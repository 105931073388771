import { Button, SearchBar, Table } from "../../../../components";
import RadioInput from "../../../../components/RadioInput";

import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as UnselectedIcon } from "../../../../assests/svg/upload_device2.svg";
import { ReactComponent as TaskIcon } from "../../../../assests/svg/task_icon.svg";
import { ReactComponent as DeviceIcon } from "../../../../assests/svg/upload_device.svg";
import { ReactComponent as Cloud } from "../../../../assests/svg/uploadDoc.svg";
import { ReactComponent as UploadIcon } from "../../../../../assests/svg/upload_icons.svg";
import styles from "../../UploadFile/style.module.scss";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  APP_ROLES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import React, { useEffect, useRef, useState } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import { Spinner } from "react-bootstrap";
import { Pagination } from "../../../../components/Pagination";
import { localeDate } from "../../../../../utils/helpers";
// const data = [

//   {
//     f: (
//       <RadioInput
//         type="checkbox"
//         name="radio"
//         maxWidth="14px"
//         alignItems="center"
//       />
//     ),
//     a: "Report_Final.docx",
//     b: "Policies",
//     c: "CPRA",
//     d: "Gregory Jhon",
//   },
//   {
//     f: (
//       <RadioInput
//         type="checkbox"
//         name="radio"
//         maxWidth="14px"
//         alignItems="center"
//       />
//     ),
//     a: "Spreadsheet_Data.xlsx",
//     b: "Policies",
//     c: "GDPR",
//     d: "Gregory Jhon",
//   },
//   {
//     f: (
//       <RadioInput
//         type="checkbox"
//         name="radio"
//         maxWidth="14px"
//         alignItems="center"
//       />
//     ),
//     a: "Report_Final.docx",
//     b: "Policies",
//     c: "CPRA",
//     d: "Gregory Jhon",
//   },
//   // {
//   //   a: "Spreadsheet_Data.xlsx",
//   //   b: "Policies",
//   //   c: "GDPR",
//   //   d: "Gregory Jhon",
//   //   e: "8/06/2023",
//   // },
//   // {
//   //   a: "Spreadsheet_Data.xlsx",
//   //   b: "Policies",
//   //   c: "GDPR",
//   //   d: "Gregory Jhon",
//   //   e: "8/06/2023",
//   // },
// ];
const LIMIT = 5;

const SelectDoc = ({
  onClickCancel,
  handleSubmitSelectDoc,
  uploadSelectLoading,
}: any) => {
  const [searchKey, setSearchKey] = React.useState<string>("null");
  const [selectedDoc, setSelectedDoc] = React.useState("");
  const [selectedRegion, setSelectedRegion] = React.useState("all");
  const [myDocDataLimit, setMyDocDataLimit] = useState<any>({
    limit: LIMIT,
    offset: 0,
  });

  const [orgDocDataLimit, setOrgDocDataLimit] = useState<any>({
    limit: LIMIT,
    offset: 0,
  });
  const countRef = useRef(0);

  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  let { data: myDocuments, isLoading: myDocIsLoading }: any = useApiQuery({
    queryKey: RQ_KEYS.DOCUMENT_LIB,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
  });

  let { data: orgDocuments, isLoading: orgDocumentsIsLoading }: any =
    useApiQuery({
      queryKey: RQ_KEYS.ORG_DOCUMENTS,
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=all&category=all&region=${selectedRegion}&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
    });

  const totalCountMyDoc = myDocuments?.totalCount;
  const totalCountOrgDoc = orgDocuments?.totalCount;

  const handleSelectChange = (e: any) => {
    console.log("in select e.target", e.target.id.split(":")[1]);
    setSelectedDoc(e.target.id);
  };

  const myDocs =
    myDocuments?.data?.length > 0
      ? myDocuments?.data?.map(
          ({
            file_original_name,
            file_created_at,
            file_signed_url,
            document_id,
            document_type,
            document_category,
            uploaded_by,
          }: any) => {
            return {
              f: (
                <RadioInput
                  type="radio"
                  name="radio"
                  maxWidth="14px"
                  alignItems="center"
                  id={`document_id:${document_id}`}
                  onChange={handleSelectChange}
                  checked={selectedDoc === `document_id:${document_id}`}
                />
              ),
              file_original_name,
              document_type,
              document_category,
              // file_created_at: localeDate(file_created_at),
              uploaded_by,
              file_created_at: localeDate(file_created_at),
              file_signed_url,
              document_id,
            };
          }
        )
      : [];

  const orgDocs =
    orgDocuments?.data?.length > 0
      ? orgDocuments?.data?.map(
          ({
            file_original_name,
            file_created_at,
            file_signed_url,
            document_id,
            document_type,
            document_category,
            uploaded_by,
            region,
          }: any) => {
            return {
              f: (
                <RadioInput
                  type="radio"
                  name="radio"
                  maxWidth="14px"
                  alignItems="center"
                  id={`document_id:${document_id}`}
                  onChange={handleSelectChange}
                  checked={selectedDoc === `document_id:${document_id}`}
                />
              ),
              file_original_name,
              document_type,
              document_category,
              // file_created_at: localeDate(file_created_at),
              uploaded_by,
              file_created_at: localeDate(file_created_at),
              region,
              file_signed_url,
              document_id,
            };
          }
        )
      : [];

  const getMyDocuments = _useMutation({ queryKey: RQ_KEYS.DOCUMENT_LIB });

  const getOrgDocuments = _useMutation({ queryKey: RQ_KEYS.ORG_DOCUMENTS });

  const searchQuery = useDebounce(searchKey, 300);

  useEffect(() => {
    countRef.current++;
  }, []);

  React.useMemo(() => {
    if (countRef.current === 0) {
      return;
    }
    if (searchQuery || searchKey.length < 0) {
      getMyDocuments.mutate({
        url: `${API_ROUTE.GET_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getOrgDocuments.mutate({
        url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (searchKey.length === 0) {
      getMyDocuments.mutate({
        url: `${API_ROUTE.GET_DOCUMENTS}?search=null&type=all&category=all&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getOrgDocuments.mutate({
        url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=null&type=all&category=all&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  }, [searchQuery, searchKey]);

  const onPaginzationChangeMyDoc = ({ offset }: any) => {
    setMyDocDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getMyDocuments.mutate({
      url: `${API_ROUTE.GET_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${offset}`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  const onPaginzationChangeOrgDoc = ({ offset }: any) => {
    setOrgDocDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getOrgDocuments.mutate({
      url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${offset}`,
      requestType: REQUEST_TYPE._GET,
    });
  };
console.log("LOGSA",

  myDocIsLoading ,
  orgDocumentsIsLoading ,
  orgDocs.length === 0,
  !selectedDoc, 
  uploadSelectLoading
)
  return (
    <div className={styles.uploadFileRow}>
      <div className={styles.documentUploadSection}>
        {/* <h5>Select from the uploaded documents</h5> */}
        <div className={styles.searchBar}>
          <SearchBar
            type="text"
            placeholder="Search"
            isRequired={false}
            name="searchBar"
            display="flex"
            onChange={(event: any) => setSearchKey(event.target.value)}
          />
        </div>
      </div>
      {userData?.role !== APP_ROLES.ORG_ADMIN && (
        <>
          <div className={styles.tableContainer}>
            {!myDocIsLoading ? (
              <>
                <div className={styles.assessmentheader}>
                  <h4>My Documents</h4>
                </div>
                <Table
                  tableHeaders={[
                    "Select",
                    "Document",
                    "Type",
                    "Category",
                    "Uploaded by",
                  ]}
                  tableData={myDocs}
                  hideColumns={[
                    "document_id",
                    "file_created_at",
                    "file_signed_url",
                  ]}
                />
              </>
            ) : (
              <div className={styles.assesmentLoader}>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!myDocIsLoading && myDocs && myDocs.length === 0 && (
              <div style={{ margin: "30px", textAlign: "center" }}>
                No Documents Found
              </div>
            )}
          </div>
          <div className={styles.pagination}>
            <Pagination
              data={myDocs}
              totalCount={totalCountMyDoc && totalCountMyDoc}
              onPaginzationChange={onPaginzationChangeMyDoc}
              setDataLimit={setMyDocDataLimit}
              dataLimit={myDocDataLimit}
            />
          </div>
        </>
      )}

      {userData && userData.role !== APP_ROLES.VENDOR_USER && (
        <>
          <div className={styles.tableContainer}>
            {!orgDocumentsIsLoading ? (
              <>
                <div className={styles.assessmentheader}>
                  <h4>Organization Documents</h4>
                </div>
                <Table
                  tableHeaders={[
                    "Select",
                    "Document",
                    "Type",
                    "Category",
                    "Uploaded by",
                    "region",
                  ]}
                  tableData={orgDocs}
                  hideColumns={[
                    "document_id",
                    "file_created_at",
                    "file_signed_url",
                  ]}
                />
              </>
            ) : (
              <div className={styles.assesmentLoader}>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!orgDocumentsIsLoading && orgDocs && orgDocs.length === 0 && (
              <div style={{ margin: "30px", textAlign: "center" }}>
                No Documents Found
              </div>
            )}
          </div>
          <div className={styles.pagination}>
            <Pagination
              data={orgDocs}
              totalCount={totalCountOrgDoc && totalCountOrgDoc}
              onPaginzationChange={onPaginzationChangeOrgDoc}
              setDataLimit={setOrgDocDataLimit}
              dataLimit={orgDocDataLimit}
            />
          </div>
        </>
      )}

      <div className={styles.buttonPanel}>
        <Button
          label="Cancel"
          id="cancelbtn"
          borderRadius="5px"
          fontWeight="bold"
          // maxWidth="100px"
          fontSize="var(--secondaryFont)"
          background="var(--whiteColor)"
          border="1px solid var(--secondaryColor)"
          color="var(--secondaryColor)"
          Icon={<CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />}
          onClick={onClickCancel}
        />
        <Button
          label="Upload"
          id="Savebtn"
          fontWeight="bold"
          color="var(--whiteColor)"
          // maxWidth="160px"
          fontSize="var(--secondaryFont)"
          //   onClick={() => {
          //     setShowPopup(!showPopup);
          //   }}
          Icon={<UploadIcon fill="#ffffff" />}
          disabled={
            myDocIsLoading ||
            orgDocumentsIsLoading ||
            // orgDocs.length === 0 ||
            !selectedDoc ||
            uploadSelectLoading
          }
          loading={uploadSelectLoading}
          onClick={() => handleSubmitSelectDoc(selectedDoc.split(":")[1])}
        />
      </div>
    </div>
  );
};

export default SelectDoc;
