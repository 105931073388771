import React from "react";
import { Pie } from "react-chartjs-2";

interface IBarchartProp {
  chartData?: any;
  title?: string;
}

const PieChart: React.FC<IBarchartProp> = ({ chartData, title }) => {
  return (
    <div className="chart-container">
      <h4>{title}</h4>
      <Pie
        data={chartData}
        options={{
          plugins: {
            title: {
              display: false,
              text: "",
            },
            legend: {
              display: true,
              // responsive: true,
              position: "right",
              align: 'center',
              labels:{
                padding:10,
                font:{
                  size:10
                }
              }
            },
          },
        }}
      />
    </div>
  );
};

export default PieChart;
