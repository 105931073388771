import { EditableQuestionTable } from "../../../../../common/Parts/EditableQuestionTable";

const QuestionTable = ({
  questions,
  answers,
  articleIndex,
  articlesLength,
  setEntryComplete,
  setArticleIndex,
  handleChangeArticleDropdown,
  article,
}: any) => {
  return (
    <EditableQuestionTable
      questions={questions}
      answers={answers}
      articleIndex={articleIndex}
      articlesLength={articlesLength}
      setEntryComplete={setEntryComplete}
      setArticleIndex={setArticleIndex}
      handleChangeArticleDropdown={handleChangeArticleDropdown}
      article={article}
    />
  );
};
export default QuestionTable;
