import * as Yup from "yup";
const ArticleSchema = Yup.object().shape({
  article_name: Yup.string().required("This field is required"),
  article_number: Yup.string().required("This field is required"),
  // risk_level: Yup.string().required("This field is required"),
  // risk_description: Yup.string().required("This field is required"),
  law: Yup.string().required("This field is required"),
});

const ArticleInitialValue = {
  article_name: "",
  article_number: "",
  // risk_level: "",
  // risk_description: "",
  law: "",
};
export { ArticleSchema, ArticleInitialValue };
