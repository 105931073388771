import * as Yup from "yup";
const InviteUserSchema = Yup.object().shape({
  org_name: Yup.string().required("This field is required"),
  // .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
});

const IniteUserInitialValue = {
  org_name: "",
  email: "",
};
export { InviteUserSchema, IniteUserInitialValue };
