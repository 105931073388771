import React from "react";
import { ILink } from "../../../common/interfaces/ILink";
import { Link } from "react-router-dom";
const CustomLink: React.FC<ILink> = ({
  label,
  color,
  textAlign,
  fontSize,
  fontWeight,
  to,
}) => {
  const linkStyle = {
    color: color || "black",
    textAlign: textAlign || "right",
    fontSize: fontSize || "small",
    fontWeight: fontWeight || "normal",
    cursor: "pointer",
  };
  return (
    <Link style={linkStyle} to={to}>
      {label}
    </Link>
  );
};

export default CustomLink;
