import React, { useState } from "react";
import { Breadcrumb } from "../../../../common/Parts";
import styles from "./style.module.scss";
import ArchiveAssessments from "./ArchivedAssessment";
import PublisedAssessments from "./PublisedAssessments";
import DraftAssessments from "./DraftAssessments";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";
import { Button, SearchBar } from "../../../../common/components";
import { IModal } from "../../../../interfaces/IModal";
import {
  APP_PATHS,
  ASSESSMENT_MODAL_NAMES,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import AssessmentPopup from "../../../../common/Parts/ModalPopups/AssessmentModals/ManageAssessments";
import {
  _getQueryState,
  _useMutation,
} from "../../../../services/useAxiosQuery";
import useDebounce from "../../../../hooks/useDebounce";
import { API_ROUTE } from "../../../../utils/api";
import { useQueryClient } from "react-query";
import ImportAssessment from "../../../../common/Parts/ModalPopups/ImportAssessment";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";
import { useNavigate } from "react-router-dom";

const ManageAssessments = () => {
  const queryClient = useQueryClient();
  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
  });

  const navigate = useNavigate();

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const [searchKey, setSearchKey] = React.useState<string>("null");

  const [fileImportMessage, setFileImportMessage] = useState("");

  const searchQuery = useDebounce(searchKey, 2000);
  const { mutate: searchDraftAssessments } = _useMutation({
    queryKey: RQ_KEYS.DRAFT_ASSESSMENT,
  });
  const { mutate: searchPublishedAssessments } = _useMutation({
    queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT,
  });
  const { mutate: searchArchivedAssessments } = _useMutation({
    queryKey: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST,
  });
  React.useMemo(() => {
    if (searchQuery || searchKey.length < 0) {
      searchDraftAssessments({
        url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${LIMIT}&offset=0&search=${searchQuery}&publish_status=Draft&archive_status=NO`,
        requestType: REQUEST_TYPE._POST,
      });
      searchPublishedAssessments({
        url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${LIMIT}&offset=0&search=${searchQuery}&publish_status=Published&archive_status=NO`,
        requestType: REQUEST_TYPE._POST,
      });
      searchArchivedAssessments({
        url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${LIMIT}&offset=0&search=${searchQuery}&publish_status=Published&archive_status=YES`,
        requestType: REQUEST_TYPE._POST,
      });
    } else if (searchKey.length === 0) {
      setSearchKey("null");
      searchDraftAssessments({
        url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${LIMIT}&offset=0&publish_status=Draft&archive_status=NO`,
        requestType: REQUEST_TYPE._POST,
      });
      searchPublishedAssessments({
        url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${LIMIT}&offset=0&search=${searchQuery}&publish_status=Published&archive_status=NO`,
        requestType: REQUEST_TYPE._POST,
      });
      searchArchivedAssessments({
        url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${LIMIT}&offset=0&search=${searchQuery}&publish_status=Published&archive_status=YES`,
        requestType: REQUEST_TYPE._POST,
      });
    }
  }, [searchQuery]);

  const { mutate: importAssessment } = _useMutation({
    onSuccess: (data: any) => {
      console.log("data in import assessemnt success", data);
      queryClient.refetchQueries({ queryKey: RQ_KEYS.DRAFT_ASSESSMENT }).then();

      setFileImportMessage(data?.message);
      setTimeout(() => {
        setFileImportMessage("");
      }, 4000);
    },
    queryKey: RQ_KEYS.IMPORT_ASSESSMENT,
  });

  const handleImportFile = (values: any) => {
    const { image, assessment_name } = values;

    let fd = new FormData();
    fd.append("file", image as any);
    fd.append("fileName", image.name);
    fd.append("assessment_name", assessment_name);
    importAssessment({
      url: API_ROUTE.IMPORT_ASSESSMENT,
      Headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
      requestType: REQUEST_TYPE._POST,
      requestData: fd,
    });
    setFileImportMessage(`${image?.name} file is Importing...`);
  };

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  console.log("🚀 ~ ManageAssessments ~ organizationData:", organizationData);
  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
      >
        {/* <Breadcrumb
          pageName="Assessments"
          path={["Dashboard", "Assessments"]}
        /> */}
      
        <div className={styles.manageAssessmentBody}>
          <div className={styles.assessmentRow}>
            <div className={styles.importFileContainer}>
              <Button
                label="Create Assessment - Import"
                id="createAssessmentBtn"
                fontWeight="bold"
                fontSize="var(--secondaryFont)"
                border="1px solid var(--secondaryColor)"
                onClick={() => {
                  setModal({
                    state: true,
                    modalName: ASSESSMENT_MODAL_NAMES.IMPORT_ASSESSMENT,
                    type: "CREATE",
                  });
                }}
                Icon={<UploadIcon fill="#ffffff" className="svg_image_icon" />}
              />
              <Button
                label="Create Assessment - Manual"
                id="createAssessmentBtn"
                fontWeight="bold"
                fontSize="var(--secondaryFont)"
                border="1px solid var(--secondaryColor)"
                onClick={() => {
                  setModal({
                    state: true,
                    modalName: ASSESSMENT_MODAL_NAMES.ASSESSMENT,
                    type: "CREATE",
                  });
                }}
                Icon={<EditIcon fill="#ffffff" className="svg_image_icon" />}
              />
            </div>

            <SearchBar
              type="text"
              placeholder="Search by Assessment Name"
              isRequired={false}
              name="searchBar"
              display="flex"
              onChange={(event: any) => setSearchKey(event.target.value)}
            />
          </div>

          <div className={styles.fileImportMessage}>{fileImportMessage}</div>
          {/* <div className={styles.fileImportMessage}>Test test...</div> */}

          <div className={`${styles.tableSeciton} ${styles.draftSection}`}>
            <DraftAssessments />
          </div>
          <div className={styles.tableSeciton}>
            <PublisedAssessments />
          </div>
          <div className={styles.tableSeciton}>
            <ArchiveAssessments />
          </div>
        </div>
      </div>
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.ASSESSMENT && (
          <AssessmentPopup
            openState={showModal}
            heading={
              showModal?.type === "CREATE"
                ? "Create Assessment"
                : "Update Assessment"
            }
            type={showModal?.type}
            // data={selectedAssessment}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.IMPORT_ASSESSMENT && (
          <ImportAssessment
            openState={showModal}
            type={showModal?.type}
            // data={selectedAssessment}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            handleImportFile={handleImportFile}
          />
        )}
    </React.Fragment>
  );
};

export default React.memo(ManageAssessments);
