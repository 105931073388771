import * as Yup from "yup";
const ChatperSchema = Yup.object().shape({
  deleteStr: Yup.string()
    .required("This field is required.")
    .matches(/^DELETE$/, 'Please enter "DELETE" to delete'),
});

const InitialChapterValue = {
  deleteStr: "",
};
export { ChatperSchema, InitialChapterValue };
