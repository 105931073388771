import React from "react";
import styles from "./style.module.scss";
import { IHeader } from "../../../interfaces/IHeader";
import { APP_PATHS, REQUEST_TYPE, RQ_KEYS } from "../../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { _getQueryState, useApiQuery } from "../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../utils/api";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../utils/helpers";
import { breadcrumbMapping } from "../../../utils/breadcrumb";

type BreadcrumbMapping = { [key: string]: string };

interface HeaderProps extends IHeader {
  dynamicBreadcrumbs?: string[];
  userAuthData?: any;
  onProfileClick?: () => void;
  onLogoutClick?: () => void;
}

const Header: React.FC<HeaderProps> = ({ onProfileClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const { data: organizationData } = useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE?.GET_USER_ORGANIZATION}?user_org_id=${
      userAuthData?.user_org_id ?? null
    }`,
    enabled: !!userAuthData,
  });

  const formattedOrganizationData = organizationData && {
    ...organizationData,
    location: organizationData?.branch_locations,
  };

  const ShowUserProfile = () => {
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS?.USER_LOGIN_DETAILS);
    }
  };

  const normalizePath = (path: string) => {
    return path.split("?")[0].replace(/\/$/, "");
  };

  const getBreadcrumbName = (path: string): string | null => {
    const normalizedPath = normalizePath(path) as keyof BreadcrumbMapping;
    const breadcrumb =
      (breadcrumbMapping as BreadcrumbMapping)[normalizedPath] ||
      (breadcrumbMapping as BreadcrumbMapping)[`${normalizedPath}/`];

    return breadcrumb || null;
  };

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment)
    .filter((segment) => isNaN(Number(segment)));

  const breadcrumbs = pathSegments
    .map((segment, index) => {
      const pathTo = `/${pathSegments.slice(0, index + 1).join("/")}`;
      const isLast = index === pathSegments?.length - 1;
      const breadcrumbName = getBreadcrumbName(pathTo);
      if (!breadcrumbName) return null;
      return (
        <span key={index}>
          <span className={isLast ? styles.currentBreadcrumb : undefined}>
            {breadcrumbName}
          </span>
          {!isLast && <span className={styles.separator}> &gt; </span>}
        </span>
      );
    })
    .filter(Boolean);

  return (
    <>
      <div className={styles.authData}>
        <div className={styles.breadcrumbContainer}>
          <nav
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className={styles.breadcrumb}>{breadcrumbs}</div>

            {formattedOrganizationData && (
              <Breadcrumb org_name={formattedOrganizationData?.org_name} />
            )}

            {userAuthData && (
              <div className={styles.loggedInUser}>
                <div className={styles.loggedInUserContainer}>
                  <div className={styles.userInfo}>
                    <p>{`${capitalizeFirstLetter(
                      userAuthData?.first_name || "User"
                    )} ${capitalizeFirstLetter(
                      userAuthData?.last_name || ""
                    )}`}</p>
                    <p>{`${
                      getAdminRoleFromKey(userAuthData?.role)?.name || "Role"
                    }`}</p>
                  </div>
                  <div
                    className={styles.userInitial}
                    onClick={onProfileClick || ShowUserProfile}
                  >
                    {userAuthData?.profile_image ? (
                      <img
                        src={userAuthData?.profile_image}
                        alt="user_profile_image"
                        style={{ width: 32, height: 32, borderRadius: "50%" }}
                      />
                    ) : (
                      <p>{`${userAuthData?.first_name?.charAt(0) || "U"}${
                        userAuthData?.last_name?.charAt(0) || "P"
                      }`}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
