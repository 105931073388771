import React, { useMemo, useState } from "react";
import styles from "./style.module.scss";
import { Dropdown, InputField, MultiSelect } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import FormikErrorMessage from "../../../../components/FormikErrorMessage";
import { Country, State } from "country-state-city";
import { ReactComponent as SelectIconBtn } from "../../../../../assests/svg/selectIcon.svg";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { AssessmentInitialValue, CreateAssessmentSchema } from "./validator";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import { ReactComponent as UpdateIcon } from "../../../../../assests/svg/update_user2.svg";

import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  ASSESSMENT_CATEGORIES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import assessments_icon from "../../../../assests/svg/assessments.svg";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { useQueryClient } from "react-query";
import {
  getAllCountries,
  getUSstate,
} from "../../../../../utils/countriesContenent";
import { Modal } from "@mui/material";

const AssessmentPopup: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  type,
  openState,
  data,
}) => {
  const [foundAssessment, setFoundAssessment] = useState("");
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const draftAssessments: any = _getQueryState({
    key: RQ_KEYS.DRAFT_ASSESSMENT,
  });
  const publishedAssessments: any = _getQueryState({
    key: RQ_KEYS.PUBLISHED_ASSESSMENT,
  });
  const archiveAssessments: any = _getQueryState({
    key: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST,
  });

  const allAssessments = useMemo(
    () =>
      draftAssessments &&
      publishedAssessments &&
      archiveAssessments && [
        ...draftAssessments.assessments,
        ...publishedAssessments.assessments,
        ...archiveAssessments.assessments,
      ],
    [draftAssessments, publishedAssessments, archiveAssessments]
  );
  console.log("assessments in modal", allAssessments);

  let categoryList = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_CATEGORIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_CATEGORY,
  });

  const usCategoryList = categoryList?.data?.filter(
    (list: any) => list.value !== "Global Assessments"
  );

  const globalCategoryList = categoryList?.data?.filter(
    (list: any) => list.value !== "US Assessments"
  );

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.DRAFT_ASSESSMENT });
    onClickCancel();
  };
  const createAssessment = _useMutation({ onSuccess });
  const updateAssessment = _useMutation({ onSuccess });

  const industryList = useApiQuery({
    queryKey: RQ_KEYS.INDUSTRIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_INDUSTRIES,
  });

  const regionList = useApiQuery({
    queryKey: RQ_KEYS.REGIONS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_REGIONS,
  });

  const { data: assessmentTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });
  console.log({ assessmentTypes });

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.invitePopupRow1}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={type === "CREATE" ? AssessmentInitialValue : data}
            validationSchema={CreateAssessmentSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log("ONSUBMIT VALUES", values);
              const foundAssessment = allAssessments.find((assessment: any) => {
                const { assessment_jurisdiction_country, assessment_region } =
                  assessment?.assessment_location[0];
                const {
                  assessment_jurisdiction_country: valueAJC,
                  assessment_region: valueAR,
                } = values.assessment_location[0];
                return (
                  assessment.assessment_name === values.assessment_name &&
                  assessment.assessment_category ===
                    values.assessment_category &&
                  assessment_jurisdiction_country === valueAJC &&
                  assessment_region === valueAR
                );
              });

              if (foundAssessment) {
                setFoundAssessment(
                  "Assessment Already Exists in this jurisdiction"
                );
                return;
              }
              setFoundAssessment("");
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              if (type === "CREATE") {
                await createAssessment.mutate({
                  url: API_ROUTE.CREATE_ASSESSMENTS,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                  },
                });
              } else {
                await updateAssessment.mutate({
                  url: API_ROUTE.UPDATE_ASSESSMENTS,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                  },
                });
              }
            }}
          >
            {({ handleChange, isValid, values, setFieldValue }) => (
              <div className={styles.invitePopupRow2}>
                <Form>
                  <div className={styles.userLogin}>
                    <div className={` ${styles.assessDropdown}`}>
                      {/* <InputField
                        name="assessment_name"
                        alignItems="left"
                        type="text"
                        label="Assessment Name"
                        placeholder="Enter Assessment Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        value={values?.assessment_name}
                      /> */}
                      <MultiSelect
                        name="assessment_name"
                        label="Assessment"
                        placeHolder="Select"
                        isMulti={false}
                        className={styles.editOrgReactSelect}
                        isRequired={true}
                        options={
                          assessmentTypes && assessmentTypes.length > 0
                            ? assessmentTypes.map(
                                ({ assessment_type }: any) => {
                                  return {
                                    value: assessment_type,
                                    label: assessment_type,
                                  };
                                }
                              )
                            : []
                        }
                        defaultValue={{
                          value: values?.assessment_name,
                          label: values?.assessment_name,
                        }}
                        values={
                          values?.assessment_name
                            ? {
                                value: values?.assessment_name,
                                label: values?.assessment_name,
                              }
                            : null
                        }
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </div>

                    <div
                      className={`${styles.userLoginInner} ${styles.fullInner}`}
                    >
                      <FieldArray
                        name="assessment_location"
                        render={(arrayHelpers) => (
                          <div className={styles.orgRegionsParent}>
                            {values &&
                              values?.assessment_location &&
                              values?.assessment_location?.map(
                                (loc: any, index: number) => (
                                  <div
                                    className={styles.orgRegions}
                                    key={loc.olr_id}
                                  >
                                    {regionList &&
                                      regionList?.data &&
                                      regionList?.data.length > 0 && (
                                        <div className={styles.editField}>
                                          <label>
                                            Region
                                            <sup
                                              style={{
                                                color: "var(--redColor)",
                                              }}
                                            >
                                              *
                                            </sup>
                                          </label>
                                          <Field
                                            as="select"
                                            name={`assessment_location[${index}].assessment_region`}
                                            value={`${values.assessment_location[index].assessment_region}`}
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                `assessment_location[${index}].assessment_jurisdiction_country`,
                                                ""
                                              );
                                              handleChange(e);
                                            }}
                                          >
                                            <option value={""} selected>
                                              Select Region
                                            </option>

                                            {regionList &&
                                              regionList?.data &&
                                              regionList?.data.length > 0 &&
                                              regionList?.data.map(
                                                (reg: any) => (
                                                  <option
                                                    key={reg.value}
                                                    value={reg.value}
                                                  >
                                                    {reg.value}
                                                  </option>
                                                )
                                              )}
                                          </Field>
                                          <SelectIconBtn />
                                          <ErrorMessage
                                            name={`assessment_location[${index}].assessment_region`}
                                            component="p"
                                          />
                                        </div>
                                      )}
                                    {values?.assessment_location[index]
                                      .assessment_region &&
                                      values?.assessment_location[index]
                                        .assessment_region !==
                                        "Online (worldwide)" && (
                                        <div className={styles.editField}>
                                          <label>Country</label>

                                          <Field
                                            as="select"
                                            name={`assessment_location[${index}].assessment_jurisdiction_country`}
                                            value={`${values.assessment_location[index].assessment_jurisdiction_country}`}
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                `assessment_location[${index}].assessment_jurisdiction_state`,
                                                ""
                                              );

                                              handleChange(e);
                                              if (
                                                e.target.value !==
                                                "United States"
                                              ) {
                                                categoryList.data =
                                                  globalCategoryList;
                                              } else {
                                                categoryList.data =
                                                  usCategoryList;
                                              }
                                            }}
                                          >
                                            <option value={""} selected>
                                              Select Country
                                            </option>
                                            {getAllCountries(
                                              values.assessment_location[index]
                                                .assessment_region
                                            ).map((cou: any) => (
                                              <option
                                                key={cou.name}
                                                value={cou.name}
                                              >
                                                {cou.name}
                                              </option>
                                            ))}
                                          </Field>
                                          <SelectIconBtn />
                                          <ErrorMessage
                                            name={`assessment_location[${index}].assessment_jurisdiction_country`}
                                            component="p"
                                          />
                                        </div>
                                      )}

                                    {values.assessment_location[index]
                                      .assessment_jurisdiction_country ===
                                      "United States" && (
                                      <div className={styles.editField}>
                                        <label>State(Optional)</label>
                                        <Field
                                          as="select"
                                          name={`assessment_location[${index}].assessment_jurisdiction_state`}
                                          onChange={handleChange}
                                          value={`${values.assessment_location[index].assessment_jurisdiction_state}`}
                                        >
                                          <option value={""} disabled selected>
                                            Select State
                                          </option>
                                          {(() => {
                                            return getUSstate().map(
                                              (state: any) => (
                                                <option
                                                  key={state.name}
                                                  value={state.name}
                                                >
                                                  {state.name}
                                                </option>
                                              )
                                            );
                                          })()}
                                        </Field>
                                        <SelectIconBtn />
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      />
                    </div>
                    <div className={` ${styles.assessDropdown}`}>
                      <MultiSelect
                        name="assessment_category"
                        label="Assessment Category"
                        placeHolder="Select"
                        isMulti={false}
                        className={styles.editOrgReactSelect}
                        isRequired={true}
                        options={
                          categoryList &&
                          categoryList.data &&
                          categoryList.data.length > 0
                            ? categoryList.data
                            : []
                        }
                        defaultValue={{
                          value: values?.assessment_category,
                          label: values?.assessment_category,
                        }}
                        values={
                          values?.assessment_category
                            ? {
                                value: values?.assessment_category,
                                label: values?.assessment_category,
                              }
                            : null
                        }
                        setFieldValue={setFieldValue}
                      />

                      <MultiSelect
                        name="assessment_industry"
                        label="Industry"
                        placeHolder="Select"
                        menuPlacement="top"
                        isMulti={false}
                        className={styles.editOrgReactSelect}
                        isRequired={true}
                        options={industryList?.data}
                        defaultValue={{
                          value: values?.assessment_industry,
                          label: values?.assessment_industry,
                        }}
                        values={
                          values?.assessment_industry
                            ? {
                                value: values?.assessment_industry,
                                label: values?.assessment_industry,
                              }
                            : null
                        }
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div className={` ${styles.userLoginInner}`}>
                      <InputField
                        name="section_name"
                        alignItems="left"
                        type="text"
                        label="Section Name"
                        placeholder="Enter Section Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        value={values?.section_name}
                      />

                      <InputField
                        name="subsection_name"
                        alignItems="left"
                        type="text"
                        label="Sub-Section Name"
                        placeholder="Enter Sub-Section Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        value={values?.subsection_name}
                      />
                    </div>
                    <div className={` ${styles.userLoginInner}`}>
                      <InputField
                        name="question_name"
                        alignItems="left"
                        type="text"
                        label="Question Name"
                        placeholder="Enter Question Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        value={values?.question_name}
                      />

                      <InputField
                        name="answer_name"
                        alignItems="left"
                        type="text"
                        label="Answer Name"
                        placeholder="Enter Answer Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        value={values?.answer_name}
                      />
                    </div> */}
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      type="button"
                      borderRadius="5px"
                      fontWeight="bold"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      disabled={loadingState}
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      label={
                        type === "CREATE"
                          ? "Create Assessment"
                          : "Update Assessment"
                      }
                      id="saveAssessmentbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      maxWidth="190px"
                      fontSize="var(--secondaryFont)"
                      border="none"
                      disabled={loadingState}
                      loading={loadingState}
                      type="submit"
                      Icon={
                        type !== "CREATE" ? (
                          <UpdateIcon
                            fill="#ffffff"
                            style={{ maxWidth: "15px" }}
                          />
                        ) : (
                          <EditIcon fill="#ffffff" className="svg_image_icon" />
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontSize: 12,
                      textAlign: "right",
                      marginTop: "10px",
                    }}
                  >
                    {foundAssessment}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AssessmentPopup;
