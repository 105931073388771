import Tooltip from "../../../../../common/Parts/ToolTip";
import { Button } from "../../../../../common/components";
import PriorityBadge from "../../../../../common/components/PriorityBadge";
import { ASSESSMENT_MODAL_NAMES } from "../../../../../utils/constants";
import { ReactComponent as UpIcon } from "../../../../../assests/svg/up_arrow.svg";
import { ReactComponent as DownIcon } from "../../../../../assests/svg/down_arrow.svg";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assests/svg/delete_icon.svg";
import styles from "./style.module.scss";

const ShowQuestions = ({
  topLevelQuestions,
  referenceQuestions: questions,
  showGuidance,
  setShowGuidance,
  setState,
  setModal,
  showOption,
  setShowOption,
  assessment,
  chapterData,
  articleData,
}: any) => {
  console.log("reference ques obo", questions);
  const { chapter_name, chapter_id } = chapterData;

  const { article_name, article_id, article_number, risk_description, law } =
    articleData;

  return (
    <>
      {topLevelQuestions?.length > 0 && (
        <div
          className={`${styles.questionsList} ${styles.addedArticleSection}`}
        >
          {topLevelQuestions.map(
            (
              {
                question,
                question_id,
                question_guidance,
                question_number,
                no_task,
                risk_level,
                options,
                question_type,
              }: any,
              index: number
            ) => {
              return (
                <p key={question_id}>
                  <b>Question {index + 1}: </b>
                  <div className={styles.questionLength}>
                    <span>
                      {question}
                      <PriorityBadge priority={risk_level} />
                    </span>
                    {showGuidance.includes(question_id) && (
                      <div className={styles.optionContainer}>
                        {options.length > 0 &&
                          options.map((option: any, idx: number) => {
                            const childQuestions =
                              option?.child_question_ids != "[undefined]"  &&  option?.child_question_ids != "undefined" ? JSON.parse(option?.child_question_ids) : [];
                            const currentFollowup = questions?.filter(
                              (ques: any) => {
                                return childQuestions?.includes(
                                  ques?.question_id
                                );
                              }
                            );

                            return (
                              <div>
                                <div className={styles.followUPCOntainer}>
                                  <div className={styles.optionCtn}>
                                    <p
                                      key={option.option_id}
                                      style={{
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bolder",
                                          minWidth: "fit-content",
                                        }}
                                      >
                                        Option {idx + 1}:
                                      </span>

                                      {option.option_text}
                                    </p>
                                  </div>
                                  <div className={styles.addfollowarrow}>
                                    {question_type === "Objective" && (
                                      <div className={styles.followupaddBtn}>
                                        <Button
                                          label="+ Add Follow up"
                                          id="followup"
                                          borderRadius="5px"
                                          padding="0px"
                                          fontWeight="bold"
                                          color="var(--secondaryColor)"
                                          border="none"
                                          fontSize="15px"
                                          background="var(--transparentColor)"
                                          onClick={() => {
                                            setState((prev: any) => ({
                                              ...prev,
                                              selectedChapters: {
                                                chapter_name,
                                                chapter_id,
                                              },
                                              selectedArticles: {
                                                article_name,
                                                article_id,
                                                article_number,
                                                risk_level,
                                                risk_description,
                                                law,
                                              },
                                            }));
                                            setModal({
                                              state: true,
                                              modalName:
                                                ASSESSMENT_MODAL_NAMES.QUESTION,
                                              type: "CREATE",
                                              data: {
                                                option_id: option.option_id,
                                                is_followup: true,
                                                parent_question_id: question_id,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                    {showOption ? (
                                      <Tooltip text="Hide">
                                        <UpIcon
                                          width={10}
                                          height={10}
                                          onClick={() => {
                                            setShowOption(false);
                                          }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip text="Show">
                                        <DownIcon
                                          width={10}
                                          height={10}
                                          onClick={() => {
                                            // const newGuidance = [
                                            //   ...showGuidance,
                                            //   question_id,
                                            // ];
                                            setShowOption(true);
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                                {showOption &&
                                  currentFollowup &&
                                  currentFollowup.length > 0 && (
                                    <ShowQuestions
                                      topLevelQuestions={currentFollowup}
                                      referenceQuestions={questions}
                                      setState={setState}
                                      setModal={setModal}
                                      showGuidance={showGuidance}
                                      setShowGuidance={setShowGuidance}
                                      showOption={showOption}
                                      setShowOption={setShowOption}
                                      assessent={assessment}
                                      chapterData={chapterData}
                                      articleData={articleData}
                                    />
                                  )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>

                  <div className={styles.iconParent}>
                    {options.length > 0 && (
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {showGuidance.includes(question_id) ? (
                          <Tooltip text="Hide">
                            <UpIcon
                              width={10}
                              height={10}
                              onClick={() => {
                                setShowGuidance((prev: any) => {
                                  const newGuidance = prev.filter(
                                    (id: any) => id !== question_id
                                  );

                                  return newGuidance;
                                });
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip text="Show">
                            <DownIcon
                              width={10}
                              height={10}
                              onClick={() => {
                                const newGuidance = [
                                  ...showGuidance,
                                  question_id,
                                ];
                                setShowGuidance(newGuidance);
                              }}
                            />
                          </Tooltip>
                        )}
                      </span>
                    )}

                    {assessment?.publish_status === "Draft" && (
                      <>
                        <Tooltip text="Edit">
                          <EditIcon
                            //    fill="var(--textColor)"
                            className={styles.iconBlue}
                            onClick={() => {
                              setModal({
                                state: true,
                                modalName: ASSESSMENT_MODAL_NAMES.QUESTION,
                                type: "UPDATE",
                              });
                              setState((prev: any) => ({
                                ...prev,
                                selectedQuestions: {
                                  question,
                                  question_id,
                                  question_number,
                                  no_task,
                                  question_type,
                                  question_guidance,
                                  options,
                                  risk_level,
                                },
                              }));
                            }}
                          />
                        </Tooltip>
                        <Tooltip text="Delete">
                          <DeleteIcon
                            //   fill="var(--textColor)"
                            className={styles.iconRed}
                            type="button"
                            onClick={() => {
                              setState((prev: any) => {
                                return {
                                  ...prev,
                                  selectedQuestions: {
                                    question_id,
                                    question_type,
                                  },
                                };
                              });
                              setModal({
                                state: true,
                                modalName:
                                  ASSESSMENT_MODAL_NAMES.DELETE_QUESTION,
                                type: "DELETE",
                              });
                              // setShowEditPopup(true);
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </p>
              );
            }
          )}
        </div>
      )}
    </>
  );
};

export default ShowQuestions;
