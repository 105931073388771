import * as Yup from "yup";
const ChatperSchema = Yup.object().shape({
  chapter_name: Yup.string().required("This field is required"),
  // chapter_number: Yup.string()
  //   .required("This field is required")
  //   .matches(/^\d*$/, "Only numbers are allowed"),
  // assessment_id: Yup.string().required("Assessment Id missing"),
});

const InitialChapterValue = {
  chapter_name: "",
};
export { ChatperSchema, InitialChapterValue };
