import * as Yup from "yup";
const AssignAssessmentSchema = Yup.object().shape({
  assigned_to_user_id: Yup.string().required("This field is required"),
  description: Yup.string(),
  due_date: Yup.string().required("This field is required."),
  role: Yup.string().required("This field is required."),
});

const AssignAssessmentInitialValue = {
  assigned_to_user_id: "",
  description: "",
  due_date: "",
  role: "",
};
export { AssignAssessmentInitialValue, AssignAssessmentSchema };
