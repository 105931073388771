import * as Yup from "yup";

const onboardingSchema: any = Yup.object().shape({
  org_name: Yup.string().required("This field is required."),
  org_type: Yup.string().required("This field is required."),
  location: Yup.array().of(
    Yup.object()
      .shape({
        branch_name: Yup.string().required("This field is required."),
        street_address_1: Yup.string().required("This field is required."),
        region: Yup.string().required("This field is required."),
        // country: Yup.string().required("This field is required."),
        is_headquarter: Yup.string(),
        // org_type: Yup.string().required("This field is required."),
      })
      .required("Location is required field")
  ),
  org_industries: Yup.array()
    .required("This field is required.")
    .min(1, "This field is required"),
  assessments: Yup.array(),
});

const stepOneOrgDetailsSchema: any = Yup.object().shape({
  org_name: Yup.string().required("This field is required."),
  org_type: Yup.string().required("This field is required."),
});
const stepTwoLocationSchema: any = Yup.object().shape({
  location: Yup.array()
    .of(
      Yup.object()
        .shape({
          branch_name: Yup.string().required("This field is required."),
          street_address_1: Yup.string().required("This field is required."),
          region: Yup.string().required("This field is required."),
          // country: Yup.string().required("This field is required."),
          is_headquarter: Yup.string(),
          // org_type: Yup.string().required("This field is required."),
        })
        .required("Location is required field")
    )
    .min(1, "At least one location is required.")
    .required("Location is required field"),
});
const stepThreeIndustrySchema: any = Yup.object({
  org_industries: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required("This field is required."),
      })
    )
    .min(1, "At least one industry is required.")
    .required("Industry is required field"),
});

export {
  onboardingSchema,
  stepOneOrgDetailsSchema,
  stepTwoLocationSchema,
  stepThreeIndustrySchema,
};
