import { ErrorMessage, getIn, useFormikContext } from "formik";
import styles from "./formilerror.module.scss";

const FormikErrorMessage = ({ name }: any) => {
  const { errors, touched }: any = useFormikContext();
  console.log("errors, name", errors, name);
  console.log("touched, name", touched, name);
  console.log("get in", name, getIn(errors, name));

  if (touched[name] || errors[name] || name.includes(".")) {
    // if (touched[name] || errors[name]) {
    // return <p className={styles.formikError}>{errors[name]}</p>;
    return (
      <ErrorMessage
        className={styles.formikError}
        component="p"
        name={`${name}`}
      />
    );
  }
  return null;
};

export default FormikErrorMessage;
