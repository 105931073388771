import { createColumnHelper } from "@tanstack/react-table";
import { TableCell } from "./TableCell";
import { Student } from "./types";
import { EditCell } from "./EditCell";

const columnHelper = createColumnHelper<any>();

export const columns = [
  columnHelper.accessor("studentNumber", {
    header: "Student Id",
    cell: TableCell,
    meta: {
      type: "number",
    },
  }),
  columnHelper.accessor("name", {
    header: "Full Name",
    cell: TableCell,
    meta: {
      type: "text",
      required: true,
      pattern: "^[a-zA-Z ]+$",
    },
  }),
  columnHelper.accessor("dateOfBirth", {
    header: "Date Of Birth",
    cell: TableCell,
    meta: {
      type: "date",
      required: true,
      validate: (value: string) => {
        const date = new Date(value);
        const today = new Date();
        return date <= today;
      },
      validationMessage: "Date cannot be in the future",
    },
  }),
  columnHelper.accessor("major", {
    header: "Major",
    cell: TableCell,
    meta: {
      type: "select",
      options: [
        { value: "", label: "Select" },
        { value: "Computer Science", label: "Computer Science" },
        { value: "Communications", label: "Communications" },
        { value: "Business", label: "Business" },
        { value: "Psychology", label: "Psychology" },
      ],
      required: true,
    },
  }),
  columnHelper.display({
    header: "Action",
    id: "edit",
    cell: EditCell,
  }),
];

export const createColumns = (tableHeaders: any) => {
  const columns: any = [];
  if (tableHeaders?.length > 0) {
    for (const headerData of tableHeaders) {
      const { header, options } = headerData;
      if (options?.length > 0) {
        let newOptions = options
          .toSorted((a: any, b: any) => a.option_number - b.option_number)
          .map((opt: any) => ({
            label: opt.option_text,
            value: opt.option_text,
          }));
        newOptions = [{ label: "Select", value: "select" }, ...newOptions];
        const column = columnHelper.accessor(header, {
          header,
          cell: TableCell,
          meta: {
            type: "select",
            options: newOptions,
          },
        });
        columns.push(column);
      } else {
        const column = columnHelper.accessor(header, {
          header,
          cell: TableCell,
          meta: {
            type: "text",
          },
        });
        columns.push(column);
      }
    }
  }

  const action = columnHelper.display({
    header: "Action",
    id: "edit",
    cell: EditCell,
  });
  columns.push(action);

  return columns;
};
