import * as Yup from "yup";
const BranchLocationSchema = Yup.object().shape({
  branch_name: Yup.string().required("This field is required"),
  street_address_1: Yup.string().required("This field is required"),
  region: Yup.string().required("Region is required"),
  country: Yup.string().required("Country is required"),
});

const BranchLocationInitialValue = {
  branch_name: "",
  street_address_1: "",
  region: "",
  country: "",
  state: "",
  is_headquarter: "",
  org_type: "",
};
export { BranchLocationSchema, BranchLocationInitialValue };
