import { v4 as uuidv4 } from "uuid";
import { Button, InputField, Textarea } from "../../../../../common/components";
import PriorityBadge from "../../../../../common/components/PriorityBadge";
import RadioInput from "../../../../../common/components/RadioInput";
import { ReactComponent as DownIcon } from "../../../../../assests/svg/down_arrow.svg";
import { ReactComponent as UpIcon } from "../../../../../assests/svg/up_arrow.svg";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assests/svg/delete_icon.svg";

import styles from "./style.module.scss";
import { isJsonString } from "../../../../../utils/helpers";
import { APP_ROLES, RQ_KEYS } from "../../../../../utils/constants";
import { _getQueryState } from "../../../../../services/useAxiosQuery";

const ListQuestions = ({
  referenceQuestions,
  currentFollowupAnswers,
  setCurrentFollowupAnswers,
  currentQuestions,
  topLevel,
  questionIndex,
  checkQuestionLength,
  assignedStatusDynamic,
  setAnswerOptionId,
  handleObjectiveRadioChange,
  answerOptionId,
  handleGuidance,
  showGuidance,
  errorText,
  setAnswerCheckId,
  answerCheckId,
  isMyAssessment,
  setAnswerText,
  answerText,
  handleListItemChange,
  setAnswerList,
  handleDeleteFromAnswerList,
  depth,
  depthStr,
}: any) => {
  console.log("depth", depth);
  console.log("current follow answers", currentFollowupAnswers);
  function processMultiOptionAnswers(answers:any) {
    return answers.map((answer:any) => {
        if (answer.question_type === "Multi-Option Selection") {
            // Parse multi_option_answer if it’s a string
            if (typeof answer.multi_option_answer === "string" && answer.multi_option_answer.startsWith("[")) {
                try {
                    // Convert the stringified array into an actual array
                    answer.multi_option_answer = JSON.parse(answer.multi_option_answer);
                } catch (error) {
                    console.error("Error parsing multi_option_answer:", error);
                    answer.multi_option_answer = [];
                }
            }
        }
        return answer;
    });
}

// Process the answers
currentFollowupAnswers = processMultiOptionAnswers(currentFollowupAnswers);

console.log(">>>>>>>currentFollowupAnswers",currentFollowupAnswers);
  function quesNoWithDepth(depth: number, depthStr: string, idx: number) {
    let quesNo = new Array(depth).fill(1);

    quesNo.splice(quesNo.length - 1, 1, idx);
    const depthArr = depthStr.split(".").map((d: string) => Number(d));
    return {
      quesStr: quesNo.join("."),
      quesOrd:
        questionIndex +
        1 +
        depthArr
          .concat(quesNo.slice(quesNo.length - 1))
          .join(".")
          .slice(1),
    };
  }

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  return (
    <>
      {currentQuestions?.length > 0
        ? currentQuestions.map((currentQuestion: any, idx: number) => (
            <div
              style={{
                marginLeft: topLevel ? 0 : 0,
                border: "1px solid var(--tableBorderColor)",
                padding: "10px 20px",
              }}
            >
              <div>
                <p>{`${
                  topLevel
                    ? questionIndex + 1
                    : quesNoWithDepth(depth, depthStr, idx + 1).quesOrd
                }:  ${currentQuestion?.question}`}</p>
                {currentQuestion?.question_guidance &&
                  currentQuestion?.question_guidance != "null" &&
                  currentQuestion?.question_guidance != "undefined" && (
                    <p
                      style={{
                        fontSize: "13px",
                        color: "grey",
                        fontStyle: "italic",
                      }}
                    >
                      <span>Guidance:</span>{" "}
                      {currentQuestion?.question_guidance}
                    </p>
                  )}
              </div>

              {currentQuestion?.risk_level && (
                <p>
                  Risk Level:{" "}
                  <PriorityBadge
                    priority={
                      currentQuestion?.risk_level
                        ? currentQuestion?.risk_level
                        : "Low"
                    }
                  />
                </p>
              )}

              {topLevel && (
                <p className={styles.statusContainer}>
                  Status: <span>{assignedStatusDynamic}</span>
                </p>
              )}

              <p>
                {currentQuestion?.question_type === "Subjective" ||
                currentQuestion?.question_type === "List"
                  ? "Answer:"
                  : currentQuestion?.question_type === "Multi-Option Selection"
                  ? "Select Multiple Options:"
                  : "Options:" 
                }
               
              </p>
              {currentQuestion?.question_type === "Objective" && (
                <div className={styles.optionBtnBar}>
                  {currentQuestion?.options?.length > 0 &&
                    currentQuestion?.options
                      .toSorted(
                        (a: any, b: any) => a.option_number - b.option_number
                      )
                      .map((option: any) => {
                        console.log("option in list", option);
                        const currentQuestionIds =
                          option?.child_assign_question_ids
                            ? JSON.parse(option.child_assign_question_ids)
                            : [];
                        console.log(
                          "current questionIds",
                          currentQuestionIds,
                          referenceQuestions
                        );
                        const newCurrentQuestions =
                          referenceQuestions &&
                          referenceQuestions.length > 0 &&
                          referenceQuestions?.filter((ques: any) =>
                            currentQuestionIds.includes(ques?.question_id)
                          );
                        console.log(
                          "new current qeustions",
                          newCurrentQuestions
                        );
                        return (
                      <>
                       <div
                         className={styles.radioOptions}
                         key={option.assign_option_id}
                       >
                         {
                           <RadioInput
                             type="radio"
                             label=""
                             maxWidth="14px"
                             id={option.assign_option_id}
                             name={`radio-${currentQuestion.org_assign_assessment_id}`}
                             onChange={
                               userAuthData?.role !==
                               APP_ROLES.OBOEDIO_ADMIN
                              
                                 ? (e: any) => {
                                     setCurrentFollowupAnswers(
                                       (prev: any) =>
                                         prev.map((ans: any) => {
                                           if (
                                             currentQuestion.org_assign_assessment_id ===
                                             ans.org_assign_assessment_id
                                           ) {
                                             return {
                                               ...ans,
                                               answer: e.target.id,
                                               answer_text: option.option_text,
                                               show:
                                                 newCurrentQuestions?.length >
                                                 0
                                                   ? true
                                                   : false,
                                             };
                                           } else return { ...ans };
                                         })
                                     );
                                   }
                                 : undefined
                             }
                             checked={
                               Number(
                                 currentFollowupAnswers?.find(
                                   (ans: any) =>
                                     currentQuestion.org_assign_assessment_id ===
                                     ans.org_assign_assessment_id
                                 )?.answer
                               ) === Number(option.assign_option_id)
                             }
                           disabled={
                            userAuthData?.role ===APP_ROLES.AUDITOR ? true : false
                           }
                           
                           />
                         }
                         <p>{option.option_text}</p>
                       </div>
                       {option?.option_description &&
                         option.option_description != "undefined" &&
                         option.option_description != "null" && (
                           <div
                             className={styles.guidanceBtn}
                             onClick={() =>
                               handleGuidance(option.assign_option_id)
                             }
                           >
                             <p className={styles.acoordion}>
                               Guidance
                               <span>
                                 {showGuidance.includes(
                                   option.assign_option_id
                                 ) ? (
                                   <UpIcon width={10} height={10} />
                                 ) : (
                                   <DownIcon width={10} height={10} />
                                 )}
                               </span>
                             </p>
                             {showGuidance.includes(
                               option.assign_option_id
                             ) && (
                               <p className={styles.guiadnacetext}>
                                 {option.option_description}
                               </p>
                             )}
                           </div>
                         )}
                       {/* option.child_assign_question_ids && */}

                       {currentFollowupAnswers?.find(
                         (ans: any) =>
                           currentQuestion.org_assign_assessment_id ===
                           ans.org_assign_assessment_id
                       )?.answer &&
                         currentFollowupAnswers?.find(
                           (ans: any) =>
                             currentQuestion.org_assign_assessment_id ===
                             ans.org_assign_assessment_id
                         )?.show &&
                         newCurrentQuestions?.length > 0 && (
                           <ListQuestions
                             depth={depth + 1}
                             depthStr={
                               quesNoWithDepth(depth, depthStr, idx + 1)
                                 .quesStr
                             }
                             currentFollowupAnswers={
                               currentFollowupAnswers
                             }
                             setCurrentFollowupAnswers={
                               setCurrentFollowupAnswers
                             }
                             referenceQuestions={referenceQuestions}
                             topLevel={false}
                             currentQuestions={newCurrentQuestions || []}
                             questionIndex={questionIndex}
                             checkQuestionLength={checkQuestionLength}
                             assignedStatusDynamic={assignedStatusDynamic}
                             setAnswerOptionId={setAnswerOptionId}
                             handleObjectiveRadioChange={
                               handleObjectiveRadioChange
                             }
                             answerOptionId={answerOptionId}
                             handleGuidance={handleGuidance}
                             showGuidance={showGuidance}
                             errorText={errorText}
                             setAnswerCheckId={setAnswerCheckId}
                             answerCheckId={answerCheckId}
                             isMyAssessment={isMyAssessment}
                             setAnswerText={setAnswerText}
                             answerText={answerText}
                             handleListItemChange={handleListItemChange}
                             setAnswerList={setAnswerList}
                             handleDeleteFromAnswerList={
                               handleDeleteFromAnswerList
                             }
                           />
                         )}
                     </>
                        );
                      })}
                  {topLevel && (
                    <p style={{ color: "red", fontSize: 12 }}>{errorText}</p>
                  )}
                </div>
              )}
              {currentQuestion?.question_type === "Multi-Option Selection" && (
                <div className={styles.optionBtnBar}>
                  {currentQuestion?.options
                    .toSorted(
                      (a: any, b: any) => a.option_number - b.option_number
                    )
                    .map((option: any) => (
                      <>
                        <div
                          className={styles.radioOptions}
                          key={option.assign_option_id}
                        >
                          <RadioInput
                            type="checkbox"
                            label=""
                            maxWidth="14px"
                            id={option.assign_option_id}
                            name={option.assign_option_id.toString()}
                            onChange={(e: any) => {
                              // e.target..checked

                              setCurrentFollowupAnswers((prev: any) =>
                                prev.map((ans: any) => {
                                  if (
                                    currentQuestion.org_assign_assessment_id ===
                                    ans.org_assign_assessment_id
                                  ) {
                                    return {
                                      ...ans,
                                      multi_option_answer: e.target.checked
                                        ? [
                                            ...ans?.multi_option_answer,
                                            e.target.name,
                                          ]
                                        : ans?.multi_option_answer.filter(
                                            (m: any) => m !== e.target.name
                                          ),
                                    };
                                  } else {
                                    return ans;
                                  }
                                })
                              );
                            }}
                            checked={currentFollowupAnswers
                              ?.find(
                                (ans: any) =>
                                  currentQuestion.org_assign_assessment_id ===
                                  ans.org_assign_assessment_id
                              )
                              ?.multi_option_answer?.map((v: any) => Number(v))
                              .includes(option.assign_option_id)}
                          />
                          <p>{option.option_text}</p>
                        </div>
                        {option?.option_description &&
                          option.option_description != "undefined" &&
                          option.option_description != "null" && (
                            <div
                              className={styles.guidanceBtn}
                              onClick={() =>
                                handleGuidance(option.assign_option_id)
                              }
                            >
                              <p className={styles.acoordion}>
                                Guidance
                                <span>
                                  {showGuidance.includes(
                                    option.assign_option_id
                                  ) ? (
                                    <UpIcon width={10} height={10} />
                                  ) : (
                                    <DownIcon width={10} height={10} />
                                  )}
                                </span>
                              </p>
                              {showGuidance.includes(
                                option.assign_option_id
                              ) && (
                                <p className={styles.guiadnacetext}>
                                  {option.option_description}
                                </p>
                              )}
                            </div>
                          )}
                      </>
                    ))}
                  <p style={{ color: "red", fontSize: 12 }}>{errorText}</p>
                </div>
              )}
              {isMyAssessment &&
                currentQuestion?.question_type === "Subjective" && (
                  <>
                    <Textarea
                      cols={30}
                      rows={5}
                      name="answer_input"
                      placeholder="Write your answer"
                      isRequired={false}
                      width="100%"
                      padding="5px 10px"
                      maxHeight="250px"
                      onChange={(e: any) => {
                        // e.target.value
                        setCurrentFollowupAnswers((prev: any) =>
                          prev.map((ans: any) => {
                            if (
                              currentQuestion.org_assign_assessment_id ===
                              ans.org_assign_assessment_id
                            ) {
                              return {
                                ...ans,
                                answer_subjective: e.target.value,
                              };
                            } else return ans;
                          })
                        );
                      }}
                      value={
                        currentFollowupAnswers?.find(
                          (ans: any) =>
                            currentQuestion.org_assign_assessment_id ===
                            ans.org_assign_assessment_id
                        )?.answer_subjective
                      }
                      disabled={
                        userAuthData?.role === APP_ROLES.AUDITOR ? true : false
                      }
                    />
                    <p style={{ color: "red", fontSize: 12 }}>{errorText}</p>
                  </>
                )}
              {isMyAssessment && currentQuestion?.question_type === "List" && (
                <>
                  <ol>
                    {isJsonString(
                      currentFollowupAnswers?.find(
                        (ans: any) =>
                          currentQuestion.org_assign_assessment_id ===
                          ans.org_assign_assessment_id
                      )?.answer_list
                    ) &&
                      JSON.parse(
                        currentFollowupAnswers?.find(
                          (ans: any) =>
                            currentQuestion.org_assign_assessment_id ===
                            ans.org_assign_assessment_id
                        )?.answer_list
                      )?.map((ansList: any) => (
                        <li key={ansList.id}>
                          <div className={styles.answerListContainer}>
                            <div className={styles.userLoginInner}>
                              <InputField
                                type="text"
                                name="answer_input"
                                placeholder="Write your answer"
                                isRequired={false}
                                width="100%"
                                padding="5px 10px"
                                onChange={(e: any) => {
                                  // e.target.value
                                  setCurrentFollowupAnswers((prev: any) =>
                                    prev.map((ans: any) => {
                                      if (
                                        currentQuestion.org_assign_assessment_id ===
                                        ans.org_assign_assessment_id
                                      ) {
                                        return {
                                          ...ans,
                                          answer_list: ans.answer_list.map(
                                            (item: any) => {
                                              if (item.id === ansList.id) {
                                                return {
                                                  ...item,
                                                  text: e.target.value,
                                                };
                                              } else return item;
                                            }
                                          ),
                                        };
                                      } else return ans;
                                    })
                                  );
                                }}
                                value={ansList.text}
                              />
                              {ansList.length > 1 && (
                                <DeleteIcon
                                  onClick={() =>
                                    setCurrentFollowupAnswers((prev: any) =>
                                      prev.map((ans: any) => {
                                        if (
                                          currentQuestion.org_assign_assessment_id ===
                                          ans.org_assign_assessment_id
                                        ) {
                                          return {
                                            ...ans,
                                            answer_list: ans.answer_list.filter(
                                              (item: any) => {
                                                return item.id !== ansList.id;
                                              }
                                            ),
                                          };
                                        } else return ans;
                                      })
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ol>
                  <p style={{ color: "red", fontSize: 12 }}>{errorText}</p>
                  <div className={styles.iconContainerQUestion}>
                    <Button
                      label="Add"
                      id="add"
                      type="button"
                      Icon={
                        <PlusIcon
                          fill="#ffffff"
                          width={50}
                          height={50}
                          className="svg_image_icon"
                        />
                      }
                      padding="6px 20px"
                      onClick={() => {
                        setCurrentFollowupAnswers((prev: any) =>
                          prev.map((ans: any) => {
                            if (
                              currentQuestion.org_assign_assessment_id ===
                              ans.org_assign_assessment_id
                            ) {
                              return {
                                ...ans,
                                answer_list: [
                                  ...ans.answer_list,
                                  { id: uuidv4(), text: "" },
                                ],
                              };
                            } else return ans;
                          })
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          ))
        : null}
    </>
  );
};

export default ListQuestions;
