import React from "react";
import styles from "./style.module.scss";
import {
  Checkbox,
  InputField,
  MultiSelect,
  Textarea,
} from "../../../../components";
import {
  IInviteUser,
  IQuestion,
} from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { TasksInitialValue, AddTasksSchema } from "./validator";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as SearchIcon } from "../../../../../assests/svg/search_icon.svg";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { useQueryClient } from "react-query";
import {
  _useMutation,
  useApiQuery,
  _getQueryState,
} from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as UpdateIcon } from "../../../../../assests/svg/update_icon.svg";
import LoadingHoc from "../../../../components/LoadingHoc";
import { Modal } from "@mui/material";

const ManageTasks: React.FC<IQuestion> = ({
  onClickCancel,
  heading,
  type,
  data,
  article_id,
  chapter_id,
  openState,
}) => {
  // console.log("ManageTasks", data, JSON.parse(data?.question_ids));
  // const { assessment_id } = useParams();
  
  const [searchParams] = useSearchParams();
  const assessment_id = searchParams.get("assessment_id");
  const queryClient = useQueryClient();
  const getChaptersMutate = _useMutation({ queryKey: RQ_KEYS.ALL_CHAPTERS });
  const onSuccess = async () => {
    await getChaptersMutate.mutate({
      url: API_ROUTE.GET_CHAPTERS_LIST_OBOEDIO + `/${assessment_id}`,
      requestType: REQUEST_TYPE._GET,
    });
    onClickCancel();
  };
  const updateTaskMutate = _useMutation({ onSuccess });
  const addTaskMutate = _useMutation({ onSuccess });

  const questions: any = useApiQuery({
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_QUESTIONS_BY_ARTICLE}?assessment_id=${assessment_id}&chapter_id=${chapter_id}&article_id=${article_id}`,
    queryKey: RQ_KEYS.QUESTIONS,
  });

  const questionsLoading = _getQueryState({
    key: `${RQ_KEYS.QUESTIONS}_loading`,
  });

  console.log("questionsuseApiQuery", questions && questions);
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={
              type === "CREATE"
                ? TasksInitialValue
                : { ...data, question_ids: JSON.parse(data?.question_ids) }
            }
            validationSchema={AddTasksSchema}
            onSubmit={async (values) => {
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              if (type === "CREATE") {
                await addTaskMutate.mutate({
                  url: API_ROUTE.CREATE_TASKS,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    article_id: article_id,
                    question_ids: values?.question_ids.map(
                      ({ value }: any) => value
                    ),
                  },
                });
              } else {
                await updateTaskMutate.mutate({
                  url: API_ROUTE.UPDATE_TASKS,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                    question_ids: values?.question_ids.map(
                      ({ value }: any) => value
                    ),
                  },
                });
              }
              onClickCancel();
            }}
          >
            {({ handleChange, isValid, values, setFieldValue }) => {
              console.log("values>>", values?.question_ids);
              console.log("questions", questions, "question_ids", values);
              return (
                <div className={styles.addChapterRow}>
                  <Form>
                    <div className={styles.userLogin}>
                      <div className={styles.userLoginInner}>
                        <LoadingHoc isLoading={questionsLoading}>
                          {questions &&
                            questions.data &&
                            questions.data.length > 0 && (
                              <MultiSelect
                                label="Select Questions"
                                name="question_ids"
                                placeholder="Select"
                                isRequired={true}
                                options={questions.data.map(
                                  (
                                    { question_number, question_id }: any,
                                    index: number
                                  ) => {
                                    return {
                                      label: `Question ${index + 1}`,
                                      value: question_id,
                                    };
                                  }
                                )}
                                defaultValue={
                                  values?.question_ids &&
                                  values?.question_ids?.length > 0 &&
                                  questions.data.map((item: any) => {
                                    return values?.question_ids.includes(
                                      item?.question_id
                                    )
                                      ? {
                                          label: `Question ${item?.question_number}`,
                                          value: item?.question_id,
                                        }
                                      : null;
                                  })
                                }
                                setFieldValue={setFieldValue}
                                isMulti={true}
                              />
                            )}
                        </LoadingHoc>

                        {/* {questions &&
                        questions.data &&
                        questions.data.length > 0 ? (
                          <MultiSelect
                            label="Select Questions"
                            name="question_ids"
                            placeholder="Select"
                            isRequired={true}
                            options={questions.data.map(
                              ({ question_number, question_id }: any) => {
                                return {
                                  label: `Question ${question_number}`,
                                  value: question_id,
                                };
                              }
                            )}
                            defaultValue={
                              values?.question_ids &&
                              values?.question_ids?.length > 0 &&
                              questions.data.map((item: any) => {
                                return values?.question_ids.includes(
                                  item.question_id
                                )
                                  ? {
                                      label: `Question ${item?.question_number}`,
                                      value: item.question_id,
                                    }
                                  : null;
                              })
                            }
                            setFieldValue={setFieldValue}
                            isMulti={true}
                          />
                        ) : (
                          <p>Loading questions...</p>
                        )} */}

                        <Textarea
                          cols={30}
                          rows={3}
                          name="task"
                          label="Task"
                          placeholder="Write a task"
                          isRequired={true}
                          width="100%"
                          padding="5px 10px"
                          maxHeight="155px"
                          onChange={handleChange}
                          value={values.task}
                        />
                      </div>
                      <div className="paddb-5">
                        <MultiSelect
                          name="risk_level"
                          label="Risk Level"
                          //   defaultValue={values?.risk_level}
                          isRequired={false}
                          placeholder="Select"
                          defaultValue={{
                            value: values?.risk_level,
                            label: values?.risk_level,
                          }}
                          options={[
                            { value: "Low", label: "Low" },
                            { value: "Medium", label: "Medium" },
                            { value: "Severe", label: "Severe" },
                          ]}
                          setFieldValue={setFieldValue}
                          onChange={handleChange}
                          values={
                            values.risk_level
                              ? {
                                  value: values?.risk_level,
                                  label: values?.risk_level,
                                }
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className={styles.inviteUserBtn}>
                      <Button
                        label="Cancel"
                        id="cancelbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        background="var(--whiteColor)"
                        border="1px solid var(--secondaryColor)"
                        color="var(--secondaryColor)"
                        // maxWidth="100px"
                        fontSize="var(--secondaryFont)"
                        Icon={
                          <CancelBtn
                            fill="#0097ce"
                            style={{ maxWidth: "10px" }}
                          />
                        }
                        onClick={onClickCancel}
                      />
                      <Button
                        label={type === "CREATE" ? "+ Add Task" : "Update Task"}
                        id="addChapterbtn"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        // maxWidth="150px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={
                          type !== "CREATE" && <UpdateIcon fill="#ffffff" />
                        }
                      />
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ManageTasks;
