import React, { useState } from "react";
import { PublishUnPublishModal } from "../../../../common/Parts";
import styles from "./style.module.scss";
import { Button, SearchBar, Table } from "../../../../common/components";
import AssessmentPopup from "../../../../common/Parts/ModalPopups/AssessmentModals/ManageAssessments";
import {
  APP_PATHS,
  ASSESSMENT_MODAL_NAMES,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as ViewIcon } from "../../../../assests/svg/view_icon.svg";
import { ReactComponent as PublishIcon } from "../../../../assests/svg/publish.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/svg/delete_icon.svg";
import { Spinner } from "react-bootstrap";
import useDebounce from "../../../../hooks/useDebounce";
import Tooltip from "../../../../common/Parts/ToolTip";
import DeleteAssessment from "../../../../common/Parts/ModalPopups/AssessmentModals/DeleteAssessment";
import { Pagination } from "../../../../common/components/Pagination";
import { IModal } from "../../../../interfaces/IModal";
import { useQueryClient } from "react-query";
import CustomizedMenus from "../../../../common/components/MenuList";

// interface IJurisdiction {
//   state: string;
//   country: string;
// }
// interface IFilters {
//   selectedFilter: "region" | "jurisdiction" | "";
//   selectedRegion: string;
//   filterRegions: string[];
//   filterJudiriction: any[];
//   selectedJurisdiction: IJurisdiction;
// }
const DraftAssessments = () => {
  const queryClient = useQueryClient();

  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
  });
  const [state, setState] = useState<any>({ publishStatus: false });
  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });
  const { data: draftAssessments }: any = useApiQuery({
    queryKey: RQ_KEYS.DRAFT_ASSESSMENT,
    requestType: REQUEST_TYPE._POST,
    url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${dataLimit?.limit}&offset=${dataLimit?.offset}&publish_status=Draft&archive_status=NO`,
  });
  const { mutate } = _useMutation({ queryKey: RQ_KEYS.DRAFT_ASSESSMENT });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const [selectedAssessment, setSelectedAssessment] = useState<any>(null);

  const navigate = useNavigate();

  const onPaginzationChange = ({ offset }: any) => {
    // console.log("offset", offset);
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    mutate({
      url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${dataLimit?.limit}&offset=${offset}&publish_status=Draft&archive_status=NO`,
      requestType: REQUEST_TYPE._POST,
    });
  };

  return (
    <React.Fragment>
      <div className={styles.assessmentMain1}>
        <div className={styles.assessmentMain}>
          <div className={styles.assessmentheader}>
            <h4>Draft Assessments</h4>
          </div>
          {!loadingState ? (
            <Table
              tableHeaders={[
                "Assessment Name",
                "Region",
                "Jurisdiction",
                "Assessment Category",
                "Last Modified",
              ]}
              tableData={
                draftAssessments?.assessments?.length > 0 &&
                draftAssessments?.assessments?.map((item: any) => ({
                  ...item,
                }))
              }
              hideColumns={[
                "archived",
                "assessment_id",
                "assessment_creater_id",
                "assessment_industry",
                "assessment_jurisdiction_country",
                "assessment_jurisdiction_state",
                "assessment_location",
                "archived_date",
                "publish_status",
              ]}
              action={{
                name: "Action",
                buttonOne: (data: any) => {
                  return (
                    <Button
                      // background=" var(--primaryColor)"
                      onClick={() => {
                        navigate(
                          // `${APP_PATHS.CHAPTER.replace(
                          //   ":assessment_id",
                          //   data?.assessment_id
                          // )}`
                          `${APP_PATHS.CHAPTER}?assessment_id=${data?.assessment_id}`
                        );
                      }}
                      label="View"
                      id="1"
                      Icon={
                        <ViewIcon fill="#ffffff" className="svg_image_icon" />
                      }
                      // fill="#000000"
                      // className="svg_image_icon"
                    />
                  );
                },
                // buttonOne: (data: any) => {
                //   return (
                //     <Tooltip text="View">
                //       <ViewIcon
                //         fill="#000000"
                //         className="svg_image_icon"
                //         onClick={() => {
                //           navigate(
                //             // `${APP_PATHS.CHAPTER.replace(
                //             //   ":assessment_id",
                //             //   data?.assessment_id
                //             // )}`
                //             `${APP_PATHS.CHAPTER}?assessment_id=${data?.assessment_id}`
                //           );
                //         }}
                //       />
                //     </Tooltip>
                //   );
                // },
                buttonTwo: (data: any) => {
                  console.log("DATA", data);
                  return (
                    <CustomizedMenus
                      // isDraftAssessment="isDraftAssessment"
                      // isEdit={() => {
                      //   setSelectedAssessment(data);
                      //   setModal({
                      //     state: true,
                      //     modalName: ASSESSMENT_MODAL_NAMES.ASSESSMENT,
                      //     type: "UPDATE",
                      //   });
                      // }}
                      // isPublish={() => {
                      //   setState((prev: any) => ({
                      //     ...prev,
                      //     publishStatus: true,
                      //     assessment_id: data?.assessment_id,
                      //   }));
                      // }}
                      // isDelete={() => {
                      //   setSelectedAssessment(data);
                      //   setModal({
                      //     state: true,
                      //     modalName: ASSESSMENT_MODAL_NAMES.DELETE_ASSESSMENT,
                      //     type: "DELETE",
                      //   });
                      // }}
                      actions={[
                        {
                          label: "Edit",
                          icon: <EditIcon fill="var(--whiteColor)" />,
                          condition: true,
                          onClick: () => {
                            setSelectedAssessment(data);
                            setModal({
                              state: true,
                              modalName: ASSESSMENT_MODAL_NAMES.ASSESSMENT,
                              type: "UPDATE",
                            });
                          },
                        },
                        {
                          label: "Publish",
                          icon: <PublishIcon fill="var(--whiteColor)"/>,
                          condition: true,
                          onClick: () => {
                            setState((prev: any) => ({
                              ...prev,
                              publishStatus: true,
                              assessment_id: data?.assessment_id,
                            }));
                          },
                        },
                        {
                          label: "Delete",
                          icon: <DeleteIcon fill="var(--whiteColor)"/>,
                          condition: true,
                          onClick: () => {
                            setSelectedAssessment(data);
                            setModal({
                              state: true,
                              modalName:
                                ASSESSMENT_MODAL_NAMES.DELETE_ASSESSMENT,
                              type: "DELETE",
                            });
                          },
                        },
                      ]}
                    />
                  );
                },
                // buttonTwo: (data: any) => {
                //   console.log("DATA", data);
                //   return (
                //     <Tooltip text="Edit">
                //       <EditIcon
                //         fill="#000000"
                //         className="svg_image_icon"
                //         onClick={() => {
                //           setSelectedAssessment(data);
                //           setModal({
                //             state: true,
                //             modalName: ASSESSMENT_MODAL_NAMES.ASSESSMENT,
                //             type: "UPDATE",
                //           });
                //         }}
                //       />
                //     </Tooltip>
                //   );
                // },
                // buttonThree: (data: any) => {
                //   return (
                //     <Tooltip text="Publish" rightSide={true}>
                //       <PublishIcon
                //         fill="#000000"
                //         className="svg_image_icon"
                //         onClick={() => {
                //           setState((prev: any) => ({
                //             ...prev,
                //             publishStatus: true,
                //             assessment_id: data?.assessment_id,
                //           }));
                //         }}
                //       />
                //     </Tooltip>
                //   );
                // },
                // buttonFour: (data: any) => {
                //   return (
                //     <Tooltip text="Delete" rightSide={true}>
                //       <DeleteIcon
                //         fill="#000000"
                //         className="svg_image_icon"
                //         onClick={() => {
                //           setSelectedAssessment(data);
                //           setModal({
                //             state: true,
                //             modalName: ASSESSMENT_MODAL_NAMES.DELETE_ASSESSMENT,
                //             type: "DELETE",
                //           });
                //         }}
                //       />
                //     </Tooltip>
                //   );
                // },
              }}
            />
          ) : (
            <div className={styles.assesmentLoader}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {draftAssessments?.assessments &&
          draftAssessments?.assessments?.length === 0 && (
            <h6
              style={{
                paddingTop: "1rem",
                textAlign: "center",
              }}
            >
              No Assessment found in Draft
            </h6>
          )}
        <Pagination
          data={draftAssessments?.assessments}
          totalCount={
            draftAssessments?.totalCount >= 0 ? draftAssessments?.totalCount : 0
          }
          onPaginzationChange={onPaginzationChange}
          setDataLimit={setDataLimit}
          dataLimit={dataLimit}
        />
      </div>

      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.ASSESSMENT && (
          <AssessmentPopup
            openState={showModal}
            heading={
              showModal?.type === "CREATE"
                ? "Create Assessment"
                : "Update Assessment"
            }
            type={showModal?.type}
            data={selectedAssessment}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}

      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.DELETE_ASSESSMENT && (
          <DeleteAssessment
            openState={showModal}
            heading="Delete Assessment"
            type={showModal?.type}
            data={selectedAssessment}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}
      {state?.publishStatus && (
        <PublishUnPublishModal
          openState={state?.publishStatus}
          type="Published"
          assessment_id={state?.assessment_id}
          onClickCancel={() => {
            setState((prev: any) => ({
              ...prev,
              publishStatus: !state?.publishStatus,
            }));
          }}
          heading="Publish Assessment"
          // onSubmit={onUnPublishAssessment}
        />
      )}
    </React.Fragment>
  );
};

export default DraftAssessments;
