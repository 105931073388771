import * as Yup from "yup";
const ImportAssessmentSchema = Yup.object({
  assessment_name: Yup.string().required("This field is required"),
  image: Yup.mixed(),
});

const AssessmentInitialValue = {
  assessment_name: "",
  image: "",
  // assessment_jurisdiction_country: "",
  // assessment_jurisdiction_state: "",
  // assessment_industry: "",
  // assessment_region: "",
  // assessment_location: [
  //   {
  //     assessment_region: "",
  //     assessment_jurisdiction_country: "Global",
  //     assessment_jurisdiction_state: "",
  //   },
  // ],
  // assessment_category: "",

  // section_name: "",
  // subsection_name: "",
  // question_name: "",
  // answer_name: "",
};

export { ImportAssessmentSchema, AssessmentInitialValue };
