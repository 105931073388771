import React, { useState } from "react";
import { Breadcrumb, PublishUnPublishModal } from "../../../../common/Parts";
import styles from "./style.module.scss";
import {
  Button,
  FormikErrorMessage,
  MultiSelect,
  SearchBar,
  Table,
} from "../../../../common/components";
import AssessmentPopup from "../../../../common/Parts/ModalPopups/AssessmentModals/ManageAssessments";
import {
  APP_PATHS,
  ASSESSMENT_MODAL_NAMES,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../../../../common/components";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as ViewIcon } from "../../../../assests/svg/view_icon.svg";
import { ReactComponent as ArchiveIcon } from "../../../../assests/svg/archive_icon.svg";
import { ReactComponent as UnPublishIcon } from "../../../../assests/svg/un_publish.svg";
import { Spinner } from "react-bootstrap";
import useDebounce from "../../../../hooks/useDebounce";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { Country, State } from "country-state-city";
// import AddArticles from "../../../../../common/Parts/AddArticles";
import { ReactComponent as FilterIcon } from "../../../../assests/svg/filter.svg";
import {
  removeDuplicates,
  removeDuplicationsUsingSet,
} from "../../../../utils/helpers";
import ArchiveAssessments from "./ArchivedAssessment";
import Tooltip from "../../../../common/Parts/ToolTip";
import DeleteAssessment from "../../../../common/Parts/ModalPopups/AssessmentModals/DeleteAssessment";
import { Pagination } from "../../../../common/components/Pagination";
import { IModal } from "../../../../interfaces/IModal";
import { useQueryClient } from "react-query";
import CustomizedMenus from "../../../../common/components/MenuList";

const PublisedAssessments = () => {
  const queryClient = useQueryClient();

  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });
  const [state, setState] = useState<any>({ publishStatus: false });
  const { data: publishedAssessments }: any = useApiQuery({
    queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT,
    requestType: REQUEST_TYPE._POST,
    url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${dataLimit?.limit}&offset=${dataLimit?.offset}&publish_status=Published&archive_status=NO`,
  });
  const { mutate } = _useMutation({ queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });

  const navigate = useNavigate();

  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    mutate({
      url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${dataLimit?.limit}&offset=${dataLimit?.offset}&publish_status=Published&archive_status=NO`,
      requestType: REQUEST_TYPE._POST,
    });
  };
  const onSuccessArchiveUpdate = async () => {
    navigate(APP_PATHS.ASSESSMENTS);
    queryClient.refetchQueries({ queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT });
    queryClient.refetchQueries({ queryKey: RQ_KEYS.DRAFT_ASSESSMENT });
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST });
  };
  const updateArchiveStatus = _useMutation({
    onSuccess: onSuccessArchiveUpdate,
  });

  const onArchiveAssessment = async ({ assessment_id }: any) => {
    await updateArchiveStatus.mutate({
      url: `${API_ROUTE.UPDATE_ARCHIVE_STATUS}`,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        assessment_id,
        action: "YES",
      },
    });
  };

  return (
    <React.Fragment>
      <div className={styles.assessmentMain1}>
        <div className={styles.assessmentMain}>
          <div className={styles.assessmentheader}>
            <h4>Published Assessments</h4>
          </div>

          {!loadingState ? (
            <Table
              tableHeaders={[
                "Assessment Name",
                "Region",
                "Jurisdiction",
                "Assessment Category",
                "Last Modified",
              ]}
              tableData={
                publishedAssessments &&
                publishedAssessments?.assessments &&
                publishedAssessments?.assessments?.length > 0 &&
                publishedAssessments?.assessments?.map((item: any) => ({
                  ...item,
                }))
              }
              hideColumns={[
                "archived",
                "assessment_id",
                "assessment_creater_id",
                "assessment_industry",
                "assessment_jurisdiction_country",
                "assessment_jurisdiction_state",
                "assessment_location",
                "archived_date",
                "publish_status",
              ]}
              action={{
                name: "Action",
                buttonOne: (data: any) => {
                  return (
                    // <Tooltip text="View">
                    //   <ViewIcon
                    //     fill="#000000"
                    //     className="svg_image_icon"
                    //     onClick={() => {
                    //       // navigate(
                    //       //   `${APP_PATHS.CHAPTER.replace(
                    //       //     ":assessment_id",
                    //       //     data?.assessment_id
                    //       //   )}`
                    //       // );
                    //       navigate(
                    //         `${APP_PATHS.CHAPTER}?assessment_id=${data?.assessment_id}`
                    //       );
                    //     }}
                    //   />
                    // </Tooltip>
                    <Button
                      // background=" var(--primaryColor)"
                      onClick={() => {
                        navigate(
                          `${APP_PATHS.CHAPTER.replace(
                            ":assessment_id",
                            data?.assessment_id
                          )}`
                        );
                        navigate(
                          `${APP_PATHS.CHAPTER}?assessment_id=${data?.assessment_id}`
                        );
                      }}
                      label="View"
                      id="1"
                      Icon={
                        <ViewIcon fill="#ffffff" className="svg_image_icon" />
                      }
                      // fill="#000000"
                      // className="svg_image_icon"
                    />
                  );
                },
                buttonTwo: (data: any) => {
                  return (
                    // <Tooltip text="UnPublish" rightSide={true}>
                    //   <UnPublishIcon
                    //     fill="#000000"
                    //     className="svg_image_icon"
                    //     onClick={() => {
                    //       setState((prev: any) => ({
                    //         ...prev,
                    //         publishStatus: true,
                    //         assessment_id: data?.assessment_id,
                    //       }));
                    //     }}
                    //   />
                    // </Tooltip>
                    <CustomizedMenus
                      actions={[
                        {
                          label: "Unpublish",
                          icon: <UnPublishIcon fill="var(--whiteColor)" />,
                          condition: true,
                          onClick: () => {
                            setState((prev: any) => ({
                              ...prev,
                              publishStatus: true,
                              assessment_id: data?.assessment_id,
                            }));
                          },
                        },
                        {
                          label: "Archive",
                          icon: (
                            <ArchiveIcon fill="var(--whiteColor)" height={12} />
                          ),
                          condition: true,
                          onClick: () => {
                            onArchiveAssessment(data);
                          },
                        },
                      ]}
                    />
                  );
                },
                // buttonThree: (data: any) => {
                //   return (
                //     <Tooltip text="Archive" rightSide={true}>
                //       <ArchiveIcon
                //         fill="#000000"
                //         className="svg_image_icon"
                //         onClick={() => onArchiveAssessment(data)}
                //       ></ArchiveIcon>
                //     </Tooltip>
                //   );
                // },
              }}
            />
          ) : (
            <div className={styles.assesmentLoader}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {publishedAssessments?.assessments &&
          publishedAssessments?.assessments?.length === 0 && (
            <h6
              style={{
                paddingTop: "1rem",
                textAlign: "center",
              }}
            >
              No Assessment found in Published
            </h6>
          )}
        <Pagination
          data={publishedAssessments?.assessments}
          totalCount={
            publishedAssessments?.totalCount >= 0
              ? publishedAssessments?.totalCount
              : 0
          }
          onPaginzationChange={onPaginzationChange}
          setDataLimit={setDataLimit}
          dataLimit={dataLimit}
        />
      </div>
      {state?.publishStatus && (
        <PublishUnPublishModal
          openState={state?.publishStatus}
          type="Draft"
          assessment_id={state?.assessment_id}
          onClickCancel={() => {
            setState((prev: any) => ({
              ...prev,
              publishStatus: !state?.publishStatus,
            }));
          }}
          heading="UnPublish Assessment"
          // onSubmit={onUnPublishAssessment}
        />
      )}
    </React.Fragment>
  );
};

export default PublisedAssessments;
