import React from "react";   
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  EditOrganizationScreen,
  ViewOrganizationDetails,
  Login,
  ManageOrganizations,
  OrgOnboarding,
  Success,
  QuestionScreen,
  Dashboard,
  DashboardLocations,
  DashboardBranchStatus,
  ResetAndUpdateDetails,
  Unauthorized,
  ManageAssessments,
  Documents,
  Chapter,
  LocationOfficesView,
  OrgBranchLocations,
  ViewAdminAssessment,
  ForgotPassword,
  ForgotPasswordStatusScreen,
  ResetPasswordStatusScreen,
  ResetPassword,
  OrgUserTasks,
  VendorTasks,
  AnswerAssignedQuestionToOrgUser,
  AnswerAssignedQuestionToVendor,
  OrgUserList,
  AdminList,
  UserLoginDetails,
  BranchAdmin,
  VendorUser,
} from "../pages";
import { Header, Sidebar, SidebarOpen } from "../common/Parts";
import styles from "./layout.module.scss";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  LOCALSTORAGE,
  PROFILE_STATUS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../utils/constants";

import { API_ROUTE } from "../utils/api";
import { decodeToken } from "react-jwt";
import { useQueryClient } from "react-query";
import { getAdminRoleFromKey } from "../utils/helpers";
import ProtectedRoute from "./ProtectedRoute";
import ResetAndUpdatePassword from "../pages/authenticator/ResetAndUpdateDetails";
import ScrollToTop from "../common/components/ScrollToTop";
import { ReactComponent as Menu } from "../assests/svg/burger_menu.svg";
import ManageUsers from "../pages/story/OrgAdminScreens/ManageUsers";
import Managevendors from "../pages/story/OrgAdminScreens/ManageVendors";
import AssessmentDashboard from "../pages/story/Dashboard/AssessmentDashboard";
import ViewUserDetails from "../pages/story/OrgAdminScreens/ManageUsers/ViewUserDetails";
import AssignedTasks from "../pages/story/OrgAdminScreens/TrackAssessments/Tasks";
import ViewVendorDetails from "../pages/story/OrgAdminScreens/ManageVendors/ViewVendorDetails";
import Templates from "../pages/story/OboedioScreens/Templates";
import { Template } from "../pages/story";
import VendorDocuments from "../pages/story/OboedioScreens/VendorDocuments";
import QuestionSuccessScreen from "../pages/story/OrgAdminScreens/Assessments/QuestionSuccessScreen";
import ReportsPage from "../pages/story/OboedioScreens/Reports";
import HistoricalReports from "../pages/story/OboedioScreens/HistoricalReports";
import { ReactComponent as AssessmentIcon } from "../assests/svg/assessments.svg";
import { ReactComponent as ManageIcon } from "../assests/svg/manageUser.svg";
import { ReactComponent as TaskIcon } from "../assests/svg/task.svg";
import { ReactComponent as DocumentIcon } from "../assests/svg/document.svg";
import { ReactComponent as ReportIcon } from "../assests/svg/report.svg";
import { ReactComponent as LogoutIcon } from "../assests/svg/logout.svg";
import { ReactComponent as OboedioIcon } from "../assests/svg/mainOLogo.svg";
import LogoutModal from "../common/Parts/ModalPopups/LogoutModal";

const Routing = () => {
  const queryClient = useQueryClient();
  const onSuccess = ({ data }: any) => {
    const token = data?.token;
    const userAuthData = decodeToken(token);
    queryClient.setDefaultOptions({
      queries: {
        staleTime: Infinity,
        cacheTime: 100000000,
      },
    });
    queryClient.setQueryData(RQ_KEYS.USER_DATA, userAuthData);
    queryClient.setQueryData(RQ_KEYS.AUTH, token);
    localStorage.setItem(LOCALSTORAGE.TOKEN, token);
  };

  const sessionData = _useMutation({
    onSuccess,
    queryKey: RQ_KEYS.AUTH,
    showErrorToast: false,
  });
  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  // const { mutate: organizationData } = _useMutation({
  //   onSuccess,
  //   queryKey: RQ_KEYS.USER_ORGANIZATION,
  // });

  React.useEffect(() => {
    const loginCachePresent = localStorage.getItem(LOCALSTORAGE.TOKEN) ? localStorage.getItem(LOCALSTORAGE.TOKEN)  : false
    loginCachePresent &&  sessionData.mutateAsync({
      requestType: REQUEST_TYPE._GET,
      url: API_ROUTE.MATCH_USER_AUTH,
    });
  }, []);
  useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
  });

  // const tasks =
  //   userData && userData.role !== APP_ROLES.VENDOR_USER ? (
  //     <OrgUserTasks />
  //   ) : (
  //     <VendorTasks />
  //   );

  const tasks = <OrgUserTasks />;

  const answerAssignedQuestion =
    userData &&
    (userData.role === APP_ROLES.ORG_USER ||
      userData.role === APP_ROLES.BRANCH_ADMIN) ? (
      <AnswerAssignedQuestionToOrgUser />
    ) : (
      <AnswerAssignedQuestionToVendor />
    );

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  return (
    <div className="App">
      <BrowserRouter basename={"/"}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Root />}>
            <Route
              path={`${APP_PATHS.RESET_AND_UPDATE_DETAILS}/:token`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <ResetAndUpdatePassword />
                </ProtectedRoute>
              }
            />

            <Route
              path={APP_PATHS.FORGET_PASSWORD}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <ForgotPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.RESET_PASSWORD}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <ResetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.FORGET_PASSWORD_STATUS}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <ForgotPasswordStatusScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.RESET_PASSWORD_STATUS}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <ResetPasswordStatusScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.UN_AUTHENTICATED}
              element={<Unauthorized />}
            />
            <Route
              path={"/"}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.LOGIN}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ALL]}
                  authAccess={false}
                >
                  <Login />
                </ProtectedRoute>
              }
            />

            <Route
              path={APP_PATHS.ORG_ADMIN_MANAGEMENT}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN]}
                  authAccess={true}
                >
                  <ManageOrganizations />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.EDIT_ORGANIZATION}/:org_id`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN]}
                  authAccess={true}
                >
                  <EditOrganizationScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.VIEW_ORGANIZATION}/:org_id`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN]}
                  authAccess={true}
                >
                  <ViewOrganizationDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${APP_PATHS.VIEW_USER}/:user_id`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <ViewUserDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${APP_PATHS.VIEW_VENDOR}/:user_id`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <ViewVendorDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path={APP_PATHS.MANAGE_VENDORS}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN, APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <Managevendors />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.ASSESSMENT_DASHBOARD}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.VENDOR_USER,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.AUDITOR
                  ]}
                  authAccess={true}
                >
                  <AssessmentDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.MANAGE_USERS}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN, APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <ManageUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.ASSESSMENTS}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN]}
                  authAccess={true}
                >
                  <ManageAssessments />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.DOCUMENTS}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.OBOEDIO_ADMIN,
                  ]}
                  authAccess={true}
                >
                  <Documents />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.VENDOR_DOCUMENTS}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.VENDOR_USER]}
                  authAccess={true}
                >
                  <VendorDocuments />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_PATHS.TEMPLATE}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.VENDOR_USER,
                  ]}
                  authAccess={true}
                >
                  <Template />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.CHAPTER}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN, APP_ROLES.ORG_ADMIN,APP_ROLES.AUDITOR]}
                  authAccess={true}
                >
                  <Chapter />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ASSESSMENT_LIST}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <LocationOfficesView />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.MANAGE_ORGANIZATION}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <OrgBranchLocations />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ASSESSMENT_CHAPTER_LIST}`}
              element={
                <ProtectedRoute
                  //   allowedRoles={[APP_ROLES.ORG_ADMIN]}
                  allowedRoles={[
                    APP_ROLES.OBOEDIO_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.AUDITOR,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.VENDOR_USER,
                    APP_ROLES.ORG_USER,
                  ]}
                  authAccess={true}
                >
                  <ViewAdminAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ORG_ONBOARDING}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN, APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <OrgOnboarding />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.SUCCESS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN, APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <Success />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.QUESTION_SCREEN}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.AUDITOR,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.VENDOR_USER,
                    APP_ROLES.OBOEDIO_ADMIN,
                  ]}
                  authAccess={true}
                >
                  <QuestionScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ORG_DASHBOARD}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN, APP_ROLES.EXECUTIVE_USER]}
                  authAccess={true}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${APP_PATHS.ORG_EXECUTIVE}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.EXECUTIVE_USER,
                  ]}
                  authAccess={true}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ORG_DASHBOARD_LOCATIONS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN, APP_ROLES.EXECUTIVE_USER]}
                  authAccess={true}
                >
                  <DashboardLocations />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ORG_DASHBOARD_BRANCH}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.ORG_ADMIN, APP_ROLES.EXECUTIVE_USER]}
                  authAccess={true}
                >
                  <DashboardBranchStatus />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ORGADMIN_LIST}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.SUPPORT_USER]}
                  authAccess={true}
                >
                  <AdminList />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.BRANCHADMIN_LIST}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.SUPPORT_USER]}
                  authAccess={true}
                >
                  <BranchAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.VENDORUSER_LIST}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.SUPPORT_USER]}
                  authAccess={true}
                >
                  <VendorUser />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ORGUSER_LIST}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.SUPPORT_USER]}
                  authAccess={true}
                >
                  <OrgUserList />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.USER_LOGIN_DETAILS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.AUDITOR,
                    APP_ROLES.EXECUTIVE_USER,
                    APP_ROLES.SUPPORT_USER,
                    APP_ROLES.VENDOR_USER,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.OBOEDIO_ADMIN,
                  ]}
                  authAccess={true}
                >
                  <UserLoginDetails />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path={`${APP_PATHS.ORG_EXECUTIVE}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.OBOEDIO_ADMIN]}
                  authAccess={true}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path={`${APP_PATHS.ASSIGNED_TASKS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.ORG_USER,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.VENDOR_USER,
                  ]}
                  authAccess={true}
                >
                  {tasks}
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.TRACK_TASKS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[APP_ROLES.BRANCH_ADMIN, APP_ROLES.ORG_ADMIN]}
                  authAccess={true}
                >
                  <AssignedTasks />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.ASSIGNED_ASSESSMENTS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.ORG_USER,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.VENDOR_USER,
                  ]}
                  authAccess={true}
                >
                  {answerAssignedQuestion}
                </ProtectedRoute>
              }
            />
            <Route
              path={`${APP_PATHS.SYSTEM_TEMPLATES}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.OBOEDIO_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.VENDOR_USER,
                  ]}
                  authAccess={true}
                >
                  <Templates />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${APP_PATHS.QUESTIONAIRE_SUCCESS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.OBOEDIO_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.ORG_USER,
                    APP_ROLES.BRANCH_ADMIN,
                    APP_ROLES.VENDOR_USER,
                  ]}
                  authAccess={true}
                >
                  <QuestionSuccessScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${APP_PATHS.GENERATE_REPORTS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.OBOEDIO_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.ORG_USER,
                  ]}
                  authAccess={true}
                >
                  <ReportsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path={`${APP_PATHS.HISTORICAL_REPORTS}`}
              element={
                <ProtectedRoute
                  allowedRoles={[
                    APP_ROLES.OBOEDIO_ADMIN,
                    APP_ROLES.ORG_ADMIN,
                    APP_ROLES.ORG_USER,
                  ]}
                  authAccess={true}
                >
                  <HistoricalReports />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};
const Root = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  console.log("<<userAuthData>>", userAuthData);
  const location = useLocation();

  const queryClient = useQueryClient();
  const showSideBar =
    (userAuthData && userAuthData?.role !== APP_ROLES.ORG_ADMIN) ||
    (userAuthData &&
      userAuthData?.role === APP_ROLES.ORG_ADMIN &&
      userAuthData?.profile_status === PROFILE_STATUS.COMPLETED &&
      location.pathname !== APP_PATHS.SUCCESS);

  //const [showSideNav, setShowSideNav] = React.useState<boolean>(false);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleLogout = () => {
    if (localStorage.getItem("supportusertoken")) {
      const token: any = localStorage.getItem("supportusertoken");
      localStorage.setItem("token", token);
      localStorage.removeItem("supportusertoken");
      navigate(APP_PATHS.ORGADMIN_LIST);
      window.location.reload();
    } else {
      localStorage.clear();
      localStorage.removeItem(LOCALSTORAGE.TOKEN);
      queryClient.removeQueries(RQ_KEYS.AUTH);
      queryClient.removeQueries(RQ_KEYS.USER_DATA);
      navigate(0);
    }
  };

  const isAuth = () => {
    const token = localStorage.getItem("token");
    return token ? true : false;
  };
  const isAuthenticated =
    isAuth() && localStorage.getItem("token") !== "undefined";

  return (
    <React.Fragment>
      <div className={styles.globalPage}>
      {isModalOpen === true ? (
          <LogoutModal
            onClickCancel={closeModal}
            onClickLogouut={handleLogout}
            openState={isModalOpen}
          />
        ) : (
          <p></p>
        )}
        {isAuth() && location.pathname !== "/onboarding" && isAuthenticated ? (
          <div
            className={
              showSideBar && isOpen === true
                ? styles.contentPage
                : styles.contentNoLogin
            }
          > {
            showSideBar && isOpen === false && (
              <div className={styles.burgerMenuContainer}>
                <div style={{ display: "flex", gap: "4px" }}>
                  <OboedioIcon
                    onClick={toggleSidebar}
                    cursor="pointer"
                    style={{ maxWidth: "16px" }}
                  />

                  <Menu
                    onClick={toggleSidebar}
                    cursor="pointer"
                    style={{
                      maxWidth: "16px",
                      // marginBottom: "60px",
                      // fill: "var(--primaryColor)",
                    }}
                  />
                </div>
                {userAuthData.role === "ORG_USER" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "50px",
                    }}
                  >
                    <AssessmentIcon
                      onClick={() => {
                        navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
                        toggleSidebar();
                      }}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",
                        fill: "#0097ce",
                      }}
                    />

                    <DocumentIcon
                      onClick={() => navigate(APP_PATHS.DOCUMENTS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <ReportIcon
                      onClick={() => navigate(APP_PATHS.GENERATE_REPORTS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />

                    <LogoutIcon
                      onClick={() => openModal()}     
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                  </div>
                )}
                {userAuthData.role === "ORG_ADMIN" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "50px",
                    }}
                  >
                    <ManageIcon
                      onClick={() => navigate(APP_PATHS.MANAGE_ORGANIZATION)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <AssessmentIcon
                      onClick={() => navigate(APP_PATHS.ASSESSMENT_DASHBOARD)}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                    <TaskIcon
                      onClick={() => navigate(APP_PATHS.TRACK_TASKS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <DocumentIcon
                      onClick={() => navigate(APP_PATHS.DOCUMENTS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <ReportIcon
                      onClick={() => navigate(APP_PATHS.GENERATE_REPORTS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <LogoutIcon
                      onClick={() => openModal()}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                  </div>
                )}
                {userAuthData.role === "OBOEDIO_ADMIN" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "50px",
                    }}
                  >
                    <ManageIcon
                      onClick={() => {
                        navigate(APP_PATHS.MANAGE_ORGANIZATION);
                        toggleSidebar();
                      }}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <AssessmentIcon
                      onClick={() => navigate(APP_PATHS.ASSESSMENTS)}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                    <TaskIcon
                      onClick={() => navigate(APP_PATHS.SYSTEM_TEMPLATES)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    {/* <DocumentIcon
                    onClick={() => navigate(APP_PATHS.DOCUMENTS)}
                    cursor="pointer"
                    style={{ maxWidth: "16px" }}
                  /> */}
                    <ReportIcon
                      onClick={() => navigate(APP_PATHS.GENERATE_REPORTS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <LogoutIcon
                      onClick={() => openModal()}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                  </div>
                )}
                 {userAuthData.role === APP_ROLES.AUDITOR && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "50px",
                    }}
                  >
                 
                    <AssessmentIcon
                      onClick={() => navigate(APP_PATHS.ASSESSMENTS)}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                   
                    <LogoutIcon
                      onClick={() => openModal()}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                  </div>
                )}
                {userAuthData.role === "BRANCH_ADMIN" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "50px",
                    }}
                  >
                    <AssessmentIcon
                      onClick={() => navigate(APP_PATHS.ASSESSMENT_DASHBOARD)}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />

                    <TaskIcon
                      onClick={() => navigate(APP_PATHS.ASSIGNED_TASKS)}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />
                    <DocumentIcon
                      onClick={() => {
                        navigate(APP_PATHS.TEMPLATE);
                        toggleSidebar();
                      }}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />

                    <LogoutIcon
                      onClick={() => openModal()}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                  </div>
                )}
                {userAuthData.role === "VENDOR_USER" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "50px",
                    }}
                  >
                    <AssessmentIcon
                      onClick={() => navigate(APP_PATHS.ASSESSMENT_DASHBOARD)}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />

                    <DocumentIcon
                      onClick={() => {
                        navigate(APP_PATHS.VENDOR_DOCUMENTS);
                        toggleSidebar();
                      }}
                      cursor="pointer"
                      style={{ maxWidth: "16px" }}
                    />

                    <LogoutIcon
                      onClick={() => openModal()}
                      cursor="pointer"
                      style={{
                        maxWidth: "16px",

                        fill: "#0097ce",
                      }}
                    />
                  </div>
                )}
              </div>
            ) }
            {showSideBar && isOpen === true && (
              <div className={styles.sideNavParent}>
                <div
                  className={`sidebar ${styles.contentPageRow1} ${
                    isOpen ? styles.open : ""
                  }`}
                >
                  <Sidebar
                    userAuthData={userAuthData}
                    sidebarData={
                      getAdminRoleFromKey(userAuthData.role)["sidenavAccess"]
                    }
                    onClickCancel={() => {
                      setIsOpen(false);
                    }}
                    onClickOpen={() => {
                      setIsOpen(true);
                    }}
                  />
                </div>
              </div>
            )
            //  : (
            //   <div className={styles.sideNavParent}>
            //     <div
            //       className={`sidebar ${styles.contentNoLogin}`}
            //     >
            //       <SidebarOpen
            //         userAuthData={userAuthData}
            //         sidebarData={
            //           getAdminRoleFromKey(userAuthData?.role)?.["sidenavAccess"]
            //         }
            //       />
            //     </div>
            //   </div>
            // ) 
            }
          </div>
        ) : null}
        <div
          className={
            showSideBar && isOpen === true
              ? styles.headerContainer
              : styles.headerwithFixedsidebar
          }
        >
          {/* {userAuthData && <Header useAuthData={userAuthData} />} */}
          {isAuth() &&
          location.pathname !== "/onboarding" &&
          isAuthenticated ? (
            <Header useAuthData={userAuthData} />
          ) : null}
          <div
            className={`${styles.contentPageRow2} ${
              location.pathname !== "/onboarding" ? styles.contentPadding : {}
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default React.memo(Routing);
