import React from "react";

import styles from "./styles.module.scss";
import { Breadcrumb } from "../../../../common/Parts";
import { Button, Table } from "../../../../common/components";
import {
  _getQueryState,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { APP_PATHS, REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { saveExcel } from "../Reports/ExportButton";
import { useNavigate } from "react-router-dom";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";

const HistoricalReports = () => {
  // const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  // let createdByUser: any = userData?.first_name + " " + userData?.last_name;

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const navigate = useNavigate();
  let { data: historicalData, isLoading }: any = useApiQuery({
    queryKey: RQ_KEYS.HISTORICAL_REPORTS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.HISTORICAL_REPORTS,
  });

  console.log("historicalData ===>", historicalData);

  // console.log("User Data ====>", userData);

  let allOrganizations: any = useApiQuery({
    queryKey: RQ_KEYS.ORGANIZATIONS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ORGANIZATIONS,
  });

  console.log("all org", allOrganizations);

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
      >
        {/* <div className={styles.breadCrumb}> */}
        {/* <Breadcrumb
            pageName="Reports"
            path={["Dashboard", "Historical Reports"]}
          /> */}
        
        {/* </div> */}

        <div className={styles.manageAssessmentBody}>
          <div className={styles.assessmentheader}>
            <h4>Historical Reports</h4>
          </div>
          <div className={styles.dashbrdVendorContainer}>
            <LoadingHoc isLoading={isLoading}>
              <Table
              flag='columnAction'
                tableHeaders={[
                  "Org Name",
                  "Assessment Name",
                  "Report Type",
                  "Compliance Status",
                  "Created By",
                  "Location",
                  "Start Date",
                  "End Date",
                ]}
                tableData={
                  historicalData?.length > 0 &&
                  historicalData?.map(
                    ({
                      org_name,
                      assessment_name,
                      report_type,
                      compliance_status,
                      created_by_user,
                      location,
                      startDate,
                      endDate,
                      report_data,
                      assessment_id,
                      org_id,
                      owner_id,
                      owner_name
                    }: any) => ({
                      org_name,
                      assessment_name,
                      report_type,
                      compliance_status,
                      created_by_user,
                      location,
                      startDate: new Date(startDate).toLocaleDateString(
                        "en-US"
                      ),
                      endDate: new Date(endDate).toLocaleDateString("en-US"),
                      report_data,
                      assessment_id,
                      org_id,
                      owner_id,
                      owner_name
                    })
                  )
                }
                hideColumns={[
                  "report_data",
                  "org_id",
                  "assessment_id",
                  "historical_report_id",
                  "owner_id",
                ]}
                action={{
                  name: "Action",
                  buttonOne: (data: any) => {
                    console.log("data in historical button", data);
                    console.log("data report", data.report_data);
                    return (
                      // <Tooltip text="View">
                      // <ViewIcon
                      //   fill="#000000"
                      //   className="svg_image_icon"
                      //   onClick={() =>
                      //     handleAssessmentClick(data?.assessment_id)
                      //   }
                      // />
                      //    </Tooltip>
                      <Button
                        onClick={() =>{
                          console.log("Data:",data);
                          console.log("Report Data:",data.report_data)
                          saveExcel(
                            {
                              name: data.assessment_name,
                              value: data.assessment_id,
                            },
                            JSON.parse(data.report_data),
                            data.report_type
                          )
                        }}
                        label="Download Report"
                        id="viewBtn"
                        disabled={!data?.report_data}
                        // Icon={
                        // <ViewIcon
                        //   fill="#ffffff"
                        //   className="svg_image_icon"
                        // />
                        // }
                        maxWidth="155px"
                        width="155px"
                        title={!data?.report_data ? "No data found in report" : ""}
                      />
                    );
                  },

                  buttonTwo: (data: any) => {
                    console.log("data in historical data button 2", data);
                    return (
                      // <Tooltip text="View">
                      // <ViewIcon
                      //   fill="#000000"
                      //   className="svg_image_icon"
                      //   onClick={() =>
                      //     handleAssessmentClick(data?.assessment_id)
                      //   }
                      // />
                      //    </Tooltip>
                      <Button
                        onClick={
                          () =>
                            navigate(
                              `${APP_PATHS.GENERATE_REPORTS}?org_id=${
                                data.org_id
                              }&org_name=${data.org_name.replace(
                                "&",
                                "%26"
                              )}&assessment_id=${
                                data.assessment_id
                              }&assessment_name=${data.assessment_name}&owner=${
                                data.owner_name
                              }&owner_id=${data.owner_id}&report_type=${
                                data.report_type
                              }&location=${data.location}&compliance_status=${
                                data.compliance_status
                              }&startDate=${data.startDate}&endDate=${
                                data.endDate
                              }`
                            )
                          // navigate(APP_PATHS.GENERATE_REPORTS)
                        }
                        maxWidth="155px"
                        label="Regenerate Report"
                        id="viewBtn"
                        // Icon={
                        // <ViewIcon
                        //   fill="#ffffff"
                        //   className="svg_image_icon"
                        // />
                        // }
                        width="155px"
                      />
                    );
                  },
                }}
              />
            </LoadingHoc>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HistoricalReports;
