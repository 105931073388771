import { useState, useEffect } from "react";

export interface ITimer {
  timeLeft: number;
  userId: number;
  endTime: Date;
}

export default function useTimer() {
  const [timer, setTimer] = useState<ITimer[]>([]);
  useEffect(() => {
    let timerState = JSON.parse(localStorage.getItem("timer") as string);

    if (timerState && timerState.length > 0) {
      timerState = timerState.map((state: ITimer) => {
        const currentDate = new Date();

        const timeDelta = Math.round(
          Math.abs(
            (currentDate.getTime() - new Date(state.endTime).getTime()) / 1000
          )
        );

        return {
          ...state,
          timeLeft: new Date(state.endTime) > currentDate ? timeDelta : 0,
        };
      });
    } else timerState = [];
    console.log({ timerState });
    setTimer(timerState);
  }, []);

  useEffect(() => {
    const timerFunc = setTimeout(() => {
      if (timer.length > 0) {
        const newTimer = timer
          .filter((state: ITimer) => state.timeLeft > 0)
          .map((state: ITimer) => ({
            ...state,
            timeLeft: state.timeLeft - 1,
          }));
        setTimer(newTimer);
        localStorage.setItem("timer", JSON.stringify(newTimer));
      }
    }, 1000);
    return () => clearTimeout(timerFunc);
  }, [timer]);

  return { timer, setTimer };
}
