import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { InitialChapterValue, ChatperSchema } from "./validator";
import { useParams } from "react-router-dom";
import {
  _getQueryState,
  _useMutation,
} from "../../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { useQueryClient } from "react-query";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as DeleteBtn } from "../../../../../assests/svg/delete_icon.svg";
import { Modal } from "@mui/material";

const DeleteDocument: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  data,
  type,
  onSuccess,
  openState,
}) => {
  console.log("data in delete", data);
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  
  
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });

  const deleteDocument = _useMutation({
    onSuccess: (data: any) => {
      onSuccess && onSuccess(data);
      onClickCancel();
    },
  });

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={InitialChapterValue}
            // validationSchema={ChatperSchema}
            onSubmit={async (values) => {
              console.log("VALUES", values, type, data);
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              if (type === "DELETE") {
                deleteDocument.mutate({
                  url: API_ROUTE.DELETE_DOCUMENT,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    document_id: data,
                  },
                });
              }
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.addChapterRow}>
                <Form>
                  <div className={styles.userLogin}>
                    <p>
                      Are you sure you want to delete? This cannot be undone.
                    </p>
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      type="button"
                      onClick={onClickCancel}
                    />
                    {type === "DELETE" && (
                      <Button
                        label="Delete"
                        id="deleteDocumentbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        background="red"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={<DeleteBtn fill="#ffffff" />}
                        disabled={!isValid || loadingState}
                      />
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocument;
