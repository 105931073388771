import React, { useEffect, useState } from "react";  
import { Breadcrumb } from "../../../../../common/Parts";
import styles from "./style.module.scss";
import { Country, State } from "country-state-city";
import {
  Button,
  Dropdown,
  InputField,
  MultiSelect,
  Table,
} from "../../../../../common/components";
import { ReactComponent as BackIcon } from "../../../../../assests/svg/backArrow.svg";
import { ReactComponent as SaveBtn } from "../../../../../assests/svg/save_close.svg";
import { ReactComponent as CloseBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as SelectIcon } from "../../../../../assests/svg/selectIcon.svg";
import { ReactComponent as AssignIcon } from "../../../../../assests/svg/assign.svg";
import {
  APP_PATHS,
  ASSESSMENT_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, FieldArray, Field } from "formik";
import { EditOrganizationSchema } from "./validator";
import Toast from "../../../../../common/components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { removeDuplicates } from "../../../../../utils/helpers";
import {
  getAllCountries,
  getUSstate,
} from "../../../../../utils/countriesContenent";
import Tooltip from "../../../../../common/Parts/ToolTip";
import AddAssessmentByAdmin from "../../../../../common/Parts/ModalPopups/AddAssessmentByAdmin";
import { IModal } from "../../../../../interfaces/IModal";
import DeleteUserAssessment from "../../../../../common/Parts/ModalPopups/AssessmentModals/Assign-UnassignUserAssessment";
import Select from "react-select/dist/declarations/src/Select";
import AssignUnassignUserAssessment from "../../../../../common/Parts/ModalPopups/AssessmentModals/Assign-UnassignUserAssessment";
import { Spinner } from "react-bootstrap";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
// import ReactSelect from "../../../../../common/components/ReactSelect";

const EditOrganizationScreen: React.FC = () => {
  const [state, setState] = useState<string[]>([]);
  const [showAdminAssessment, setShowAdminAssessment] =
    React.useState<boolean>(false);
  const [showModal, setModal] = React.useState<any>({
    state: false,
    modalName: "",
  });
  const [orgAssessmentId, setOrgAssessmentId] = React.useState();
  const queryClient = useQueryClient();
  const { org_id } = useParams();
  const navigate = useNavigate();
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  let { data } = useApiQuery({
    queryKey: RQ_KEYS.ORG_ADMIN_BY_ID,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ORG_ADMIN_BY_ID + `/${org_id}`,
    // staleTime: 1000,
  });

  const onSuccess = ({ message }: any) => {
    // queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
    //   message: message,
    //   toastType: "SUCCESS",
    // });

    queryClient.invalidateQueries(RQ_KEYS.ORG_ADMIN_BY_ID);

    navigate(
      `${APP_PATHS.VIEW_ORGANIZATION}/${org_id}`
    );
  };
  let organizationList = useApiQuery({
    queryKey: RQ_KEYS.ORG_ADMIN_BY_ID,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ORG_ADMIN_BY_ID + `/${org_id}`,
    // staleTime: 1000,
  });
  console.log("edit data", data);

  const updateUserOrganizationDetails = _useMutation({ onSuccess });
  const industryList = useApiQuery({
    queryKey: RQ_KEYS.INDUSTRIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_INDUSTRIES,
  });
  const regionList = useApiQuery({
    queryKey: RQ_KEYS.REGIONS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_REGIONS,
  });
  console.log("organizationList", organizationList);
  // useEffect(() => {
  //   setState(["US"]);
  // }, []);
  return (
    <React.Fragment>
      <div className={styles.editOrganisationContainer}>
        <div className="contentHeader">
          <div className={styles.backBtn}>
            <Button
              label=""
              id="backBtn"
              color="var(--blackColor)"
              background="transparent"
              fontSize="var(--secondaryFont)"
              onClick={() => {
                navigate(
                  `${APP_PATHS.VIEW_ORGANIZATION}/${organizationList?.data?.org_id}`
                );
              }}
              border="none"
              Icon={<BackIcon className="svg_image_icon" />}
            />
          </div>
          <Breadcrumb
            pageName="Edit Organization"
            // path={[
            //   "Dashboard",
            //   "Organizations",
            //   "View Organization",
            //   "Edit Organization",
            // ]}
          />
        </div>
        <div className={styles.editOrganisationBody}>
          <Formik
            initialValues={organizationList?.data}
            enableReinitialize={true}
            validationSchema={EditOrganizationSchema}
            onSubmit={async (values) => {
              console.log("values on submit in edit org", values);
              const org_industries =
                values.org_industries.length > 0
                  ? values.org_industries.map(({ value }: any) => {
                      console.log("requestData", value);
                      return value;
                    })
                  : [];

              const requestData = {
                ...values,
                org_industries: org_industries,
                // org_region: org_region,
              };
              console.log("requestData", requestData, org_industries);

              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              await updateUserOrganizationDetails.mutate({
                url: API_ROUTE.UPDATE_ORG_USER_DETAILS,
                requestType: REQUEST_TYPE._PATCH,
                requestData,
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => {
              // console.log(values, " id guess");
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  {/* <p>{JSON.stringify(values?.location, null, 2)}</p> */}
                  <div className={styles.login}>
                    <div className={styles.loginSection1}>
                      <div className={styles.loginForm}>
                        <div className={styles.loginFormInner}>
                          <h2>Edit Organization Details</h2>
                          <h3>Organization Details</h3>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <InputField
                                name="org_name"
                                type="text"
                                label="Organization Name"
                                placeholder="Enter Organization Name"
                                isRequired={true}
                                display="flex"
                                flexDirection="row"
                                fontWeight="600"
                                gap="6px"
                                flexWrap="wrap"
                                onChange={handleChange}
                                value={values?.org_name}
                                onBlur={handleBlur}
                              />
                            </div>

                            <div className={styles.editOrgInput}>
                              <MultiSelect
                                name="org_industries"
                                label="Organization Industries"
                                placeholder="Select"
                                isMulti={true}
                                className={styles.editOrgReactSelect}
                                defaultValue={values?.org_industries}
                                options={
                                  industryList?.data &&
                                  industryList?.data?.length > 0
                                    ? industryList?.data
                                    : []
                                }
                                values={values?.org_industries}
                                setFieldValue={setFieldValue}
                                // onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>Organization Status</label>
                              <Dropdown
                                label={values?.org_status}
                                className={styles.editOrgSelect}
                                dropdownData={[
                                  {
                                    name: "ACTIVE",
                                    value: "ACTIVE",
                                  },
                                  {
                                    name: "SUSPEND",
                                    value: "SUSPENDED",
                                  },
                                ]}
                                onClick={(selectedItem: any) => {
                                  setFieldValue(
                                    "org_status",
                                    selectedItem?.value
                                  );
                                }}
                              />
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Organization Type</label>
                              <Dropdown
                                label={values?.org_type}
                                className={styles.editOrgSelect}
                                placeholder="Select"
                                dropdownData={[
                                  {
                                    name: "Public",
                                    value: "PUBLIC",
                                  },
                                  {
                                    name: "Private",
                                    value: "PRIVATE",
                                  },
                                ]}
                                onClick={(selectedItem: any) => {
                                  setFieldValue(
                                    "org_type",
                                    selectedItem?.value
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <FieldArray
                            name="location"
                            render={(arrayHelpers) => (
                              <div className={styles.orgRegionsParent}>
                                <div className={styles.orgRegionsHeading}>
                                  <h3>Office Locations</h3>
                                  <Button
                                    id="add-id"
                                    label="+ Add Location"
                                    type="button"
                                    fontWeight="bold"
                                    // maxWidth="150px"
                                    fontSize="var(--secondaryFont)"
                                    border="1px solid var(--secondaryColor)"
                                    onClick={() => {
                                      // setFieldValue(
                                      //   "location",
                                      //   [
                                      //     ...values?.location,
                                      //     {
                                      //       region: "",
                                      //       country: "",
                                      //       state: "",
                                      //     },
                                      //   ]
                                      // );
                                      arrayHelpers.push({
                                        region: "",
                                        country: "",
                                        state: "",
                                      });
                                    }}
                                  />
                                </div>

                                {values &&
                                  values?.location &&
                                  values?.location?.length > 0 &&
                                  values?.location
                                    // .filter(
                                    //   (obj: any) =>
                                    //     obj.region !== null &&
                                    //     obj.country !== null
                                    // )
                                    .map((loc: any, index: number) => (
                                      <div
                                        className={styles.orgRegions}
                                        key={loc.olr_id}
                                      >
                                        <div className={styles.regionBranch}>
                                          Office {index + 1}:
                                        </div>
                                        <div className={styles.editField}>
                                          <label>
                                            Region{" "}
                                            <sup
                                              style={{
                                                color: "var(--redColor)",
                                              }}
                                            >
                                              *
                                            </sup>
                                          </label>
                                          {/* <LoadingHoc> */}
                                          <Field
                                            as="select"
                                            name={`location[${index}].region`}
                                            value={`${values.location[index].region}`}
                                          >
                                            <option
                                              value={""}
                                              disabled
                                              selected
                                            >
                                              Select Region
                                            </option>
                                            {regionList &&
                                              regionList?.data &&
                                              regionList?.data?.length > 0 &&
                                              regionList?.data.map(
                                                (reg: any) => (
                                                  <option
                                                    key={reg.value}
                                                    value={reg.value}
                                                  >
                                                    {reg.value}
                                                  </option>
                                                )
                                              )}
                                          </Field>
                                          {/* </LoadingHoc> */}
                                          <SelectIcon />
                                        </div>
                                        {loc.region &&
                                          loc.region !==
                                            "Online (worldwide)" && (
                                            <div className={styles.editField}>
                                              <label>
                                                Country
                                                <sup
                                                  style={{
                                                    color: "var(--redColor)",
                                                  }}
                                                >
                                                  *
                                                </sup>
                                              </label>
                                              <Field
                                                as="select"
                                                name={`location[${index}].country`}
                                                value={`${values.location[index].country}`}
                                                onChange={handleChange}
                                                className={styles.fieldArr}
                                              >
                                                <option value={""} selected>
                                                  Select Country
                                                </option>
                                                {getAllCountries(
                                                  loc.region
                                                ).map((cou: any) => (
                                                  <option
                                                    key={cou.name}
                                                    value={cou.name}
                                                  >
                                                    {cou.name}
                                                  </option>
                                                ))}
                                              </Field>
                                              <SelectIcon />
                                            </div>
                                          )}

                                        {loc &&
                                          loc.country === "United States" && (
                                            <div className={styles.editField}>
                                              <label>State(Optional)</label>
                                              <Field
                                                as="select"
                                                name={`location[${index}].state`}
                                                onChange={handleChange}
                                                // name={`${values.location[index].state}`}
                                                value={`${values.location[index].state}`}
                                                // defaultValue="string"
                                                // country
                                              >
                                                <option
                                                  value={""}
                                                  disabled
                                                  selected
                                                >
                                                  Select State
                                                </option>
                                                {(() => {
                                                  return getUSstate().map(
                                                    (state: any) => (
                                                      <option
                                                        key={state.name}
                                                        value={state.name}
                                                      >
                                                        {state.name}
                                                      </option>
                                                    )
                                                  );
                                                })()}
                                              </Field>
                                              <SelectIcon />
                                            </div>
                                          )}

                                        <Button
                                          id="remove-id"
                                          label=""
                                          type="button"
                                          border="1px solid var(--secondaryColor)"
                                          Icon={
                                            <CloseBtn
                                              fill="var(--whiteColor)"
                                              style={{ maxWidth: "12px" }}
                                            />
                                          }
                                          onClick={() => {
                                            setState((prevState: any) => {
                                              let newState = [...prevState];
                                              newState.splice(index, 1);
                                              console.log(
                                                "newstate",
                                                newState,
                                                prevState
                                              );
                                              return newState;
                                            });
                                            arrayHelpers.remove(index);
                                          }}
                                        ></Button>
                                      </div>
                                    ))}
                              </div>
                            )}
                          />

                          <h3>Organization Admin Details</h3>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <InputField
                                name="first_name"
                                type="text"
                                label="First Name"
                                placeholder="Enter First Name "
                                isRequired={true}
                                display="flex"
                                flexDirection="row"
                                fontWeight="600"
                                onChange={handleChange}
                                flexWrap="wrap"
                                gap="5px"
                                value={values?.first_name}
                                onBlur={handleBlur}
                              />
                            </div>
                            <div className={styles.editOrgInput}>
                              <InputField
                                name="last_name"
                                type="text"
                                label="Last Name"
                                placeholder="Enter Last Name"
                                isRequired={true}
                                display="flex"
                                flexDirection="row"
                                fontWeight="600"
                                onChange={handleChange}
                                flexWrap="wrap"
                                gap="5px"
                                value={values?.last_name}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <InputField
                                name="email"
                                type="text"
                                label="Email"
                                isRequired={true}
                                display="flex"
                                flexDirection="row"
                                fontWeight="600"
                                flexWrap="wrap"
                                gap="5px"
                                readonly={true}
                                value={values?.email}
                                // readonly={editOrg ? "readonly" : ""}
                              />
                            </div>
                            <div className={styles.editOrgInput}>
                              <div className="paddb-5">
                                <label>Admin Status</label>

                                <Dropdown
                                  label={values?.is_active}
                                  className={styles.editOrgSelect}
                                  dropdownData={[
                                    {
                                      name: "ACTIVE",
                                      value: "ACTIVE",
                                    },
                                    {
                                      name: "SUSPENDED",
                                      value: "SUSPENDED",
                                    },
                                  ]}
                                  onClick={(selectedItem: any) => {
                                    setFieldValue(
                                      "is_active",
                                      selectedItem?.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className={styles.assessmentBtnHead}>
                            <h3>Assigned Assessments</h3>
                            <div className={styles.assessmentBtnDiv}>
                              <Button
                                label="Assign Assessment"
                                borderRadius="5px"
                                id="assessmentBtn"
                                border="none"
                                fontWeight="bold"
                                type="button"
                                Icon={
                                  <AssignIcon
                                    fill="var(--whiteColor)"
                                    style={{ maxHeight: "14px" }}
                                  />
                                }
                                // maxWidth="150px"
                                fontSize="var(--secondaryFont)"
                                onClick={() => {
                                  // queryClient.resetQueries({
                                  //   queryKey: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
                                  // });
                                  setShowAdminAssessment(!showAdminAssessment);
                                }}
                              />
                            </div>
                          </div>
                          <div className={styles.tableEditOrg}>
                            {/* {data?.org_assessments &&
                            data?.org_assessments?.length > 0 ? ( */}
                            {!loadingState ? (
                              <Table
                                tableHeaders={[
                                  "Assessment Name",
                                  "Region",
                                  "Jurisdiction",
                                ]}
                                tableData={
                                  data?.org_assessments.length > 0 &&
                                  data?.org_assessments.filter(
                                    ({ assigned_status }: any) =>
                                      assigned_status === "ASSIGNED"
                                  )
                                }
                                hideColumns={[
                                  "assessment_id",
                                  "org_assessment_id",
                                  "assigned_status",
                                  "org_id",
                                ]}
                                action={{
                                  name: "Action",
                                  buttonOne: (data: any) => {
                                    return (
                                      data && (
                                        <Button
                                          label="UnAssign"
                                          // borderRadius="0px"
                                          id="assessmentBtn"
                                          // border="none"
                                          // padding="0px"
                                          // background="transparent"
                                          // fontWeight="bold"
                                          // color="var(--redColor)"
                                          // justifyContent="left"
                                          // textAlign="left"
                                          fontSize="var(--secondaryFont)"
                                          type="button"
                                          onClick={async () => {
                                            setModal({
                                              state: true,
                                              modalName:
                                                ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT,
                                              type: "UNASSIGNED",
                                              data: data,
                                            });
                                          }}
                                        />
                                      )
                                    );
                                  },
                                }}
                              />
                            ) : (
                              <div className={styles.assesmentLoader}>
                                <Spinner animation="border" variant="primary" />
                              </div>
                            )}
                            {data && data?.org_assessments?.length === 0 && (
                              <h6
                                style={{
                                  paddingTop: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                No Assessments added yet
                              </h6>
                            )}
                          </div>
                          <div className={styles.assessmentBtnHead}>
                            <h3>Unassigned Assessments</h3>
                          </div>
                          <div className={styles.tableEditOrg}>
                            {!loadingState ? (
                              <Table
                                tableHeaders={[
                                  "Assessment Name",
                                  "Region",
                                  "Jurisdiction",
                                ]}
                                tableData={
                                  data?.org_assessments.length > 0 &&
                                  data?.org_assessments.filter(
                                    ({ assigned_status }: any) =>
                                      assigned_status === "UNASSIGNED"
                                  )
                                }
                                hideColumns={[
                                  "assessment_id",
                                  "org_assessment_id",
                                  "assigned_status",
                                  "org_id",
                                ]}
                                action={{
                                  name: "Action",
                                  buttonOne: (data: any) => {
                                    return (
                                      data && (
                                        <Button
                                          label="ReAssign"
                                          borderRadius="0px"
                                          id="assessmentBtn"
                                          border="none"
                                          padding="0px"
                                          background="transparent"
                                          fontWeight="bold"
                                          color="var(--secondaryColor)"
                                          justifyContent="left"
                                          textAlign="left"
                                          fontSize="var(--secondaryFont)"
                                          type="button"
                                          onClick={async () => {
                                            setModal({
                                              state: true,
                                              modalName:
                                                ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT,
                                              type: "ASSIGNED",
                                              data,
                                            });
                                          }}
                                        />
                                      )
                                    );
                                  },
                                }}
                              />
                            ) : (
                              <div className={styles.assesmentLoader}>
                                <Spinner animation="border" variant="primary" />
                              </div>
                            )}
                            {/* <p style={{ alignSelf: "baseline" }}>
                                 No Assessments added yet
                               </p> */}

                            {data && data?.org_assessments?.length === 0 && (
                              <h6
                                style={{
                                  paddingTop: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                No Assessments added yet
                              </h6>
                            )}
                          </div>
                          <hr />
                          <Toast toastState={toastState} />
                          <div className={styles.flexEditOrgBtn}>
                            <Button
                              id="cancelBtn"
                              label="Cancel"
                              borderRadius="5px"
                              fontWeight="bold"
                              // padding="10px 20px"
                              background="var(--whiteColor)"
                              border="1px solid var(--secondaryColor)"
                              color="var(--secondaryColor)"
                              fontSize="var(--secondaryFont)"
                              type="button"
                              Icon={
                                <CloseBtn
                                  fill="#0097ce"
                                  style={{ maxHeight: "10px" }}
                                />
                              }
                              onClick={() => {
                                navigate(APP_PATHS.ORG_ADMIN_MANAGEMENT);
                              }}
                            />
                            <Button
                              id="saveBtn"
                              label="Save & Update"
                              borderRadius="5px"
                              // padding="10px 20px"
                              maxWidth="160px"
                              fontWeight="bold"
                              color="var(--whiteColor)"
                              fontSize="var(--secondaryFont)"
                              border="none"
                              type="submit"
                              disabled={!isValid || loadingState}
                              loading={loadingState}
                              Icon={<SaveBtn fill="#ffffff" />}
                            />
                          </div>

                          <div className={styles.formInnerBtnSection}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddAssessmentByAdmin
        heading="Assign Assessment"
        openState={showAdminAssessment}
        onClickCancel={() => {
          setShowAdminAssessment(false);
          queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
        }}
        data={data}
        user_org_id={data?.org_id}
        assignedAssessmentIds={
          data?.org_assessments.length > 0 &&
          data?.org_assessments.map(
            (assessment: any) => assessment.assessment_id
          )
        }
      />

      {/* {showModal &&
        showModal.state &&
        showModal.modalName ===
          ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT && (
          <DeleteUserAssessment
            heading="Remove Assessment"
            type={showModal?.type}
            data={{ org_assessment_id: orgAssessmentId }}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )} */}
      {showModal &&
        showModal.state &&
        showModal.modalName ===
          ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT && (
          <AssignUnassignUserAssessment
            heading={
              showModal?.type === "ASSIGNED"
                ? "Re-Assign Assessment"
                : "UnAssign Assessment"
            }
            openState={showModal}
            type={showModal?.type}
            data={showModal?.data}
            // assessment_id={orgAssessmentId}
            onClickCancel={() => {
              setModal((prev: any) => ({ ...prev, state: false }));
            }}
          />
        )}
    </React.Fragment>
  );
};
export default React.memo(EditOrganizationScreen);
