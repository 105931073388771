import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { Button, InputField } from "../../../common/components";
import { ReactComponent as BackIcon } from "../../../assests/svg/back_icon.svg";
import reset_password_icon from "../../../assests/svg/reset_password_icon.svg";

const ResetPasswordStatusScreen = () => {
  return (
    <div className={styles.forgotStatus}>
      <div className={styles.forgotStatusDiv}>
        <img
          style={{
            width: 40,
            alignSelf: "center",
          }}
          src={reset_password_icon}
          alt="forgot_icon"
        ></img>
        <h1>Password Reset</h1>
        <p>
          Ypur password has been successfully reset{" "}
          <span>Click below to login.</span>
        </p>

        <div className={styles.formInnerBtnSection}>
          <Button
            id="resetPassBtn"
            label="Continue"
            borderRadius="5px"
            fontWeight="bold"
            color="var(--whiteColor)"
            fontSize="15px"
            border="none"
            type="submit"
            width="100%"
            maxWidth="100%"
            lineHeight="1.4rem"
            //    icon={update_icon}
            //  disabled={!isValid || loadingState}
          />

          <div className={`backToLogin ${styles.backToLogin}`}>
            <BackIcon fill={`var(--textColor)`} className="svg_image_icon" />
            <Link to={"/login"}>Back to login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordStatusScreen;
