import { Button } from "../../components";
import styles from "./style.module.scss";
import update_user from "../../../assests/svg/invite_user.svg";
import React from "react";
import { ReactComponent as EditIcon } from "../../../assests/svg/edit_icon.svg";

const Breadcrumb: React.FC<any> = ({ pageName, path, action, org_name }) => {
  return (
    <>
      <div className={styles.breadcrumb}>
        <div className={styles.headingBreadCrumb}>
          <div className={styles.leftBreadCrumb}>
            {org_name && (
              <div className={styles.adminAssessmentContentTop}>
                <h2>{org_name}</h2>
              </div>
            )}
            <h3>
              {pageName}
              {action && action?.iconOne ? action.iconOne : null}
            </h3>

            <p>
              {path &&
                path.length > 0 &&
                path.map((name: string, index: number) => (
                  <span key={index}>
                    {name} {path.length - 1 !== index ? ">" : null}
                  </span>
                ))}
            </p>
          </div>
          <div className={styles.rightBreadCrumb}>
            <div className={styles.headerButtons}>
              {action && action?.buttonOne ? action.buttonOne : null}
              {action && action?.buttonTwo ? action.buttonTwo : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
