import * as Yup from "yup";

function getOptionSchema() {
  return {
    id: Yup.string(),
    option: Yup.string().required("This field is required"),
    option_description: Yup.string(),
  };
}

const optionForSubjective = {
  id: Yup.string(),
  option: Yup.string(),
  option_description: Yup.string(),
};

const AddQuestionsSchemaSubjective = Yup.object().shape({
  question: Yup.string().required("This field is required"),
  // question_number: Yup.string().required("This field is required"),
  // no_task: Yup.string().required("This field is required"),
  risk_level: Yup.string().nullable(),
  question_type: Yup.string().required("This field is required"),
  question_guidance: Yup.string().nullable(),
});

const AddQuestionsSchemaObjective = Yup.object().shape({
  question: Yup.string().required("This field is required"),
  // question_number: Yup.string().required("This field is required"),
  // no_task: Yup.string().required("This field is required"),
  risk_level: Yup.string().nullable(),
  question_type: Yup.string().required("This field is required"),
  question_guidance: Yup.string().nullable(),
  option1: Yup.object(getOptionSchema()).required(),

  option2: Yup.object(getOptionSchema()).required(),

  options: Yup.array().of(Yup.object(getOptionSchema())),
  // option: Yup.string().required('This Field is required'),
  // option_desc: Yup.string().required('This field is required'),
});

const QuestionsInitialValue = {
  question: "",
  risk_level: "",
  question_type: "Subjective",
  question_guidance: "",
  option1: optionForSubjective,
  option2: optionForSubjective,
  options: [],
};

const QuestionsInitialValueList = {
  question: "",
  risk_level: "",
  question_type: "List",
  question_guidance: "",
  option1: optionForSubjective,
  option2: optionForSubjective,
  options: [],
};

const QuestionsInitialValueObjective = {
  question: "",
  risk_level: "",
  question_type: "Objective",
  question_guidance: "",
  option1: {
    id: 1,
    option_number: 1,
    option: "",
    option_description: "",
  },
  option2: {
    id: 2,
    option_number: 2,
    option: "",
    option_description: "",
  },
  options: [],
};

const QuestionsInitialValueMultiOptionSelection = {
  question: "",
  risk_level: "",
  question_type: "Multi-Option Selection",
  question_guidance: "",
  option1: {
    id: 1,
    option_number: 1,
    option: "",
    option_description: "",
  },
  option2: {
    id: 2,
    option_number: 2,
    option: "",
    option_description: "",
  },
  options: [],
};

export {
  AddQuestionsSchemaObjective,
  AddQuestionsSchemaSubjective,
  QuestionsInitialValue,
  QuestionsInitialValueObjective,
  QuestionsInitialValueList,
  QuestionsInitialValueMultiOptionSelection,
};
