import React from "react";
import styles from "./style.module.scss";
import { Checkbox, InputField } from "../../../components";
import { Button } from "../../../components";
import { Formik, Form, ErrorMessage, Field, FieldArray } from "formik";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as SaveIcon } from "../../../../assests/svg/save_close.svg";
import { ReactComponent as UpdateIcon } from "../../../../assests/svg/update_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ILocationModal } from "../../../../interfaces/ILocation";
import { BranchLocationInitialValue, BranchLocationSchema } from "./validator";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import FormikErrorMessage from "../../../components/FormikErrorMessage";

import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  getAllCountries,
  getUSstate,
} from "../../../../utils/countriesContenent";
import { useQueryClient } from "react-query";
import { Modal } from "@mui/material";

const AddEditLocationModal: React.FC<ILocationModal> = ({
  onClickCancel,
  heading,
  type,
  data,
  onSubmit,
  headQuarterExists,
  openState,
}) => {
  console.log("data in add edit location", data, headQuarterExists);
  const regionList = useApiQuery({
    queryKey: RQ_KEYS.REGIONS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_REGIONS,
  });
  // const loading = _getQueryState({ key: RQ_KEYS.LOADING });

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={
              type === "CREATE" ? BranchLocationInitialValue : data
            }
            validationSchema={BranchLocationSchema}
            isInitialValid={false}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log("FORMIK ON BUBMIT CALLED");
            }}
          >
            {({
              handleChange,
              isValid,
              values,
              setFieldValue,
              handleBlur,
              validateForm,
              errors,
              setTouched,
            }) => (
              <Form>
                <div className={styles.addChapterBody}>
                  <div className={styles.userLogin}>
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <InputField
                        name="branch_name"
                        alignItems="left"
                        type="text"
                        label="Office Location"
                        placeholder="Enter Office Location"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.branch_name}
                      />
                    </div>
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <div className={styles.orgRegionsParent}>
                        <div className={styles.orgRegions}>
                          <div className={styles.editField}>
                            <label>
                              Region
                              <sup style={{ color: "red" }}>*</sup>
                            </label>
                            {/* <Field
                              as="select"
                              name={`region`}
                              value={`${values?.region}` || ""}
                              onChange={(e: any) => {
                                setFieldValue(`country`, "");
                                handleChange(e);
                              }}
                            >
                              <option
                                value={
                                  values?.region
                                    ? values?.region
                                    : "Select Region"
                                }
                                disabled
                                selected
                              >
                                {values?.region
                                  ? values?.region
                                  : "Select Region"}
                              </option>
                              {regionList &&
                                regionList?.data &&
                                regionList?.data.length > 0 &&
                                regionList?.data.map((reg: any) => (
                                  <option key={reg.value} value={reg.value}>
                                    {reg.value}
                                  </option>
                                ))}
                            </Field> */}

                            <Field
                              as="select"
                              name={`region`}
                              value={`${values?.region}` || ""}
                              onChange={(e: any) => {
                                setFieldValue("country", "");
                                handleChange(e);
                              }}
                            >
                              <option value="" selected>
                                Select Region
                              </option>
                              {regionList &&
                                regionList?.data &&
                                regionList?.data.length > 0 &&
                                regionList?.data?.map((reg: any) => (
                                  <option key={reg.value} value={reg.value}>
                                    {reg.value}
                                  </option>
                                ))}
                            </Field>

                            <ErrorMessage component="p" name={`region`} />
                          </div>

                          <div className={styles.editField}>
                            <label>
                              Country
                              {/* <sup style={{ color: "var(--redColor)" }}>*</sup> */}
                            </label>

                            <Field
                              as="select"
                              name={`country`}
                              value={`${values?.country}` || ""}
                              onChange={(e: any) => {
                                setFieldValue("state", "");
                                handleChange(e);
                              }}
                              disabled={
                                !values?.region ||
                                values?.region === "Worldwide"
                              }
                            >
                              <option value="" selected>
                                Select Country
                              </option>
                              {getAllCountries(values?.region).map(
                                (cou: any) => (
                                  <option key={cou.name} value={cou.name}>
                                    {cou.name}
                                  </option>
                                )
                              )}
                            </Field>
                            <p
                              style={{
                                color: "red",
                                width: "100%",
                                fontWeight: "normal",
                                fontSize: "12px",
                              }}
                            >
                              <ErrorMessage name={`country`} component="p" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.userLoginInner} ${styles.address} ${styles.full}`}
                    >
                      <InputField
                        name="street_address_1"
                        alignItems="left"
                        type="text"
                        label="Street Address"
                        placeholder="Street Address Line 1"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.street_address_1}
                      />
                      <InputField
                        name="street_address_2"
                        alignItems="left"
                        type="text"
                        // label="Street Address"
                        placeholder="Street Address Line 2"
                        isRequired={false}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.street_address_2}
                      />
                    </div>

                    <div
                      className={`${styles.userLoginInner} ${styles.fullInner}`}
                    >
                      {values?.country === "United States" && (
                        <div className={styles.halfField}>
                          <div
                            className={`${styles.userLoginInner} ${styles.editField}`}
                          >
                            <label>State</label>
                            <Field
                              as="select"
                              name={`state`}
                              onChange={handleChange}
                              value={`${values.state}`}
                            >
                              <option value={""} selected>
                                Select State
                              </option>
                              {(() => {
                                return getUSstate().map((state: any) => (
                                  <option key={state.name} value={state.value}>
                                    {state.name}
                                  </option>
                                ));
                              })()}
                            </Field>
                            <FormikErrorMessage name={`state`} component="p" />
                          </div>
                        </div>
                      )}
                      <div className={styles.halfField}>
                        <InputField
                          name="city"
                          alignItems="left"
                          type="text"
                          label="City"
                          placeholder="Enter City"
                          isRequired={false}
                          display="flex"
                          fontWeight="600"
                          fontSize="12px"
                          gap="5px"
                          onChange={handleChange}
                          value={values?.city}
                        />
                      </div>{" "}
                      <div className={styles.halfField}>
                        <InputField
                          name="zipcode"
                          alignItems="left"
                          type="text"
                          label="Zip code"
                          placeholder="Enter Zip code"
                          isRequired={false}
                          display="flex"
                          fontWeight="600"
                          fontSize="12px"
                          gap="5px"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.zipcode}
                        />
                        <FormikErrorMessage name={`zipcode`} component="p" />
                      </div>
                    </div>
                    <div
                      className={`${styles.userLoginInner} ${styles.flexOne}`}
                    ></div>

                    {/* <div className={`${styles.userLoginInner} ${styles.fullInner}`}>
                    <div className={styles.orgRegionsParent}>
                      <div className={styles.orgRegions}>
                        {values?.country === "United States" && (
                          <div className={styles.editField}>
                            <label>State(Optional)</label>
                            <Field
                              as="select"
                              name={`state`}
                              onChange={handleChange}
                              value={`${values.state}`}
                            >
                              <option value={""} disabled selected>
                                Select State
                              </option>
                              {(() => {
                                return getUSstate().map((state: any) => (
                                  <option key={state.name} value={state.value}>
                                    {state.name}
                                  </option>
                                ));
                              })()}
                            </Field>
                            <FormikErrorMessage name={`state`} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                    {/* {(!headQuarterExists || data?.is_headquarter === true) && ( */}
                    <Checkbox
                      name="is_headquarter"
                      type="checkbox"
                      label="Is Headquarter"
                      isRequired={false}
                      flexDirection="row-reverse"
                      fontSize="13px"
                      gap="5px"
                      checked={
                        values?.is_headquarter === true ||
                        values?.is_headquarter === "Yes"
                          ? true
                          : false
                      }
                      // onChange={(e: any) => {
                      //   setFieldValue(`is_headquarter`, "");
                      //   handleChange(e);
                      // }}
                      onChange={handleChange}
                      disabled={onSubmit && values?.is_headquarter === "Yes"}
                    />
                    {/* )} */}
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      type="button"
                      borderRadius="5px"
                      fontWeight="bold"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      label={
                        type === "CREATE"
                          ? "Save Location"
                          : "Update Office Location"
                      }
                      id="saveAssessmentbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      fontSize="var(--secondaryFont)"
                      border="none"
                      onClick={async () => {
                        const validationErrors = await validateForm(values);
                        console.log("🚀 ~ onClick={ ~ values:", values);
                        const touchedObj = Object.keys(values).reduce(
                          (acc: any, value: any) => {
                            acc[value] = true;
                            return acc;
                          },
                          {}
                        );
                        setTouched(touchedObj, true);
                        if (Object.keys(validationErrors).length > 0) {
                          return;
                        }
                        onSubmit && onSubmit(values);

                        onClickCancel();
                      }}
                      type={"button"}
                      Icon={
                        type === "CREATE" ? (
                          <SaveIcon fill="#ffffff" className="svg_image_icon" />
                        ) : (
                          <UpdateIcon
                            fill="#ffffff"
                            className="svg_image_icon"
                          />
                        )
                      }
                      // disabled={!isValid}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditLocationModal;
