import { DateRangePicker } from "react-date-range";

const DateRangePickerComp = ({ dateRangeState, setDateRangeState }: any) => {
  return (
    <DateRangePicker
      onChange={(item: any) => setDateRangeState([item.selection])}
      //   showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={dateRangeState}
      direction="horizontal"
    />
  );
};

export default DateRangePickerComp;
