import { ITable } from "../../interfaces/ITable";
import React from "react";
import styles from "./style.module.scss";
import { capitalizeFirstLetter } from "../../../utils/helpers";
// , enablebtn, disablebtn
const Table: React.FC<ITable> = ({
  tableData,
  action,
  hideColumns,
  flag,
  showActionWithColumns,
  tableHeaders,
}) => {
  // console.log("tableData", tableData);

  // Function to truncate text to 20 characters
  // const truncateText = (text: string) => {
  //   return text.length > 45 ? text.substring(0, 45) + "..." : text;
  // };
  return (
    <table className={styles.table}>
      <thead className={styles.tableHeading}>
        <tr>
          {tableHeaders &&
            tableHeaders.length > 0 &&
            tableHeaders.map((header, index) => {
              return (
                <th key={index}>
                  {capitalizeFirstLetter(header.replace(/_/g, " "))}
                </th>
              );
            })}
          {/* {tableData &&
            tableData.length > 0 &&
            Object.keys(tableData[0])
              .filter((key) => !hideColumns.includes(key))
              .map((result: any, key) => {
                console.log("KEY", key, result);
                return (
                  <th key={key}>
                    {capitalizeFirstLetter(result.replace(/_/g, " "))}
                  </th>
                );
              }, {})} */}
          {action && action.name && (
            <th className={styles.actionTableHeading}>{action.name}</th>
          )}
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {tableData &&
          tableData.length > 0 &&
          tableData.map((item: any, index: number) => {
            // console.log("TABLE ITEM", item);
            return (
              <tr key={index}>
                {Object.keys(item)
                  .filter((key) => !hideColumns.includes(key))
                  .map((result: any, key) => {
                    if (
                      showActionWithColumns &&
                      showActionWithColumns.length > 0 &&
                      showActionWithColumns[0]["key"] === result
                    ) {
                      return (
                        <td key={index}>
                          {showActionWithColumns[0].button(item)}
                        </td>
                      );
                    } else {
                      return (
                        <td key={index}>
                          {item[result] && item[result] !== "Global" && item[result] !== "null"
                            ? 
                              item[result]
                            : "N/A"}
                        </td>
                      );
                    }
                  }, {})}

                {action && action.name && (
                  <td>
                    <div className={flag==='columnAction'?styles.columnactionBtn:styles.actionBtn}>
                      {action.buttonOne && item && action.buttonOne(item)}
                      {action.buttonTwo && item && action.buttonTwo(item)}
                      {action.buttonThree && item && action.buttonThree(item)}
                      {action.buttonFour && item && action.buttonFour(item)}
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
export default Table;
