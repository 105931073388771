import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { InitialChapterValue, ChatperSchema } from "./validator";
import { useParams, useSearchParams } from "react-router-dom";
import {
  _getQueryState,
  _useMutation,
} from "../../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { useQueryClient } from "react-query";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as DeleteBtn } from "../../../../../assests/svg/delete_icon.svg";
import { Modal } from "@mui/material";
const DeleteChapter: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  data,
  openState,
  type,
}) => {
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  // const { assessment_id } = useParams();
  
  const [searchParams] = useSearchParams();
  const assessment_id = searchParams.get("assessment_id");
  const getChatpers = _useMutation({
    queryKey: RQ_KEYS.ALL_CHAPTERS,
  });
  const onSuccess = () => {
    getChatpers.mutate({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_CHAPTERS_LIST_OBOEDIO}/${assessment_id}`,
    });
    onClickCancel();
  };

  const deleteChapter = _useMutation({ onSuccess });
  console.log("assessment_id", assessment_id);
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={type === "DELETE" ? InitialChapterValue : data}
            validationSchema={ChatperSchema}
            onSubmit={async (values) => {
              console.log("VALUES", values);
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              if (type === "DELETE") {
                deleteChapter.mutate({
                  url: API_ROUTE.DELETE_CHAPTER,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    chapter_id: data?.chapter_id,
                    article_ids: data?.article_ids,
                    question_ids: data?.question_ids,
                    task_ids: data?.task_ids,
                  },
                });
              }
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.addChapterRow}>
                <Form>
                  <div className={styles.userLogin}>
                    <h6>
                      To Delete this Assessment, please type 'DELETE' in the box
                      given below.
                    </h6>
                    <div className={styles.userLoginInner}>
                      <InputField
                        name="deleteStr"
                        alignItems="left"
                        type="text"
                        label=""
                        placeholder='Type "DELETE"'
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    {type === "DELETE" && (
                      <Button
                        label="Delete"
                        id="deleteChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        background="red"
                        // maxWidth="120px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={<DeleteBtn fill="#ffffff" />}
                        disabled={!isValid || loadingState}
                      />
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteChapter;
