import React from "react";
import styles from "./style.module.scss";
interface Itabs {
  title: string;
  subTitle: string;
  status: "Completed" | "Pending" | "In progress";
}
interface IProps {
  tabs: Itabs[];
  step: number;
  values: any;
}
const StepTab: React.FC<any> = ({ tabs, step, values }: IProps) => {
  let stepValue = "";
  let modifiedTabs = tabs.map((tab: any) => {
    console.log("testing for step tab", step, tab.subTitle.split(" ")[1]);

    if (step === 1) {
      stepValue = "org_name";
    } else if (step === 2) {
      stepValue = "location";
    } else if (step === 3) {
      stepValue = "org_industries";
    } else if (step === 4) {
      stepValue = "assessments";
    }
    console.log("values of stepValues", values[stepValue]);
    return {
      ...tab,
      status:
        step.toString() === tab.subTitle?.split(" ")[1]
          ? "In progress"
          : (values[stepValue] !== "" || values[stepValue].length > 0) &&
            step.toString() > tab.subTitle?.split(" ")[1]
          ? "Completed"
          : "Pending",
    };
  });
  console.log("mdoified tablsl", modifiedTabs);
  return (
    <div className={styles.stepsDiv}>
      {modifiedTabs &&
        modifiedTabs.map((data: any, i: any) => (
          <div className={styles.stepsContainer}>
            <div
              className={
                data.status === "Completed"
                  ? `${styles.done} ${styles.stepsCount}`
                  : data.status === "In progress"
                  ? `${styles.active} ${styles.stepsCount}`
                  : `${styles.stepsCount}`
              }
            >
              <span>
                <b>{i + 1}</b>
              </span>
            </div>
            <div
              className={
                data.status === "Completed"
                  ? `${styles.done} ${styles.stepsText}`
                  : data.status === "In progress"
                  ? `${styles.active} ${styles.stepsText}`
                  : `${styles.stepsText}`
              }
            >
              <div className={styles.stepsSubTitle}>{data.subTitle}</div>
              <div className={styles.stepsTitle}>{data.title}</div>
              <div className={styles.stepsStatus}>{data.status}</div>
            </div>
          </div>
        ))}

      {/* <div className={styles.stepsContainer}>
        <div className={`${styles.done} ${styles.stepsCount}`}>
          <span></span>
        </div>
        <div className={styles.stepsText}>
          <div className={styles.stepsTitle}>Organization Detail</div>
        </div>
      </div>
      <div className={styles.stepsContainer}>
        <div className={`${styles.active} ${styles.stepsCount}`}>
          <span>2</span>
        </div>
        <div className={styles.stepsText}>Branch Location</div>
      </div>
      <div className={styles.stepsContainer}>
        <div className={styles.stepsCount}>
          <span>3</span>
        </div>
        <div className={styles.stepsText}>Select Industries</div>
      </div>
      <div className={styles.stepsContainer}>
        <div className={styles.stepsCount}>
          <span>4</span>
        </div>
        <div className={styles.stepsText}>Assessment</div>
      </div> */}
    </div>
  );
};
export default StepTab;
