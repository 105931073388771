import * as Yup from "yup";

const uploadTemplateInitialValues = {
  image: null,
  document_type: "",
  document_category: "",
};

const uploadTemplateSchema = Yup.object().shape({
  image: Yup.mixed().required("File is required"),
  document_type: Yup.string().required("This field is required"),
  document_category: Yup.string().required("This field is required"),
});

const uploadDocumentInitialValues = {
  image: null,
  document_type: "",
  document_category: "",
  assessmentTypesList: "",
  region: "",
};

const uploadDocumentSchema = Yup.object().shape({
  image: Yup.mixed().required("File is required"),
  document_type: Yup.string().required("This field is required"),
  document_category: Yup.string().required("This field is required"),
  region: Yup.string().required("This field is required"),
});
console.log({uploadTemplateSchema})
export {
  uploadTemplateSchema,
  uploadTemplateInitialValues,
  uploadDocumentInitialValues,
  uploadDocumentSchema,
};
