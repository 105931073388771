import React from "react";
import styles from "./style.module.scss";
import { IInviteUser } from "../../../../../../interfaces/IAssessmentModals";
import cancel_icon from "../../../../../../assests/svg/cancel_icon.svg";
import { Modal } from "@mui/material";

const ViewArticle: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  data,
  type,
  openState,
}) => {
  console.log("data in view article", data);
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <div className={styles.viewChapterBody}>
            <div className={styles.chapterItem}>
              <label>Article Number:</label>
              <p>{data?.article_number}</p>
            </div>
            <div className={styles.chapterItem}>
              <label>Article Name:</label>
              <p>{data?.article_name}</p>
            </div>

            <div className={styles.chapterItem}>
              <label>Risk Level:</label>
              <p>
                {!data?.risk_level ||
                data?.risk_level === "undefined" ||
                data?.risk_level === "null"
                  ? "N/A"
                  : data?.risk_level}
              </p>
            </div>
            <div className={styles.chapterItem}>
              <label>Risk Description:</label>
              <p>
                {!data?.risk_description ||
                data?.risk_description === "undefined" ||
                data?.risk_level === "null"
                  ? "N/A"
                  : data?.risk_description}
              </p>
            </div>
            <div className={styles.chapterItem}>
              <label>Law:</label>
              <p>
                {!data?.law || data?.law === "undefined" || data?.law === "null"
                  ? "N/A"
                  : data?.law}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewArticle;
