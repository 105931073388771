import React, { useEffect, useState } from "react";
import { ITOAST_STATE } from "../../../interfaces/IToastState";
import { Container, Col, Alert } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { RQ_KEYS } from "../../../utils/constants";
import { ReactComponent as CloseIcon } from "../../../assests/svg/close.svg";

import "./toast.scss";

const Toast = ({ toastState }: ITOAST_STATE | any) => {
  const [showToast, setShowToast] = useState<boolean | null>(null);

  const queryClient = useQueryClient();
  // React.useEffect(() => {
  //   console.log("queryClient toast", toastState);
  //   // if (toastState && toastState.type !== "NULL") {
  // setTimeout(() => {
  //   queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
  //     message: "",
  //     toastType: "NULL",
  //   });
  // }, 3000);
  //   // }
  // }, []);

  useEffect(() => {
    if (toastState && toastState.toastType !== "NULL") setShowToast(true);
  }, [toastState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
      queryClient.setQueriesData(RQ_KEYS.TOAST_STATE, {
        message: "",
        toastType: "NULL",
      });
    }, 3500);

    return () => {
      clearTimeout(timer);
      setShowToast(false);
    };
  }, [toastState, queryClient]);
  return (
    <>
      {toastState && toastState.type !== "NULL" && showToast && (
        <Container className="p-0 text-center toastContainer">
          <Col md="12" className="p-0 toastCol">
            <Alert
              className="alertDiv m-0"
              variant={
                toastState.toastType === "SUCCESS" ? "success" : "danger"
              }
            >
              <h2>{toastState.toastType}</h2>
              {toastState.message}
              {/* <CloseIcon
              style={{
                maxWidth: "14px",
                fill: "var(--whiteColor)",
                cursor: "pointer",
              }}
              onClick={() => setShowToast(!showToast)}
            /> */}
            </Alert>
          </Col>
        </Container>
      )}
    </>
  );
};

export default React.memo(Toast);
