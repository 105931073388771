import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../components";
import { IInviteUser } from "../../../../interfaces/IAssessmentModals";
import { Button } from "../../../components";
import { Formik, Form } from "formik";
import { ReactComponent as SendInviteIcon } from "../../../../assests/svg/email.svg";
import { ReactComponent as CancelIcon } from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";

import { IniteUserInitialValue, InviteUserSchema } from "./validator";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { LIMIT, REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../../utils/api";
import Toast from "../../../components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import { Modal } from "@mui/material";
const InviteUserPopup: React.FC<IInviteUser> = ({ onClickCancel, heading ,openState }) => {
  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const getOrgAdminLists = _useMutation({ queryKey: RQ_KEYS.ORG_ADMINS_LIST });
  const onSuccess = () => {
    onClickCancel();
    getOrgAdminLists.mutate({
      url: API_ROUTE.GET_ORG_ADMINS_LIST + `/${LIMIT}/0/null`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  const inviteOrgAdmin = _useMutation({ onSuccess });

  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const storeState = queryClient.getQueryData(RQ_KEYS.TOAST_STATE);
  // console.log("NEW", storeState);
  return (
    <Modal
    open={openState}
    onClose={onClickCancel}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div className={styles.invitePopupContainer}>
      <div className={styles.invitePopupContainerInner}>
        <div className={styles.invitePopupRow1}>
          <p>{heading}</p>
          <CancelIcon className="svg_image_icon" onClick={onClickCancel} />
          {/* <img
            className="svg_image_icon"
            src={cancel_icon}
            alt="invite"
            onClick={onClickCancel}
          ></img> */}
        </div>
        <Toast toastState={toastState} />
        <Formik
          initialValues={IniteUserInitialValue}
          validationSchema={InviteUserSchema}
          onSubmit={async (values) => {
            queryClient.setQueryData(RQ_KEYS.LOADING, true);
            inviteOrgAdmin.mutate({
              url: API_ROUTE.ORG_ADMIN_SEND_INVITE,
              requestType: REQUEST_TYPE._POST,
              requestData: values,
            });
            queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
          }}
        >
          {({ handleChange, isValid, handleBlur }) => {
            return (
              <div className={styles.invitePopupRow2}>
                <Form>
                  <div className={styles.userLogin}>
                    <div className={styles.userLoginInner}>
                      <InputField
                        name="org_name"
                        alignItems="left"
                        type="text"
                        label="Organization's Name"
                        placeholder="Organization Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="10px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className={styles.userLoginInner}>
                      <InputField
                        name="email"
                        type="text"
                        alignItems="left"
                        label="Admin's Email"
                        placeholder="Email ID"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="10px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {/* <div className={styles.userLoginInner}>
                      <label>Jurisdiction</label>
                      <InputField
                        name="org_judiriction_country"
                        type="text"
                        alignItems="left"
                        label="Country"
                        placeholder="Jurisdiction Country"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="10px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputField
                        name="org_judiriction_state"
                        type="text"
                        alignItems="left"
                        label="State"
                        placeholder="Jurisdiction State"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="10px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div> */}
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      label="Send Invitation"
                      id="invite user btn"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      maxWidth="160px"
                      fontSize="var(--secondaryFont)"
                      type="submit"
                      Icon={<SendInviteIcon fill="#ffffff" />}
                      loading={loadingState}
                      disabled={!isValid || loadingState}
                    />
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
    </Modal>
  );
};

export default InviteUserPopup;
