import React from "react";
import styles from "./style.module.scss";
import {
  // Dropdown,
  InputField,
  MultiSelect,
  Textarea,
} from "../../../../components";
import {
  IArticle,
  // IInviteUser,
} from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { ArticleInitialValue, ArticleSchema } from "./validator";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { useQueryClient } from "react-query";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { _useMutation } from "../../../../../services/useAxiosQuery";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as UpdateIcon } from "../../../../../assests/svg/update_icon.svg";
import { Modal } from "@mui/material";

const ManageArticles: React.FC<IArticle> = ({
  onClickCancel,
  heading,
  type,
  chapter_id,
  chapter_number,
  data,
  openState,
}) => {
  // const { assessment_id } = useParams();
  
  const [searchParams] = useSearchParams();
  const assessment_id = searchParams.get("assessment_id");
  const queryClient = useQueryClient();
  const getChaptersMutate = _useMutation({ queryKey: RQ_KEYS.ALL_CHAPTERS });
  const onSuccess = async () => {
    await getChaptersMutate.mutate({
      url: API_ROUTE.GET_CHAPTERS_LIST_OBOEDIO + `/${assessment_id}`,
      requestType: REQUEST_TYPE._GET,
    });
    onClickCancel();
  };
  const addArticleMutate = _useMutation({ onSuccess });
  const updateArticleMutate = _useMutation({ onSuccess });
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={type === "CREATE" ? ArticleInitialValue : data}
            validationSchema={ArticleSchema}
            onSubmit={async (values) => {
              console.log("values", values, type);
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              if (type === "CREATE") {
                await addArticleMutate.mutate({
                  url: API_ROUTE.CREATE_ARTICLE,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    chapter_id,
                    chapter_number
                  },
                });
              } else {
                await updateArticleMutate.mutate({
                  url: API_ROUTE.UPDATE_ARTICLE,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                    chapter_id,
                    // assessment_industry,
                    // assessment_region,
                  },
                });
              }
            }}
          >
            {({ handleChange, isValid, values, setFieldValue }) => (
              <div className={styles.addChapterRow}>
                <Form>
                  <div className={styles.userLogin}>
                    <div className={`${styles.userLoginInner}`}>
                      <InputField
                        name="article_number"
                        type="text"
                        alignItems="left"
                        label="Article Number"
                        placeholder="Enter article number"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="6px"
                        onChange={handleChange}
                        value={values?.article_number}
                      />
                    </div>
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <InputField
                        name="article_name"
                        alignItems="left"
                        type="text"
                        label="Article Name"
                        placeholder="Enter article name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="6px"
                        onChange={handleChange}
                        value={values?.article_name}
                      />
                    </div>

                    {/* <div className={styles.userLoginInner}> */}
                    {/* <div className="paddb-5">
                      <MultiSelect
                        name="risk_level"
                        label="Risk Level"
                        //   defaultValue={values?.risk_level}
                        isRequired={true}
                        placeholder="Select"
                        defaultValue={{
                          value: values?.risk_level,
                          label: values?.risk_level,
                        }}
                        options={[
                          { value: "Low", label: "Low" },
                          { value: "Medium", label: "Medium" },
                          { value: "Severe", label: "Severe" },
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        values={
                          values.risk_level
                            ? {
                                value: values?.risk_level,
                                label: values?.risk_level,
                              }
                            : ""
                        }
                      />
                    </div> */}
                    {/* </div> */}
                    {/* <div className={styles.full}> */}
                    {/* <Textarea
                      cols={30}
                      rows={3}
                      name="risk_description"
                      placeholder="Enter risk description"
                      label="Risk Description"
                      isRequired={true}
                      width="100%"
                      padding="5px 10px"
                      maxHeight="130px"
                      minHeight="100px"
                      onChange={handleChange}
                      value={values?.risk_description}
                    /> */}
                    {/* <InputField
                      name="risk_description"
                      type="textarea"
                      alignItems="left"
                      label="Risk Description"
                      placeholder="Risk Description"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="6px"
                      onChange={handleChange}
                      value={values?.risk_description}
                    /> */}
                    {/* </div> */}
                    <div className={styles.full}>
                      {/* <InputField
                      name="law"
                      type="textarea"
                      alignItems="left"
                      label="Law"
                      placeholder="Law"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="6px"
                      onChange={handleChange}
                      value={values?.law}
                    /> */}
                      <Textarea
                        cols={30}
                        rows={6}
                        name="law"
                        placeholder="Enter law"
                        label="Law"
                        isRequired={true}
                        width="100%"
                        padding="5px 10px"
                        maxHeight="130px"
                        minHeight="100px"
                        onChange={handleChange}
                        value={values?.law}
                      />
                    </div>
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "10px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      label={
                        type === "CREATE" ? "+ Add Article" : " Update Article"
                      }
                      id="addChapterbtn"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      // maxWidth="150px"
                      fontSize="var(--secondaryFont)"
                      type="submit"
                      Icon={type !== "CREATE" && <UpdateIcon fill="#ffffff" />}
                    />
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ManageArticles;
