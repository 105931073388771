import React from "react";
import Excel from "exceljs";
import styles from "./styles.module.scss";
import { saveAs } from "file-saver";
import { Button } from "../../../../common/components";
import { worksheetColumns } from "./workbookConstanst";
import { API_ROUTE } from "../../../../utils/api";
import { REQUEST_TYPE } from "../../../../utils/constants";

export const saveExcel = async (
  assessmentData: any,
  reportData: any,
  report_type: string
) => {
  const workbook = new Excel.Workbook();
  console.log("save Excel params", assessmentData, reportData);
  let workSheetArray: any = [];
  try {
    const fileName = `${assessmentData.name}-${report_type}`;

    const {
      itemsOutOfComplianceColumns,
      itemsInComplianceColumns,
      remediationTasksOutstandingColumns,
      riskAssessmentColumns,
      assessmentStatusColumns,
    } = worksheetColumns;

    workSheetArray = [
      {
        name: "Items Out of Compliance",
        columns: itemsOutOfComplianceColumns,
      },
      { name: "Items In Compliance", columns: itemsInComplianceColumns },
      {
        name: "Remediation Tasks Outstanding",
        columns: remediationTasksOutstandingColumns,
      },
      { name: "Risk Assessment", columns: riskAssessmentColumns },
      { name: "Assessment Status", columns: assessmentStatusColumns },
    ];

    workSheetArray = workSheetArray.filter(
      (sheet: any) => sheet.name === report_type
    );

    workSheetArray.forEach((worksheet: any) => {
      worksheet["worksheet"] = workbook.addWorksheet(worksheet.name);
    });

    workSheetArray.forEach(({ worksheet, columns, name }: any) => {
      console.log("name in worksheet array", name);
      worksheet.columns = columns;

      worksheet.getRow(1).font = { bold: true };

      worksheet.columns.forEach((column: any) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      reportData[name].forEach((singleData: any) => {
        worksheet.addRow(singleData);
      });

      worksheet.eachRow({ includeEmpty: false }, (row: any) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: any) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
    });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error: any) {
    console.error("<<<ERRROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    // workbook.removeWorksheet(items);
    if (workSheetArray?.length > 0) {
      workSheetArray.forEach(({ worksheet }: any) => {
        workbook.removeWorksheet(worksheet);
      });
    }
  }
};

export default function ExportButton({
  reportData,
  assessmentData,
  getReportByAssessmentId,
  showGenerateButton,
  showDownloadButton,
  getReportByAssessmentIdLoading,
  handleGenerateReport,
  getAssessmentByOrgIdLoading,
  reqData,
  enableDownloadButton,
}: any) {
  console.log(
    "reqData.compliance_status.value>>>>>>>>>> ",
    reqData?.compliance_status?.value
  );
  console.log("showGenerateButton", showGenerateButton);
  console.log("reqData>>>>>>>>>", reqData);
  console.log("getReportByAssessmentIdLoading", getReportByAssessmentIdLoading);
  console.log("getAssessmentByOrgIdLoading", getAssessmentByOrgIdLoading);
  console.log(">>> enableDownloadButton",enableDownloadButton);
  console.log(">>> reportData",reportData);
  return (
    <>
      <div style={{ textAlign: "center" }}>
        {/* <br /> */}
        <div className={styles.buttonContainer}>
          {showGenerateButton && (
            <Button
              // Icon={<UploadIcon fill="#ffffff" />}
              label="Run Report"
              id="exportDocLibrary"
              // onClick={() => saveExcel(assessmentData, reportData)}
              onClick={handleGenerateReport}
              loading={
                getReportByAssessmentIdLoading || getAssessmentByOrgIdLoading
              }
              disabled={
                !reqData?.report_type?.value ||
                getReportByAssessmentIdLoading ||
                getAssessmentByOrgIdLoading
              }
            />
          )}
          {showDownloadButton && (
            <Button
              // Icon={<UploadIcon fill="#ffffff" />}
              label="Download Report"
              id="downloadDocLibrary"
              disabled={enableDownloadButton === false}
              onClick={() => {
                console.log(">>> assessmentData", assessmentData);
                console.log(">>> reportData", reportData);
                console.log(">>> reqData.report_type.value", reqData.report_type.value);
                saveExcel(
                  assessmentData,
                  reportData,
                  reqData.report_type.value
                );
              }}
            />
          )}
        </div>
        <br />
      </div>
    </>
  );
}
