import React, { useState } from "react";
import styles from "./style.module.scss";

const Tooltip: React.FC<any> = ({ text, children, rightSide }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      className={styles.tooltipContainer}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isTooltipVisible && (
        <div
          //className={styles.tooltip}
          className={rightSide ? styles.rightTooltip : styles.tooltip}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
