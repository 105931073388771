import { IRadio } from "../../interfaces/IRadio";
import FormikErrorMessage from "../FormikErrorMessage";
import styles from "./style.module.scss";

const RadioInput: React.FC<IRadio> = ({
  name,
  type,
  label,
  isRequired,
  onChange,
  checked,
  display,
  width,
  maxWidth,
  gap,
  flexDirection,
  alignItems,
  justifyContent,
  id,
  fontWeight,
  fontSize,
  borderRadius,
  padding,
  disabled
}) => {
  const inputStyle = {
    display: display ? display : "flex",
    width: width ? width : "100%",
    maxWidth: maxWidth ? maxWidth : "100%",
    flexDirection: flexDirection ? flexDirection : "column",
    gap: gap ? gap : "0px",
    alignItems: alignItems ? alignItems : "center",
    justifyContent: justifyContent ? justifyContent : "left",
    fontWeight: fontWeight ? fontWeight : "normal",
    borderRadius: borderRadius,
    padding: padding,
    fontSize: fontSize,
  };

  return (
    <div className={styles.checkbox} style={inputStyle} id={id}>
      <input
        type={type}
        id={type === "radio" ? id : name}
        name={name}
        onChange={onChange}
        className="form-check-input"
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={name}>{label}</label>
      {/* {isRequired && <FormikErrorMessage name={name} />} */}
    </div>
  );
};

export default RadioInput;
