import React from "react";
import styles from "./table.module.scss";

export const FooterCell = ({ table, handleAddRow }: any) => {
  const meta = table.options.meta;
  const selectedRows = table.getSelectedRowModel().rows;

  const removeRows = () => {
    meta.removeSelectedRows(
      table.getSelectedRowModel().rows.map((row: any) => row.index)
    );
    table.resetRowSelection();
  };

  return (
    <div className={styles.footerBtnContainer}>
      {selectedRows.length > 0 ? (
        <button className={styles.btnContainer} onClick={removeRows}>
          X Remove Selected
        </button>
      ) : null}
      <button className={styles.btnContainer} onClick={handleAddRow}>
        + Add Row
      </button>
    </div>
  );
};
