import React from "react";
import styles from "./style.module.scss";
import ImageSuccess from "../../../../assests/svg/success.svg";
import { ReactComponent as PageIcon } from "../../../../assests/svg/blueLogo.svg";
import { Button } from "../../../../common/components";
import { useNavigate } from "react-router-dom";
import {
  APP_PATHS,
  LOCALSTORAGE,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { useQueryClient } from "react-query";
import { useApiQuery } from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { decodeToken } from "react-jwt";

const Success = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useApiQuery({
    queryKey: RQ_KEYS.AUTH,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.MATCH_USER_AUTH,
  });

  React.useMemo(() => {
    if (data?.token) {
      const token = data?.token;
      const userAuthData = decodeToken(token);
      localStorage.setItem(LOCALSTORAGE.TOKEN, token);
      queryClient.setQueryData(RQ_KEYS.USER_DATA, userAuthData);
    }
    queryClient.refetchQueries({ queryKey: RQ_KEYS.USER_ORGANIZATION });
  }, [data?.token]);
  return (
    <div className={styles.stepperSuccessBody}>
      <div className={styles.stepSuccessDiv}>
        <PageIcon style={{ maxWidth: "200px" }} />
        <h3>Onboarding Process Completed!</h3>
        <p>Your Organization Details have been saved Successfully!</p>
        <img src={ImageSuccess} alt="ImageSuccess" />
        <Button
          label="Go to Dashboard"
          borderRadius="5px"
          id="dashBoardBtn"
          border="none"
          fontWeight="bold"
          fontSize="var(--secondaryFont)"
          onClick={() => navigate(APP_PATHS.MANAGE_ORGANIZATION)}
        />
      </div>
    </div>
  );
};

export default Success;
