import { ITextarea } from "../../interfaces/ITextarea";
import FormikErrorMessage from "../FormikErrorMessage";
import styles from "./style.module.scss";
import SvgIcon from "../SvgIcon";
import { getIn } from "formik";

const Textarea: React.FC<ITextarea> = ({
  name,
  label,
  Icon,
  iconClass,
  isRequired,
  readonly,
  onChange,
  display,
  width,
  maxWidth,
  gap,
  flexDirection,
  alignItems,
  justifyContent,
  id,
  fontWeight,
  fontSize,
  borderRadius,
  padding,
  placeholder,
  value,
  flexWrap,
  setShowPassword,
  showPassword,
  onBlur,
  cols,
  rows,
  outline,
  maxHeight,
  minHeight,
  className,
  disabled
}) => {
  const textareaStyle = {
    display: display ? display : "block",
    width: width ? width : "100%",
    maxWidth: maxWidth ? maxWidth : "100%",
    flexDirection: flexDirection ? flexDirection : "column",
    flexWrap: flexWrap ? flexWrap : "",
    gap: gap ? gap : "0px",
    alignItems: alignItems ? alignItems : "center",
    justifyContent: justifyContent ? justifyContent : "space-between",
    fontWeight: fontWeight ? fontWeight : "normal",
    borderRadius: borderRadius ? borderRadius : "5px",
    padding: padding,
    readonly: readonly ? readonly : "",
    outline: "none",
    minHeight: minHeight,
    maxHeight: maxHeight,
    label: label ? label : "",
    className: className ? className : "",
   
  };

  return (
    <div className={styles.formGroup} id={id}>
      {label && (
        <label htmlFor={name}>
          {label}
          {isRequired && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      <div style={{ width: "100%", position: "relative" }}>
        <span className={styles.svg_image_icon}>{Icon}</span>
        <textarea
          style={textareaStyle}
          id={name}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          className={Icon ? `${styles.withLogo}` : className}
          readOnly={readonly}
          onBlur={onBlur}
          cols={cols}
          rows={rows}
          disabled={disabled}
        />
        {isRequired && <FormikErrorMessage name={name} />}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Textarea;
