import React from "react";
import InputField from "../InputField";
import styles from "./style.module.scss";
import SvgIcon from "../SvgIcon";
import { ReactComponent as SearchIcon } from "../../../assests/svg/search_icon.svg";
const SearchBar: React.FC<any> = ({ onChange, placeholder, Icon }: any) => {
  return (
    <div className={styles.searchBar}>
      <SearchIcon fill="#000000" />
      <InputField
        type="text"
        placeholder={placeholder}
        isRequired={false}
        name="searchBar"
        display="flex"
        onChange={onChange}
      />
      {/* <span className={styles.searchIcon}>{Icon}</span> */}
    </div>
  );
};
export default SearchBar;
