import React from "react";
import styles from "./style.module.scss";
import {
  Button,
  Dropdown,
  InputField,
  MultiSelect,
  Table,
  SearchBar,
} from "../../../components";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";
import RadioInput from "../../../components/RadioInput";
import FileInput from "../../../components/FileInput/input";
import { Form, Formik } from "formik";
import {
  uploadDocumentInitialValues,
  uploadDocumentSchema,
} from "./validations";
import {
  _getQueryState,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { useQueryClient } from "react-query";
import { Modal } from "@mui/material";
import Toast from "../../../components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import { API_ROUTE } from "../../../../utils/api";

// const DOCUMENT_CATEGORIES = [
//   { label: "CPRA", value: "CPRA" },
//   { label: "GDPR", value: "GDPR" },
// ];

const UploadDocument: React.FC<any> = ({
  heading,
  Title,
  handleSubmitUploadTemplate,
  openState,
  onClickCancel,
  region,
  uploadImageLoading,
}) => {
  const queryClient = useQueryClient();

  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const { data: assessmentTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });

  const assessmentTypesList = assessmentTypes?.map(
    (type: { assessment_type: string }) => ({
      label: type.assessment_type,
      value: type.assessment_type,
    })
  );

  const DOCUMENT_TYPES =
    Title === "Template"
      ? [
          { label: "Policies", value: "Policies" },
          { label: "Procedures", value: "Procedures" },
        ]
      : [
          { label: "Policies", value: "Policies" },
          { label: "Procedures", value: "Procedures" },
          { label: "Diagrams", value: "Diagrams" },
        ];

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.uploadFileHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            />
          </div>
          <Formik
            initialValues={uploadDocumentInitialValues}
            validationSchema={uploadDocumentSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              const replace =
                toastState && toastState.message.includes("REPLACE");
              handleSubmitUploadTemplate &&
                handleSubmitUploadTemplate(values, { replace });

              queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
                message: "",
                toastType: "",
              });
              // resetForm();
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <Form>
                <div className={styles.uploadFileBody}>
                  <div className={styles.uploadFileRow1}>
                    <InputField
                      name="image"
                      type="file"
                      fileType="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt, .pptx, image/*"
                      label={`Upload ${Title}`}
                      isRequired={true}
                      onChange={(event: any) => {
                        setFieldValue("image", event.currentTarget.files[0]);
                      }}
                    />
                  </div>
                  <div className={styles.dropdownData}>
                    <MultiSelect
                      name="region"
                      label={`Region`}
                      placeHolder="Select"
                      isMulti={false}
                      className={styles.editOrgReactSelect}
                      isRequired={true}
                      options={region && region}
                      defaultValue={{
                        value: values.region,
                        label: values.region,
                      }}
                      values={
                        values?.region
                          ? {
                              value: values.region,
                              label: values.region,
                            }
                          : null
                      }
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className={styles.uploadFileRow1}>
                    <div className={styles.dropdownData}>
                      <MultiSelect
                        name="document_type"
                        label={`${Title} Type`}
                        placeHolder="Select"
                        isMulti={false}
                        className={styles.editOrgReactSelect}
                        isRequired={true}
                        options={DOCUMENT_TYPES}
                        defaultValue={{
                          value: values.document_type,
                          label: values.document_type,
                        }}
                        values={
                          values?.document_type
                            ? {
                                value: values.document_type,
                                label: values.document_type,
                              }
                            : null
                        }
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className={styles.dropdownData}>
                      <MultiSelect
                        name="document_category"
                        label={`${Title} Category`}
                        placeHolder="Select"
                        isMulti={false}
                        className={styles.editOrgReactSelect}
                        isRequired={true}
                        options={assessmentTypesList}
                        defaultValue={{
                          value: values.document_category,
                          label: values.document_category,
                        }}
                        values={
                          values?.document_category
                            ? {
                                value: values.document_category,
                                label: values.document_category,
                              }
                            : null
                        }
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>

                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "right",
                    }}
                  >
                    {toastState &&
                      toastState.toastType === "ERROR" &&
                      toastState.message}
                  </p>
                  <div className={styles.btnContainer}>
                    <Button
                      label="Cancel"
                      id="cancel"
                      borderRadius="5px"
                      fontWeight="bold"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />

                    {toastState && toastState.toastType === "ERROR" ? (
                      <Button
                        label="Replace"
                        id="replace a new documentbtn"
                        Icon={
                          <UploadIcon fill="#ffffff" width={15} height={15} />
                        }
                        // onClick={onClickProceed}
                        type="submit"
                        loading={uploadImageLoading}
                        disabled={uploadImageLoading}
                      />
                    ) : (
                      <Button
                        label="Upload"
                        id="upload a new documentbtn"
                        Icon={
                          <UploadIcon fill="#ffffff" width={15} height={15} />
                        }
                        // onClick={onClickProceed}
                        type="submit"
                        loading={uploadImageLoading}
                        disabled={uploadImageLoading}
                      />
                    )}
                    {/* <Button
                    label="Upload"
                    id="upload a new documentbtn"
                    Icon={<UploadIcon fill="#ffffff" width={15} height={15} />}
                    // onClick={onClickProceed}
                    type="submit"
                    loading={uploadImageLoading}
                    disabled={uploadImageLoading}
                  /> */}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDocument;
