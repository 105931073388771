import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import {
  Dropdown,
  InputField,
  MultiSelect,
  SearchBar,
  Table,
} from "../../../../components";
import { Button } from "../../../../components";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as SaveBtn } from "../../../../../assests/svg/save_close.svg";
import { ReactComponent as UploadIcon } from "../../../../../assests/svg/upload_icons.svg";
import { ReactComponent as AttachIcon } from "../../../../../assests/svg/attach.svg";
import RadioInput from "../../../../components/RadioInput";
import { IQuestion } from "../../../../../interfaces/IQuestion";
import FileInput from "../../../../components/FileInput/input";
import { Form, Formik } from "formik";
import {
  uploadDocumentInitialValues,
  uploadDocumentSchema,
  uploadTemplateInitialValues,
  uploadTemplateSchema,
} from "../../UploadDocument/validations";

import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { useQueryClient } from "react-query";

const DOCUMENT_TYPES = [
  {
    label: "Policies",
    value: "Policies",
  },
  {
    label: "Procedures",
    value: "Procedures",
  },
  {
    label: "Diagrams",
    value: "Diagrams",
  },
];

// const DOCUMENT_CATEGORIES = [
//   {
//     label: "CPRA",
//     value: "CPRA",
//   },
//   {
//     label: "GDPR",
//     value: "GDPR",
//   },
// ];

const UploadDocAdmin = ({
  heading,
  onClickCancel,
  handleSubmitUploadTemplate,
  title,
  uploadImageLoading,
  regions,
}: any) => {
  const [, setImage] = useState(null);
  const queryClient = useQueryClient();

  const { data: assessmentTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });

  const assessmentTypesList = assessmentTypes?.map(
    (type: { assessment_type: string }) => ({
      label: type.assessment_type,
      value: type.assessment_type,
    })
  );

  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  // const { data: assessmentTypes } = useApiQuery({
  //   queryKey: RQ_KEYS.ASSESSMENT_TYPES,
  //   requestType: REQUEST_TYPE._GET,
  //   url: API_ROUTE.GET_ASSESSMENT_TYPES,
  // });

  // const assessmentTypesList = assessmentTypes?.map(
  //   (type: { assessment_type: string }) => ({
  //     label: type.assessment_type,
  //     value: type.assessment_type,
  //   })
  // );
  // useEffect(() => {
  //   queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
  //     message: "",
  //     toastType: "",
  //   });
  // }, [image, queryClient]);

  return (
    <Formik
      initialValues={uploadDocumentInitialValues}
      validationSchema={uploadDocumentSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        const replace = toastState && toastState.message.includes("REPLACE");
        handleSubmitUploadTemplate &&
          handleSubmitUploadTemplate(values, { replace });
        handleSubmitUploadTemplate && handleSubmitUploadTemplate(values);

        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
          message: "",
          toastType: "",
        });
        // resetForm();
      }}
    >
      {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
        <Form>
          <div className={styles.uploadFileBody}>
            <div className={styles.uploadFileRow}>
              <div className={styles.inputContainer}>
                <InputField
                  name="image"
                  type="file"
                  fileType="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt, .pptx, image/*"
                  label={`Upload ${title}`}
                  isRequired={true}
                  onChange={(event: any) => {
                    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
                      message: "",
                      toastType: "",
                    });
                    setImage(event.currentTarget.files[0]);
                    setFieldValue("image", event.currentTarget.files[0]);
                  }}
                />
              </div>
            </div>
            <div className={styles.dropdownData}>
              <MultiSelect
                name="region"
                label={`Region`}
                placeHolder="Select"
                isMulti={false}
                className={styles.editOrgReactSelect}
                isRequired={true}
                options={regions && regions}
                defaultValue={{
                  value: values.region,
                  label: values.region,
                }}
                values={
                  values?.region
                    ? {
                        value: values.region,
                        label: values.region,
                      }
                    : null
                }
                setFieldValue={setFieldValue}
              />
            </div>
            <div className={styles.uploadFileRow}>
              <div className={styles.dropdownData}>
                <MultiSelect
                  name="document_type"
                  label={`${title} Type`}
                  placeHolder="Select"
                  isMulti={false}
                  className={styles.editOrgReactSelect}
                  isRequired={true}
                  options={DOCUMENT_TYPES}
                  defaultValue={{
                    value: values.document_type,
                    label: values.document_type,
                  }}
                  values={
                    values?.document_type
                      ? {
                          value: values.document_type,
                          label: values.document_type,
                        }
                      : null
                  }
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className={styles.dropdownData}>
                <MultiSelect
                  name="document_category"
                  label={`${title} Category`}
                  placeHolder="Select"
                  isMulti={false}
                  className={styles.editOrgReactSelect}
                  isRequired={true}
                  options={assessmentTypesList}
                  defaultValue={{
                    value: values.document_category,
                    label: values.document_category,
                  }}
                  values={
                    values?.document_category
                      ? {
                          value: values.document_category,
                          label: values.document_category,
                        }
                      : null
                  }
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <p
              style={{
                color: "red",
                fontSize: "14px",
                textAlign: "right",
              }}
            >
              {toastState &&
                toastState.toastType === "ERROR" &&
                toastState.message}
            </p>
            <div className={styles.btnContainer}>
              <Button
                label="Cancel"
                id="cancel"
                borderRadius="5px"
                fontWeight="bold"
                fontSize="var(--secondaryFont)"
                background="var(--whiteColor)"
                border="1px solid var(--secondaryColor)"
                color="var(--secondaryColor)"
                Icon={<CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />}
                onClick={onClickCancel}
              />
              {toastState &&
              toastState.toastType === "ERROR" &&
              toastState.message.includes("REPLACE") ? (
                <Button
                  label="Replace"
                  id="replace a new documentbtn"
                  Icon={<UploadIcon fill="#ffffff" width={15} height={15} />}
                  // onClick={onClickProceed}
                  type="submit"
                  loading={uploadImageLoading}
                  disabled={uploadImageLoading}
                />
              ) : (
                <Button
                  label="Upload"
                  id="upload a new documentbtn"
                  Icon={<UploadIcon fill="#ffffff" width={15} height={15} />}
                  // onClick={onClickProceed}
                  type="submit"
                  loading={uploadImageLoading}
                  disabled={
                    uploadImageLoading ||
                    (toastState && toastState.toastType === "ERROR")
                  }
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default UploadDocAdmin;
