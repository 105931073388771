import React, { useState } from "react";
import styles from "./style.module.scss";
import { Breadcrumb } from "../../../../common/Parts";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "../../../../common/components";
import { ReactComponent as ViewIcon } from "../../../../assests/svg/view_icon.svg";
import { ReactComponent as DownloadIcon } from "../../../../assests/svg/download.svg";
import AddAssessmentByAdmin from "../../../../common/Parts/ModalPopups/AddAssessmentByAdmin";

import { Spinner } from "react-bootstrap";
import CustomizedMenus from "../../../../common/components/MenuList";

const AssessmentDashboard = () => {
  const navigate = useNavigate();

  const [addAssessmentPopup, setAddAssessmentPopup] =
    React.useState<boolean>(false);
  const [isDownloadPdf, setDownloadPdf] = useState(false);
  const [downloadPdfData, setDownloadPdfData] = useState(null);
  const [clickedAssessmentId, setClickedAssessmentId] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const itemsPerPage = 10;

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });

  let { data: organizationData } = useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_ORGANIZATION}?user_org_id=${
      userAuthData && userAuthData.user_org_id > 0
        ? userAuthData.user_org_id
        : null
    }`,
    enabled: !!userAuthData,
  });

  organizationData = organizationData && {
    ...organizationData,
    location: organizationData.branch_locations,
  };

  let { data: assessments }: any = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID_CATEGORIZED,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_BY_ORG_ID_CATEGORIZED}`,
  });

  console.log("Org data in assessments", assessments);

  const { mutate: getAssessmentPdfData } = _useMutation({
    onSuccess: (data: any) => {
      setDownloadPdf(true);
      setDownloadPdfData(data.data);
      console.log("PDF DATA=-=-=-=-", data);
    },
  });

  const handleAssessmentClick = (assessment_id: string) => {
    if (
      userAuthData?.role === APP_ROLES.ORG_ADMIN ||
      userAuthData?.role === APP_ROLES.BRANCH_ADMIN ||
      userAuthData?.role === APP_ROLES.VENDOR_USER ||
      userAuthData?.role === APP_ROLES.ORG_USER||
      userAuthData?.role === APP_ROLES.AUDITOR
    ) {
      navigate(
        `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
          assessment_id
        )} `
      );
    }
    //  else if (userAuthData?.role === APP_ROLES.VENDOR_USER) {
    //   navigate(APP_PATHS.ASSIGNED_ASSESSMENTS, {
    //     state: { selectedAssessmentId: assessment_id },
    //   });
    // }
  };
  const handleDownloadAssessmentPdf = async (assessment_id: string) => {
    console.log("assessment_id-=-=-=-=", assessment_id);
    getAssessmentPdfData({
      url: API_ROUTE.GET_PDF_ASSESSMENT_DATA,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        assessment_id: assessment_id,
        assigned_by_user_id: userAuthData?.user_id,
      },
    });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAssessments = assessments?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil((assessments?.data?.length || 0) / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div
          className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
        >
          {/* <div className={styles.innerContainer}>
            <Breadcrumb
              // org_name={organizationData?.org_name}
              path={["Assessing > Assessments"]}
            />
            <Breadcrumb
              org_name={organizationData?.org_name}
              // path={["Assessing - Assessments"]}
            />

            <div className={styles.authData}>
              {userAuthData && (
                <div className={styles.loggedInUser}>
                  <div
                    className={styles.loggedInUserContainer}
                    onClick={() => {
                      ShowUserProfile();
                    }}
                  >
                    <div className={styles.userInfo}>
                      <p>{`${capitalizeFirstLetter(
                        userAuthData.first_name
                      )} ${capitalizeFirstLetter(userAuthData.last_name)}`}</p>
                      <p>{`${
                        getAdminRoleFromKey(userAuthData.role) !== null &&
                        getAdminRoleFromKey(userAuthData.role)["name"]
                      }`}</p>

                    </div>
                    <div className={styles.userInitial}>
                      {userAuthData?.profile_image ? (
                        <img
                          src={userAuthData.profile_image}
                          alt="user_profile_image"
                          style={{ width: 10 }}
                        ></img>
                      ) : (
                        <p>{`${userAuthData.first_name.charAt(
                          0
                        )}${userAuthData.last_name.charAt(0)}`}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}

          {userAuthData?.role === APP_ROLES.ORG_ADMIN && (
            <div className={styles.assessmentheader}>
              <Breadcrumb pageName="Assessments" />
              <Button
                id="add-assessment"
                label="Add Assessment"
                padding="8px 10px"
                onClick={() => setAddAssessmentPopup(true)}
                // Icon={<PlusIcon fill="#ffffff" className="svg_image_icon" />}
                border="2px solid var(--secondaryColor)"
                background="transparent"
                color="var(--secondaryColor)"
              />
            </div>
          )}
          <div className={styles.manageAssessmentBody}>
            <div className={styles.dashbrdVendorContainer}>
              {!loadingState ? (
                <>
                  <Table
                    tableHeaders={[
                      "Assessment Name",
                      "Region",
                      "Country",
                      "Assessment Category",
                      "Last Modified",
                    ]}
                    tableData={
                      currentAssessments &&
                      currentAssessments.map((item: any) => ({ ...item }))
                    }
                    hideColumns={[
                      // "archived",
                      "assessment_id",
                      // "assessment_creater_id",
                      // "assessment_industry",
                      // "assessment_jurisdiction_country",
                      // "assessment_jurisdiction_state",
                      // "assessment_location",
                      // "archived_date",
                      // "publish_status",
                    ]}
                    action={{
                      name: "Action",
                      buttonOne: (data: any) => (
                        // <Tooltip text="View" rightSide={true}>
                        <Button
                          // background=" var(--primaryColor)"
                          onClick={() =>
                            handleAssessmentClick(data?.assessment_id)
                          }
                          label="View"
                          id="1"
                          Icon={
                            <ViewIcon
                              fill="#ffffff"
                              className="svg_image_icon"
                            />
                          }
                          // fill="#000000"
                          // className="svg_image_icon"
                        />
                        // <a style={{ fontSize: "13px" }} href="View">
                        //   View
                        // </a>
                        // </Tooltip>
                      ),
                      buttonTwo: (data: any) => (
                        <>
                          {/* <Tooltip text="Download Pdf" rightSide={true}> */}
                          {/* <Button
                            // className="svg_image_icon"
                            label="Download"
                            id="2"
                            Icon={
                              <DownloadIcon
                                fill="#ffffff"
                                className="svg_image_icon"
                              />
                            }
                            onClick={() => {
                              setClickedAssessmentId(data?.assessment_id);
                              handleDownloadAssessmentPdf(data?.assessment_id);
                            }}
                          /> */}

                          <CustomizedMenus
                            onClick={() => {
                              setClickedAssessmentId(data?.assessment_id);
                              handleDownloadAssessmentPdf(data?.assessment_id);
                            }}
                            isDownloadPdf={
                              isDownloadPdf &&
                              clickedAssessmentId === data?.assessment_id
                            }
                            pdfData={downloadPdfData}
                            actions={[
                              {
                                label: "Export PDF",
                                icon: <DownloadIcon fill="var(--whiteColor)"/>,
                                condition: true,
                                onClick: () => {
                                  console.log("Pdf Downloaded");
                                },
                              },
                            ]}
                          />

                          {/* </Tooltip> */}
                          {/* {isDownloadPdf &&
                            clickedAssessmentId === data?.assessment_id && (
                              <PDFDownloadLink
                                document={
                                  <Pdf assessmentData={downloadPdfData} />
                                }
                                fileName="assessment.pdf"
                              >
                                {({ loading }) =>
                                  loading
                                    ? "Loading document..."
                                    : "Download PDF"
                                }
                              </PDFDownloadLink>
                            )} */}
                        </>
                      ),
                    }}
                  />
                </>
              ) : (
                <div className={styles.assesmentLoader}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
            {assessments?.data?.length <= 0 && (
              <div style={{ textAlign: "center", margin: "30px" }}>
                No Assessments Assigned
              </div>
            )}
          </div>
        </div>
        {itemsPerPage < 10 && (
          <div className={styles.paginationControls}>
            <Button
              id="1"
              label="Previous"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              // Icon={
              //   <ViewIcon
              //     fill="#ffffff"
              //     className="svg_image_icon"
              //   />
              // }
            />
            {/* <PreviousIcon
            id="1"
            // label="Previous"
            onClick={handlePreviousPage}
            width={18}
            // disabled={currentPage === 1}
          /> */}
            <span>{` ${currentPage}`}</span>
            {/* <NextIcon
            id="2"
            // label="Next"
            onClick={handleNextPage}
            width={18}
            // disabled={currentPage === totalPages}
          /> */}
            <Button
              id="2"
              label="Next"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            />
          </div>
        )}
        {/* <div className={styles.paginationControls}>
          <Button
            id="1"
            // label="Previous"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            
          />

          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (pageNumber) => {
              // First 5 pages
              if (pageNumber <= 5) {
                return (
                  <Button
                    id="5"
                    key={pageNumber}
                    label={pageNumber.toString()}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={currentPage === pageNumber}
                    // style={{
                    //   margin: "0 5px",
                    //   backgroundColor:
                    //     currentPage === pageNumber
                    //       ? "var(--primaryColor)"
                    //       : "transparent",
                    //   color: currentPage === pageNumber ? "#ffffff" : "inherit",
                    // }}
                  />
                );
              }

              // Pages around the current page (dynamic window)
              if (
                pageNumber >= currentPage - 2 &&
                pageNumber <= currentPage + 2
              ) {
                return (
                  <Button
                    id="4"
                    key={pageNumber}
                    label={pageNumber.toString()}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={currentPage === pageNumber}
                    // style={{
                    //   margin: "0 5px",
                    //   backgroundColor:
                    //     currentPage === pageNumber
                    //       ? "var(--primaryColor)"
                    //       : "transparent",
                    //   color: currentPage === pageNumber ? "#ffffff" : "inherit",
                    // }}
                  />
                );
              }

              // Last page
              if (pageNumber === totalPages) {
                return (
                  <>
                    <span key="ellipsis" style={{ margin: "0 5px" }}>
                      ...
                    </span>
                    <Button
                      id="3"
                      key={pageNumber}
                      label={pageNumber.toString()}
                      onClick={() => handlePageChange(pageNumber)}
                      disabled={currentPage === pageNumber}
                      // style={{
                      //   margin: "0 5px",
                      //   backgroundColor:
                      //     currentPage === pageNumber
                      //       ? "var(--primaryColor)"
                      //       : "transparent",
                      //   color:
                      //     currentPage === pageNumber ? "#ffffff" : "inherit",
                      // }}
                    />
                  </>
                );
              }

              // Ellipsis for pages between start and currentPage - 2
              if (pageNumber === 6 && currentPage > 5) {
                return (
                  <span key="ellipsis-start" style={{ margin: "0 5px" }}>
                    ...
                  </span>
                );
              }

              return null;
            }
          )}

          <Button
            id="2"
            label="Next"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          />
        </div> */}
        {/* <div className={styles.paginationControls}> */}
        {/* Previous Arrow */}
        {/* <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          style={{
            margin: "0 5px",
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
            background: "transparent",
            border: "none",
          }}
        >
          &#8592; {/* Unicode left arrow */}
        {/* </button> */}
        {/* {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (pageNumber) => {
            // First 5 pages
            if (pageNumber <= 5) {
              return (
                <Button
                  id=""
                  key={pageNumber}
                  label={pageNumber.toString()}
                  onClick={() => handlePageChange(pageNumber)}
                  disabled={currentPage === pageNumber} */}
        {/* // style={{
                  //   margin: "0 5px",
                  //   backgroundColor:
                  //     currentPage === pageNumber
                  //       ? "var(--primaryColor)"
                  //       : "transparent",
                  //   color: currentPage === pageNumber ? "#ffffff" : "inherit",
                  // }}
                /> */}
        {/* );
            } */}
        {/* // Pages around the current page (dynamic window)
            if (
              pageNumber >= currentPage - 2 &&
              pageNumber <= currentPage + 2
            ) {
              return (
                <Button
                  id=""
                  key={pageNumber}
                  label={pageNumber.toString()}
                  onClick={() => handlePageChange(pageNumber)}
                  disabled={currentPage === pageNumber} */}
        {/* // style={{ */}
        {/* // margin: "0 5px", // backgroundColor: // currentPage === pageNumber //
        ? "var(--primaryColor)" // : "transparent", // color: currentPage ===
        pageNumber ? "#ffffff" : "inherit",
        {/* // }}
                />
              );
            } */}
        {/* // Last page  */}
        {/* if (pageNumber === totalPages) {
              return (
                <>
                  <span key="ellipsis" style={{ margin: "0 5px" }}>
                    ...
                  </span>
                  <Button
                    id=""
                    key={pageNumber}
                    label={pageNumber.toString()}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={currentPage === pageNumber} */}
        {/* // style={{ */}
        {/* //   margin: "0 5px",
                    //   backgroundColor:
                    //     currentPage === pageNumber
                    //       ? "var(--primaryColor)"
                    //       : "transparent",
                    //   color:
                    //     currentPage === pageNumber ? "#ffffff" : "inherit",
                    // }}
                  />
                </>
              );
            } */}
        {/* // Ellipsis for pages between start and currentPage - 2
            if (pageNumber === 6 && currentPage > 5) {
              return (
                <span key="ellipsis-start" style={{ margin: "0 5px" }}>
                  ...
                </span>
              );
            } */}
        {/* return null;
          }
        )} */}
        {/* Next Arrow */}
        {/* <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          style={{
            margin: "0 5px",
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
            background: "transparent",
            border: "none",
          }}
        > */}
        {/* &#8594; Unicode right arrow */}
        {/* </button> */}
        {/* {/* </div> */}
      </div>
      <AddAssessmentByAdmin
        heading="Add Assessment"
        onClickCancel={() => setAddAssessmentPopup(false)}
        openState={addAssessmentPopup}
        data={organizationData}
        assignedAssessmentIds={assessments?.assignedAssessmentIds}
      />
    </>
  );
};

export default AssessmentDashboard;
