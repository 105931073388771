import * as Yup from "yup";
const UserDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),

});

const UserDetailsInitialValue = {
    first_name: "",
    last_name: "",
};
export { UserDetailsSchema, UserDetailsInitialValue };