import React from "react";
import styles from "./style.module.scss";
import { Button } from "../../../components";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as LogoutIcon } from "../../../../assests/svg/logout.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ILogoutModal } from "../../../../interfaces/ILocation";
import { Modal } from "@mui/material";

const AddEditUsetDetailsModal: React.FC<ILogoutModal> = ({
  onClickCancel,
  onClickLogouut, openState,
}) => {
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>Logout</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>

          <div className={styles.manageWarning}>
            <p>Are you sure you want to logout?</p>

          </div>



          <div className={styles.inviteUserBtn}>


            <Button
              label="Cancel"
              id="cancelbtn"
              type="button"
              borderRadius="5px"
              fontWeight="bold"
              fontSize="var(--secondaryFont)"
              background="var(--whiteColor)"
              border="1px solid var(--secondaryColor)"
              color="var(--secondaryColor)"
              Icon={
                <CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />
              }
              onClick={onClickCancel}
            />
            <Button
              label="Logout"
              id="Logout"
              borderRadius="5px"
              fontWeight="bold"
              color="var(--whiteColor)"
              fontSize="var(--secondaryFont)"
              border="none"
              onClick={() => {
                onClickLogouut();

                onClickCancel();
              }}
              type={"button"}
              Icon={<LogoutIcon fill="#ffffff" className="svg_image_icon" />}
            />
          </div>



        </div>
      </div>
    </Modal>
  );
};

export default AddEditUsetDetailsModal;