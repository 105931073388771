import React, { useEffect, useState } from "react";
import { API_ROUTE } from "../../../../utils/api";
import { REQUEST_TYPE, RQ_KEYS, LIMIT } from "../../../../utils/constants";
import {
  useApiQuery,
  _getQueryState,
  _useMutation,
} from "../../../../services/useAxiosQuery";
import Tooltip from "../../../../common/Parts/ToolTip";
import SearchBar from "../../../../common/components/SearchBar";
import { ReactComponent as LoginIcon } from "../../../../assests/svg/login_icon.svg";
import { Table } from "../../../../common/components";
import {
  APP_PATHS,
  APP_ROLES,
  PROFILE_STATUS,
} from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../../common/components/Pagination";
import { Breadcrumb } from "../../../../common/Parts";
import styles from "./style.module.scss";
import { Button } from "../../../../common/components";
import { Dropdown } from "../../../../common/components";
import Toast from "../../../../common/components/Toast";
import LoadingHoc from "../../../../common/components/LoadingHoc";

function Index() {
  const navigate = useNavigate();
  const [offset, setoffset] = useState(0);
  const [dataLimit, setDataLimit] = useState<any>({
    limit: LIMIT,
    offset: offset,
  });
  const [OrganizationLabelName, setOrganizationLabelName] =
    useState("All Organization ");
  const [BranchLabelName, setBranchLabelName] = useState("All Branches");
  const [OrganizationList, setOrganizationList] = useState<any[]>([
    { name: "All Organization " },
  ]);
  const [BranchList, setBranchList] = useState<any[]>([]);
  const [ToastState, setToastState] = useState("");
  const [SearchKey, setSearchKey] = useState("");
  const [HandleLoader, setHandleLoader] = useState(true);

  const OrganizationName = (selectedData: any) => {
    setOrganizationLabelName(selectedData.name);
    setBranchLabelName("All Branches");
  };
  const BranchName = (selectedData: any) => {
    setBranchLabelName(selectedData.name);
  };

  function ShowModal(ToastState: any, OrganizationLabelName: any) {
    if (OrganizationLabelName === "All Organization ") {
      setToastState("Please Select Organization Name");
      setBranchList([]);
    } else {
      setToastState("");
    }
  }
  useEffect(() => {
    setToastState("");
  }, [OrganizationLabelName]);

  function HandleDropDownData() {
    if (OrganizationLabelName !== "All Organization ") {
      const FilterByOrgnaizations = BranchList.filter((item) => {
        Object.values(item).includes(OrganizationLabelName);
      });
      setBranchList(FilterByOrgnaizations);
    }
  }

  let orglist: any = useApiQuery({
    queryKey: RQ_KEYS.ORG_ADMINS_LIST,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ORG_ADMIN_LISTS}`,
  });

  let adminList = orglist.data;
  var totalCount = orglist.data ? orglist.data.length : 5;

  console.log(adminList, totalCount);

  function getAllUserData(
    array: [],
    offset: any,
    limit: any,
    OrganizationLabelName: string,
    BranchLabelName: string
  ) {
    let result: any[] = [];

    const startIdx = (offset / 5) * limit;
    const endIdx = (offset / 5 + 1) * limit;
    // console.log(offset)

    if (orglist.data) {
      array.map((item: any, index: number) => {
        if (
          OrganizationLabelName === "All Organization " ||
          Object.values(item).includes(OrganizationLabelName)
        ) {
          if (
            BranchLabelName === "All Branches" ||
            Object.values(item).includes(BranchLabelName)
          ) {
            result.push(item);
            // console.log(result.length)
            totalCount = result.length;
          }
        }
      });

      if (SearchKey !== "") {
        result = array.filter((item: any, index) => {
          let firstname = item.first_name;

          return (
            item.first_name.toLowerCase().includes(SearchKey.toLowerCase()) ||
            item.last_name.toLowerCase().includes(SearchKey.toLowerCase())
          );
        });
        totalCount = result.length;
      }
    }

    result = result.slice(startIdx, endIdx);
    return result;
  }

  const Userdata = getAllUserData(
    adminList,
    offset,
    LIMIT,
    OrganizationLabelName,
    BranchLabelName
  );
  console.log(Userdata.length);

  const onPaginzationChange = ({ offset }: any) => {
    setoffset(offset);

    console.log(offset);

    setDataLimit((prev: any) => ({
      ...prev,
    }));
  };

  function HandleAdmin(token: any) {
    const supportusertoken: any = localStorage.getItem("token");
    localStorage.setItem("supportusertoken", supportusertoken);
    localStorage.setItem("token", token);
    navigate(APP_PATHS.MANAGE_ORGANIZATION);
    window.location.reload();
  }

  useEffect(() => {
    if (orglist.data) {
      setHandleLoader(false);
    }
    if (SearchKey !== "") {
      setHandleLoader(false);
    }

    HandleDropDownData();
  }, [OrganizationLabelName, BranchLabelName, SearchKey, HandleLoader]);

  return (
    <div className={styles.manageAdminContainer}>
      <div className={`manageAdminContainer ${styles.manageBreadCrumb}`}>
        {/* {Object.keys(ToastState).length!==0?<Toast toastState={ToastState} />:<p></p>}  */}
        <Breadcrumb
          pageName="Login as Org Admin"
          showBtn="true"
          // org_name="Oboedio Support Dashboard"
        />
      </div>

      <div className={styles.manageDropDown}>
        <div className={styles.orgUserHeader}>
          <Dropdown
            dropdownData={OrganizationList}
            label={OrganizationLabelName}
            onClick={OrganizationName}
            // className="custom-dropdown"
            // classParent="dropdown-container"
            placeholder="Select"
            readOnly={false}
          />
          <p className={styles.toastStateP}>{ToastState}</p>
        </div>

        <div
          onClick={() => {
            ShowModal(ToastState, OrganizationLabelName);
          }}
          className={styles.orgUserHeader}
        >
          <Dropdown
            dropdownData={BranchList}
            label={BranchLabelName}
            onClick={BranchName}
            // className="custom-dropdown"
            // classParent="dropdown-container"
            placeholder="Select"
            readOnly={false}
          />
        </div>

        <div className={styles.manageSearchbar}>
          <SearchBar
            type="text"
            placeholder="Search by Name"
            isRequired={false}
            name="searchBar"
            display="flex"
            onChange={(event: any) => setSearchKey(event.target.value)}
          />
        </div>
      </div>

      <div className={`manageAdminContainer ${styles.manageTableContainer}`}>
        {Userdata.length > 0 ? (
          <Table
            tableHeaders={[
              "First Name",
              "Last Name",
              "Email",
              "Organization Name",
              "Branch Name",
              // "profile Status",
              "Status",
              "Action",
            ]}
            tableData={
              Userdata.length > 0 ? (
                Userdata.map((item: any) => {
                  const token = item.token;

                  const isOrganizationExists = OrganizationList.some(
                    (org) => org.name === item.org_name
                  );
                  const isBranchNameExists = BranchList.some(
                    (org) => org.name === item.branch_name
                  );

                  if (!isOrganizationExists) {
                    OrganizationList.push({ name: item.org_name });
                  }

                  if (
                    !isBranchNameExists &&
                    OrganizationLabelName !== "All Organization "
                  ) {
                    if (item.branch_name !== null) {
                      BranchList.push({ name: item.branch_name });
                    }
                  }

                  return {
                    First_Name:
                      item.first_name && item.first_name !== "null"
                        ? item.first_name
                        : "N/A",
                    Last_Name:
                      item.last_name && item.last_name !== "null"
                        ? item.last_name
                        : "N/A",
                    Email:
                      item.email && item.email !== "null" ? item.email : "N/A",
                    org_name:
                      item.org_name && item.org_name !== "null"
                        ? item.org_name
                        : "N/A",
                    branch_name:
                      item.branch_name && item.branch_name !== "null"
                        ? item.branch_name
                        : "N/A",

                    //   profilestatus:
                    // item.profile_status && item.profile_status !== "null"
                    //   ? item.profile_status
                    //   : "N/A",
                    Status:
                      item.is_active && item.is_active !== "null"
                        ? item.is_active
                        : "N/A",
                    Action: (
                      <Tooltip text="Show">
                        <Button
                          id="Login"
                          key={token}
                          label="LogIn"
                          onClick={() => {
                            HandleAdmin(token);
                          }}
                          Icon={
                            <LoginIcon
                              fill="var(--whiteColor)"
                              style={{ width: "14px" }}
                            />
                          }
                        ></Button>
                      </Tooltip>
                    ),
                  };
                })
              ) : (
                <p>NO User Found</p>
              )
            }
            hideColumns={["org_type", "is_headquarter", "olr_id"]}
          />
        ) : (
          <div>
            <Table
              tableHeaders={[
                "First Name",
                "Last Name",
                "Email",
                "Organization Name",
                "Branch Name",
                // "profile Status",
                "Status",
                "Action",
              ]}
              tableData={undefined}
              hideColumns={[]}
            />
            <LoadingHoc isLoading={HandleLoader}>
              <p>No User Data Found</p>
            </LoadingHoc>
          </div>
        )}

        <Pagination
          data={orglist?.data}
          totalCount={totalCount}
          onPaginzationChange={onPaginzationChange}
          setDataLimit={setDataLimit}
          dataLimit={dataLimit}
        />
      </div>
    </div>
  );
}

export default Index;
