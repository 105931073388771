import { Link, useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import { Button, InputField } from "../../../common/components";
import { ReactComponent as BackIcon } from "../../../assests/svg/back_icon.svg";
import reset_password_icon from "../../../assests/svg/reset_password_icon.svg";
import { useQueryClient } from "react-query";
import { REQUEST_TYPE, RQ_KEYS } from "../../../utils/constants";
import { _getQueryState, _useMutation } from "../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../utils/api";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { ITOAST_STATE } from "../../../interfaces/IToastState";
import Toast from "../../../common/components/Toast";

const ForgotPasswordStatusScreen = () => {
  const {
    state: { email },
  } = useLocation();
  const queryClient = useQueryClient();
  console.log("email in success forgot", email);
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const onSuccess = ({ message, data }: any) => {
    // onSuccess
    console.log("message in forgot password ", message, data.envelope.to[0]);
    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
      message: message,
      toastType: "SUCCESS",
    });
  };

  const { mutate } = _useMutation({
    onSuccess,
  });

  const handleResend = () => {
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    mutate({
      url: API_ROUTE.FORGET_PASSWORD,
      requestType: REQUEST_TYPE._POST,
      requestData: { email },
    });
    queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
  };

  return (
    <div className={styles.forgotStatus}>
      <div className={styles.loginSiteLogo}>
        <img
          style={{
            width: 150,
            alignSelf: "center",
          }}
          src={logoImage}
          alt="logo_image"
        ></img>
      </div>
      <div className={styles.forgotStatusDiv}>
        <img
          style={{
            width: 40,
            alignSelf: "center",
          }}
          src={reset_password_icon}
          alt="forgot_icon"
        ></img>
        <h1>Check your Email</h1>
        <p>
          We sent a password reset link to <span>{email}</span>
        </p>

        <div className={styles.formInnerBtnSection}>
          <span className={styles.resendSpan}>
            Didn't receive the email?
            <Button
              id="resend"
              label="Click to resend"
              borderRadius="5px"
              fontWeight="bold"
              padding="0"
              background="transparent"
              border="none"
              color="var(--secondaryColor)"
              fontSize="var(--secondaryFont)"
              onClick={handleResend}
            />
          </span>
          {/* <Toast toastState={toastState} /> */}
          <div className={`backToLogin ${styles.backtoLoginbtn}`}>
            {/* <img className="svg_image_icon" src={back_icon} alt="icon"></img> */}
            <BackIcon className="svg_image_icon" />
            <Link to={"/login"}>Back to login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordStatusScreen;
