import React, { useState } from "react";
import styles from "./style.module.scss";
import update_details from "../../../assests/png/reset password.png";
import { Button, InputField } from "../../../common/components";
import { Formik, Form } from "formik";
import eyeHideIcon from "../../../assests/svg/eye_hIde.svg";
import { ReactComponent as BackIcon } from "../../../assests/svg/back_icon.svg";
import eyeIcon from "../../../assests/svg/eye_icon.svg";
import { ReactComponent as ResetPasswordIcon } from "../../../assests/svg/reset_password_icon.svg";
import { ResetPasswordValue, ResetPasswordSchema } from "./validator";
import { _useMutation, _getQueryState } from "../../../services/useAxiosQuery";
import { APP_PATHS, REQUEST_TYPE, RQ_KEYS } from "../../../utils/constants";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../utils/api";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ITOAST_STATE } from "../../../interfaces/IToastState";
import Toast from "../../../common/components/Toast";

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] =
    React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const [params] = useSearchParams();
  const token = params.get("token");
  console.log("token search ", token);
  const onSuccess = ({ message }: any) => {
    // onSuccess
    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
      message: message,
      toastType: "SUCCESS",
    });
    navigate(APP_PATHS.LOGIN);
  };
  const { mutate } = _useMutation({
    onSuccess,
  });

  return (
    <div className={styles.resetPasswordContainer}>
      <div className={styles.loginSiteLogo}>
        <img
          style={{
            width: 150,
            alignSelf: "center",
          }}
          src={logoImage}
          alt="logo_image"
        ></img>
      </div>
      <div className={styles.loginFlexMain}>
        <div className={styles.resetPassForm}>
          <Formik
            initialValues={ResetPasswordValue}
            validationSchema={ResetPasswordSchema}
            onSubmit={async (values, { resetForm }) => {
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              mutate({
                url: `${API_ROUTE.RESET_PASSWORD}/${token}`,
                requestType: REQUEST_TYPE._POST,
                requestData: values,
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
              resetForm();
            }}
          >
            {({ handleChange, isValid, values }) => {
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  <div className={styles.resetPass}>
                    <div className={styles.resetPassSectionOne}>
                      <div className={styles.resetPassForm}>
                        <div className={styles.resetPassFormInner}>
                          <ResetPasswordIcon
                            style={{
                              width: 30,
                              alignSelf: "center",
                            }}
                          />
                          <h1>Reset password</h1>
                          {/* <p>
                            Your new password must be different to previously
                            used passwords.
                          </p> */}
                          <Toast toastState={toastState} />
                          <div className={styles.loginInputs}>
                            <InputField
                              name="password"
                              type="password"
                              label="New Password"
                              placeholder="Create a new password"
                              isRequired={true}
                              display="flex"
                              flexDirection="column"
                              fontWeight="600"
                              onChange={handleChange}
                              value={values.password}
                              gap="5px"
                              alignItems="flex-start"
                              icon={showPassword ? eyeHideIcon : eyeIcon}
                              iconClass={styles.eyeIconPassword}
                              setShowPassword={setShowPassword}
                              showPassword={showPassword}
                            />
                            <InputField
                              name="repeat_password"
                              type="password"
                              placeholder="Confirm new password"
                              label="Confirm New Password"
                              isRequired={true}
                              display="flex"
                              flexDirection="column"
                              fontWeight="600"
                              onChange={handleChange}
                              value={values.repeat_password}
                              alignItems="flex-start"
                              gap="5px"
                              icon={showRepeatPassword ? eyeHideIcon : eyeIcon}
                              iconClass={styles.eyeIconPassword}
                              setShowPassword={setShowRepeatPassword}
                              showPassword={showRepeatPassword}
                            />
                          </div>
                          {/* <hr /> */}
                          <div className={styles.formInnerBtnSection}>
                            <Button
                              id="resetPassBtn"
                              label="Set Password"
                              borderRadius="5px"
                              fontWeight="bold"
                              color="var(--whiteColor)"
                              fontSize="15px"
                              border="none"
                              type="submit"
                              maxWidth="60%"
                              lineHeight="1.4rem"
                              justifyContent="center"
                              Icon={
                                <ResetPasswordIcon
                                  style={{ fill: "#ffffff" }}
                                />
                              }
                              disabled={!isValid || loadingState}
                              filter="invert(100%) sepia(1%) saturate(4057%) hue-rotate(163deg) brightness(110%) contrast(100%)"
                            />
                          </div>
                          <div className={`${styles.backToLogin} backToLogin`}>
                            <BackIcon
                              fill={`var(--textColor)`}
                              className="svg_image_icon"
                            />
                            <Link to={"/login"}>Back to login</Link>
                          </div>

                          <div className={styles.requirements}>
                            <h4>Password Requirements:</h4>
                            <div
                              className={`requirement ${
                                /[A-Z]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain an Uppercase letter.
                              </span>
                            </div>
                            <div
                              className={`requirement ${
                                /[a-z]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain a Lowercase letter.
                              </span>
                            </div>
                            <div
                              className={`requirement ${
                                /[!@#$%^&*]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain a special character.
                              </span>
                            </div>
                            <div
                              className={`requirement  ${
                                /[0-9]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain a number.
                              </span>
                            </div>
                            <div
                              className={`requirement ${
                                values.password.length >= 8
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain 8 or more characters.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={styles.imageSection}>
          <img src={update_details} alt="sample" />
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
