import Axios from "./axios.service";

export async function _GET(url: string) {
  try {
    const { data } = await Axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return data;
  } catch (error) {
    return error;
  }
}

export async function _POST(url: string, requestData?: any, headers?: any) {
  try {
    const { data } = await Axios.post(url, requestData, headers);
    return data;
  } catch (error) {
    console.log("ERR", error);
    return error;
  }
}

export async function _PATCH(url: string, requestData: any) {
  try {
    const { data } = await Axios.patch(url, requestData);
    return data;
  } catch (error) {
    return error;
  }
}
