import React from "react";
import { Dropdown, InputField, Button } from "../../components";
import { ReactComponent as CancelBtn } from "../../../assests/svg/close.svg";
import { ReactComponent as UpdateBtn } from "../../../assests/svg/update_icon.svg";
import cancel_icon from "../../../assests/svg/cancel_icon.svg";
import styles from "./style.module.scss";
import { ISidePopups } from "../../../interfaces/ISIdePopups";

const UserProfile: React.FC<ISidePopups> = ({ onClickCancel, heading }) => {
  return (
    <div className={styles.userPopup}>
      <div className={styles.userPopupInner}>
        <div className={styles.userPopupHeader}>
          <h3>{heading}</h3>
          <img
            className="svg_image_icon"
            src={cancel_icon}
            alt="invite"
            onClick={onClickCancel}
          ></img>
        </div>
        <div className={styles.form}>
          {/* <div className={styles.loggedInUser}>
            <div className={styles.userInitial}>
                  {useAuthData?.profile_image ? (
                    <img
                      src={useAuthData.profile_image}
                      alt="user_profile_image"
                      style={{ width: 10 }}
                    ></img>
                  ) : (
                    <p>{`${useAuthData.first_name.charAt(
                      0
                    )}${useAuthData.last_name.charAt(0)}`}</p>
                  )}
                </div>
            <p>Upload Photo</p>
          </div> */}
          <InputField
            name="First name"
            type="text"
            alignItems="left"
            label="First Name"
            placeholder="Enter First Name"
            isRequired={false}
            borderRadius="14px"
            width="100%"
            fontSize="16px"
            padding=""
          />
          <InputField
            name="Last name"
            type="text"
            alignItems="left"
            label="Last Name"
            placeholder="Enter Last Name"
            isRequired={false}
            borderRadius="14px"
            width="100%"
            fontSize="16px"
            padding=""
          />
          <InputField
            name="Role"
            type="text"
            alignItems="left"
            label="Role"
            isRequired={false}
            borderRadius="14px"
            width="100%"
            fontSize="16px"
            padding=""
          />
          <div className={styles.dropdownPanel}>
            <label>Location</label>
            <Dropdown
              dropdownData={[
                { name: "jaipur", value: "JAIPUR" },
                { name: "jaipur", value: "JAIPUR" },
                { name: "jaipur", value: "JAIPUR" },
              ]}
            />
          </div>
        </div>

        <div className={styles.buttonPanel}>
          <Button
            label="Cancel"
            id="cancelbtn"
            borderRadius="5px"
            fontWeight="bold"
            // maxWidth="100px"
            fontSize="var(--secondaryFont)"
            background="var(--whiteColor)"
            border="1px solid var(--secondaryColor)"
            color="var(--secondaryColor)"
            Icon={<CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />}
            // onClick={onClickCancel}
          />
          <Button
            label="Update"
            id="Updatebtn"
            fontWeight="bold"
            color="var(--whiteColor)"
            // padding="10px 20px"
            // maxWidth="100px"
            fontSize="var(--secondaryFont)"
            //   onClick={() => {
            //     setShowPopup(!showPopup);
            //   }}
            Icon={<UpdateBtn fill="#fff" />}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
