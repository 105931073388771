import React, { useState } from "react";
import styles from "./style.module.scss";
import map_icon from "../../../../assests/svg/map.svg";
import LocationModal from "../../ModalPopups/LocationModal";
import {
  InputField,
  Button,
  Link,
  FormikErrorMessage,
} from "../../../components";
import AddLocationPopup from "../AddLocationPopup";
import { ISteps } from "../../../../interfaces/ISteps";
import { ReactComponent as RightArrowIcon } from "../../../../assests/svg/next_arrow_fill.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../assests/svg/backArrow.svg";
import { ReactComponent as Map } from "../../../../assests/svg/map.svg";
import { ReactComponent as Close } from "../../../../assests/svg/close.svg";
import { useApiQuery } from "../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import logoImage from "../../../../assests/png/siteLogo.png";
import { ILocation } from "../../../../interfaces/ILocation";
import { ArrayHelpers, FieldArray } from "formik";
// import {
//   IErrorLocation,
//   ValidateLocationForm,
// } from "../AddLocationPopup/validator";
import StepTab from "../../../components/StepTab";
import StepSideBar from "../../../components/StepSideBar";

import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/svg/delete_icon.svg";
import Tooltip from "../../ToolTip";
import { IModal } from "../../../../interfaces/IModal";

const StepTwo_orgLocation: React.FC<ISteps> = ({
  nextPageHandler,
  prevPageHandler,
  setFieldValue,
  handleChange,
  values,
  handleBlur,
  setFieldTouched,
}) => {
  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
  });
  const [type, setType] = React.useState<{
    val: string;
    index: number | null;
  }>({ val: "ADD", index: null });

  const [locationError, setLocationError] = React.useState("");

  console.log({ locationError });

  const onSubmit = (location: any) => {
    console.log("location in step 2", location, values);
    let headquarterSingularity =
      values.location.length > 0 &&
      values.location.filter((loc: any) => loc.is_headquarter).length === 1;

    if (showModal?.type === "CREATE" && location.branch_name !== "") {
      let newValuesLocation = values.location;
      if (location.is_headquarter) {
        newValuesLocation = values.location.map((loc: any) => {
          return {
            ...loc,
            is_headquarter: false,
          };
        });
      }
      setFieldValue &&
        setFieldValue("location", [...newValuesLocation, { ...location }]);
      headquarterSingularity =
        [...newValuesLocation, { ...location }].length > 0 &&
        [...newValuesLocation, { ...location }].filter(
          (loc: any) => loc.is_headquarter
        ).length === 1;
    } else if (showModal?.type === "UPDATE") {
      const findLocationIndex = values.location.findIndex(
        (val: string, index: number | null) => index === showModal.data.index
      );
      if (findLocationIndex !== -1) {
        values.location = values.location.map((loc: any) => ({
          ...loc,
          is_headquarter: false,
        }));
        values.location[findLocationIndex] = location;
        setFieldValue && setFieldValue("location", values.location);
        headquarterSingularity =
          values.location.length > 0 &&
          values.location.filter((loc: any) => loc.is_headquarter).length === 1;
      }
    }
    if (headquarterSingularity) {
      setLocationError("");
    } else {
      setLocationError("Please set atleast one location as headquarter");
    }
  };

  return (
    <>
      <div className={styles.stepCommonInner}>
        <div className={styles.stepThreeForm}>
          <div className={styles.stepThreeHeading}>
            <img
              style={{
                width: 30,
                alignSelf: "center",
              }}
              src={map_icon}
              alt="forgot_icon"
            ></img>
            <h3>Office Location</h3>
            <p>Add Office Location by clicking on the "Add Location" button</p>
          </div>

          <FieldArray
            name="location"
            render={(arrayHelpers: ArrayHelpers) => (
              <div
                className={
                  values.location.length > 0
                    ? styles.locations
                    : styles.locationOnlyBtn
                }
              >
                <div className={styles.locationBtn}>
                  <Button
                    label="Add location"
                    id="addLocations"
                    type="button"
                    // maxWidth="150px"
                    fontSize="var(--secondaryFont)"
                    // background="transparent"
                    // border="1px solid var(--secondaryColor)"
                    // color="var(--secondaryColor)"
                    fontWeight="600"
                    borderRadius="5px"
                    onClick={() => {
                      setModal({
                        state: true,
                        modalName: "LOCATION",
                        type: "CREATE",
                      });
                    }}
                    Icon={<Map fill="var(--whiteColor)" />}
                  />
                  <FormikErrorMessage name="location" />
                  {/* {error && error.location && error.location.length > 0 ? (
                      <p>{error.location[0]}</p>
                    ) : null} */}
                </div>
                {values.location.length > 0 &&
                  values.location.map((location: ILocation, index: number) => (
                    <React.Fragment key={index}>
                      <div className={styles.locationCard}>
                        <p>
                          <b>Office Location:</b> {location.branch_name}
                        </p>

                        <p>
                          <b>Office Address:</b> {location.street_address_1}
                          {location?.city ? `, ${location.city}` : null}
                          {location?.state ? `, ${location.state}` : null}
                          {location?.zipcode ? `, ${location.zipcode}` : null}
                          {location?.country
                            ? `, ${location.country} (${location.region})`
                            : null}
                        </p>

                        <p>
                          <b>Headquarter: </b>
                          {location.is_headquarter ? "Yes" : "No"}
                        </p>

                        {/* <p>
                              <b> Organization Type:</b> {location.org_type}
                            </p> */}
                        <div className={styles.locationIconsInCard}>
                          <Tooltip text="Edit">
                            <EditIcon
                              fill="#000000"
                              className="svg_image_icon"
                              onClick={() => {
                                setModal({
                                  state: true,
                                  modalName: "LOCATION",
                                  type: "UPDATE",
                                  data: { ...location, index },
                                });
                              }}
                            />
                          </Tooltip>
                          <Tooltip text="Delete">
                            <DeleteIcon
                              fill="#000000"
                              className="svg_image_icon"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                <p style={{ color: "red", fontSize: 12 }}>{locationError}</p>
              </div>
            )}
          />

          <div className={styles.pageBtn}>
            <Button
              label="Previous Step"
              id="backbtn"
              background="transparent"
              border="1px solid var(--secondaryColor)"
              color="var(--secondaryColor)"
              fontSize="var(--secondaryFont)"
              fontWeight="600"
              // maxWidth="100px"
              borderRadius="5px"
              onClick={prevPageHandler}
              Icon={<LeftArrowIcon fill="var(--secondaryColor)" />}
            />
            <Button
              label="Next"
              id="nextbtn"
              type="submit"
              borderRadius="5px"
              fontWeight="bold"
              background="var(--secondaryColor)"
              // maxWidth="100px"
              fontSize="var(--secondaryFont)"
              border="none"
              display="flex"
              flexDirection="row-reverse"
              Icon={<RightArrowIcon fill="#fff" />}
            />
          </div>
        </div>
      </div>

      {showModal?.state && (
        <LocationModal
          openState={showModal?.state}
          heading={
            showModal?.type === "CREATE"
              ? "Add Office Location"
              : "Update Office Location"
          }
          org_id={1}
          data={showModal?.data}
          onClickCancel={() => {
            setModal((prev: any) => ({ ...prev, state: false }));
          }}
          onSubmit={onSubmit}
          type={showModal?.type}
          headQuarterExists={values.location.some(
            ({ is_headquarter }: any) => is_headquarter === true
          )}
        />
      )}
    </>
  );
};

export default StepTwo_orgLocation;
