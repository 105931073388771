import React from "react";
import Button from "../Button";
import useTimer, { ITimer } from "../../../hooks/useTimer";
import { addMinutes } from "../../../utils/helpers";
import styles from "./resendInvitationButton.module.scss";

interface IResendInvitation {
  data: any;
  handleResendInvitation: (item: any) => void;
}

const ResendInvitationButton: React.FC<IResendInvitation> = ({
  data,
  handleResendInvitation,
}) => {
  const { timer, setTimer } = useTimer();

  const handleResend = (item: any) => {
    handleResendInvitation(item);
    const newDate = addMinutes(new Date(), 1);
    const newTimer = [
      ...timer,
      { userId: item.user_id, timeLeft: 60, endTime: newDate },
    ];

    localStorage.setItem("timer", JSON.stringify(newTimer));
    setTimer(newTimer);
  };
  return (
    <>
      <span className={styles.invitePending}>Invite Pending</span>
      <Button
        label="Resend Invitation"
        id="resendInvitation"
        border="1px solid var(--secondaryColor)"
        fontWeight="500"
        padding="5.5px 10px"
        maxWidth="100%"
        width="140px"
        fontSize="var(--secondaryFont)"
        background="var(--whiteColor)"
        lineHeight="inherit"
        color="var(--secondaryColor)"
        onClick={() => handleResend(data)}
        disabled={(() => {
          const timerState = timer.find(
            (state: ITimer) => state.userId === data.user_id
          );
          return timerState && timerState.timeLeft > 0;
        })()}
      />
      <span className={styles.timerSpan}>
        {(() => {
          const timerState = timer.find(
            (state: ITimer) => state.userId === data.user_id
          );
          if (timerState) {
            return `0:${timerState.timeLeft}`;
          } else return "";
        })()}
      </span>
    </>
  );
};

export default ResendInvitationButton;
