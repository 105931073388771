import { IInputField } from "../../interfaces/IInputField";
import FormikErrorMessage from "../FormikErrorMessage";
import styles from "./style.module.scss";

const InputField: React.FC<IInputField> = ({
  name,
  type,
  label,
  icon,
  iconClass,
  isRequired,
  readonly,
  onChange,
  display,
  width,
  maxWidth,
  gap,
  flexDirection,
  alignItems,
  justifyContent,
  id,
  fontWeight,
  fontSize,
  borderRadius,
  padding,
  placeholder,
  value,
  flexWrap,
  setShowPassword,
  showPassword,
  onBlur,
  disabled,
  min,
  inputBoxStyle,
  fileType,
}) => {
  const inputStyle = {
    display: display ? display : "block",
    width: width ? width : "100%",
    maxWidth: maxWidth ? maxWidth : "100%",
    flexDirection: flexDirection ? flexDirection : "column",
    flexWrap: flexWrap ? flexWrap : "",
    gap: gap ? gap : "0px",
    alignItems: alignItems ? alignItems : "center",
    justifyContent: justifyContent ? justifyContent : "space-between",
    fontWeight: fontWeight ? fontWeight : "normal",
    borderRadius: borderRadius,
    padding: padding,
    readonly: readonly ? readonly : "",
  };

  return (
    <div className={styles.formGroup} id={id}>
      <div style={inputStyle}>
        {label && (
          <label htmlFor={name}>
            {label}
            {isRequired && <sup>*</sup>}
          </label>
        )}
        <div style={{ width: "100%", position: "relative" }}>
          {icon && (
            <img
              onClick={() => setShowPassword(!showPassword)}
              className={`${styles.svg_input_icon} ${iconClass}`}
              src={icon}
              alt="icon"
            ></img>
          )}
          <input
            type={showPassword ? "text" : type}
            id={name}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            className={
              icon && !iconClass ? `${styles.ipWithIcon}` : inputBoxStyle
            }
            readOnly={readonly}
            onBlur={onBlur}
            disabled={disabled ? disabled : false}
            min={min}
            accept={type === "file" ? fileType : undefined}
          />
              </div>
          {isRequired && (
            <div>
              {isRequired && <FormikErrorMessage name={name} component="p" />}
            </div>
          )}
      </div>
    </div>
  );
};

export default InputField;
