import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { APP_PATHS, REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import {
  useApiQuery,
  _getQueryState,
} from "../../../../services/useAxiosQuery";
import ProgressBar from "../../../../common/components/ProgressBar";
import Tooltip from "../../../../common/Parts/ToolTip";
import PieChart from "../../../../common/components/PieChart";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import BarChart from "../../../../common/components/BarChart";
import { Breadcrumb } from "../../../../common/Parts";
import { useNavigate } from "react-router-dom";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";

const Executive = () => {
  const { data: assessmentCompletionData } = useApiQuery({
    queryKey: RQ_KEYS.DASHBOARD_ASSESSMENT_DETAILS,
    url: `${API_ROUTE.ASSESMENT_COMPLETION_DETAILS}`,
    requestType: REQUEST_TYPE._GET,
  });

  console.log({ assessmentCompletionData });

  const navigate = useNavigate();
  const [PercentageData, setPercentageData] = useState<any[]>([]);
  const [AssesmentNameList, setAssesmentNameList] = useState<any[]>([]);
  const colors = [
    "#0097ce",
    "#55a8d6",
    "#7eb9df",
    "#a1cae7",
    "#c1dbef",
    "#e0edf7",
  ];
  const [orgName, setorgName] = useState();
  const [HandleLoader, setHandleLoader] = useState(true);
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  function NavigateToDashboardLocation(data: any) {
    let accessdata: any = {
      org_id: data.org_id,
      Assesment_id: data.assessment_id,
      AssesmentName: data.assessment_name,
    };
    localStorage.setItem("accessdata", JSON.stringify(accessdata));

    navigate(APP_PATHS.ORG_DASHBOARD_LOCATIONS);
  }

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  React.useMemo(() => {
    assessmentCompletionData &&
      assessmentCompletionData.length > 0 &&
      Object.keys(assessmentCompletionData[0]).length &&
      assessmentCompletionData?.forEach((value: any, index: Number) => {
        const completionPercentage = (
          (value.completed_question_count / value.total_question_count) *
          100
        ).toFixed(2);

        if (AssesmentNameList.includes(value.assessment_name)) {
          console.log("already exists");
        } else {
          PercentageData.push(completionPercentage);
          AssesmentNameList.push(value.assessment_name);
        }

        setorgName(value.org_name);
        setHandleLoader(false);
      });
  }, [assessmentCompletionData, HandleLoader]);

  Chart.register(CategoryScale);

  const data = {
    labels: AssesmentNameList.filter((list) => list),
    datasets: [
      {
        label: "Assessments Completetion Status(%)",
        data: PercentageData,
        backgroundColor: colors,
        borderWidth: 2,
      },
    ],
  };

  return (
    <LoadingHoc isLoading={HandleLoader}>
      <div className={styles.dashboard}>
        {/* <div className={styles.breadcrumbcontainer}>
          <Breadcrumb
            pageName="Manage Dashboard"
            showBtn="true"
            org_name={orgName}
          />
          <p>Dashboard</p>
        </div> */}

        <div className={styles.dashExecutiveContainer}>
          <Breadcrumb pageName="Manage Dashboard" />
          <div className={styles.dashExecutiveBody}>
            <div className={styles.dashExecutiveGraph}>
              <div className={styles.dashExecGraphCircle}>
                <PieChart title="GLOBAL COMPLIANCE" chartData={data} />

                {assessmentCompletionData &&
                assessmentCompletionData.length > 0 &&
                Object.keys(assessmentCompletionData[0]).length > 1 &&
                assessmentCompletionData.some(
                  (data: any) => data.completed_question_count > 0
                ) &&
                PercentageData.length > 0 &&
                !PercentageData.every((element) => element === 0) ? (
                  <p></p>
                ) : (
                  <p> No Data Found</p>
                )}
              </div>
              <div className={styles.dashExecGraphBar}>
                <BarChart
                  title="RETROSPECTIVE GLOBAL COMPLIANCE"
                  chartData={data}
                />
                {assessmentCompletionData &&
                assessmentCompletionData.length > 0 &&
                Object.keys(assessmentCompletionData[0]).length > 1 &&
                assessmentCompletionData.some(
                  (data: any) => data.completed_question_count > 0
                ) &&
                PercentageData.length > 0 &&
                !PercentageData.every((element) => element === 0) ? (
                  <p></p>
                ) : (
                  <p> There is no Data to show on this chart</p>
                )}
              </div>
            </div>
            <div className={styles.dashExecGraphBarFull}>
              <h4>AssesssmentS</h4>
              <div className={styles.dashExecArea}>
                {assessmentCompletionData &&
                assessmentCompletionData.length > 0 &&
                Object.keys(assessmentCompletionData[0]).length > 1 ? (
                  assessmentCompletionData.map((value: any, index: number) => {
                    console.log(value);
                    const completionPercentage = (
                      (value.completed_question_count /
                        value.total_question_count) *
                      100
                    ).toFixed(2);
                    // data.datasets[0]

                    return (
                      <div
                        className={styles.card}
                        key={index}
                        onClick={() => {
                          NavigateToDashboardLocation(
                            assessmentCompletionData[index]
                          );
                        }}
                      >
                        <h3>{value.assessment_name}</h3>
                        <span></span>
                        <Tooltip text={completionPercentage + "%"}>
                          <ProgressBar
                            fullStatus={
                              (value?.completed_question_count /
                                value?.total_question_count) *
                                100 ==
                              100
                                ? true
                                : false
                            }
                            progress={`${
                              (value?.completed_question_count /
                                value?.total_question_count) *
                              100
                            }%`}
                          />{" "}
                        </Tooltip>
                      </div>
                    );
                  })
                ) : (
                  <p>No Assessments added yet</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </LoadingHoc>
  );
};

export default React.memo(Executive);
