import * as Yup from "yup";
const InviteUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z]*$/,
      "Please enter a valid name without numbers or special characters."
    ),
  last_name: Yup.string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z]*$/,
      "Please enter a valid name without numbers or special characters."
    ),
  role: Yup.string().required("This field is required"),
  branch_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
});

const IniteUserInitialValue = {
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  branch_name: "",
};
export { InviteUserSchema, IniteUserInitialValue };
