import { APP_PATHS } from "./constants";

export const breadcrumbMapping = {
  [APP_PATHS.LOGIN]: "Login",
  [APP_PATHS.FORGET_PASSWORD]: "Forgot Password",
  [APP_PATHS.RESET_PASSWORD]: "Reset Password",
  [APP_PATHS.FORGET_PASSWORD_STATUS]: "Forgot Password Status",
  [APP_PATHS.RESET_PASSWORD_STATUS]: "Reset Password Status",
  [APP_PATHS.RESET_AND_UPDATE_DETAILS]: "Reset and Update Details",
  [APP_PATHS.ORG_ADMIN_MANAGEMENT]: "Administration > Organization Management",
  [APP_PATHS.GENERATE_REPORTS]: "Reports > Generate Reports",
  [APP_PATHS.HISTORICAL_REPORTS]: "Reports > Historical Reports",
  [APP_PATHS.EDIT_ORGANIZATION]: "Edit Organization",
  [APP_PATHS.UN_AUTHENTICATED]: "Unauthorized Access",
  [APP_PATHS.ASSESSMENTS]: "Assessing > Assessments",
  [APP_PATHS.ORG_ONBOARDING]: "Administration > Organization Onboarding",
  [APP_PATHS.CHAPTER]: "Chapter",
  [APP_PATHS.ASSESSMENT_LIST]: "Assessments > Assessment List",
  [APP_PATHS.ASSESSMENT_CHAPTER_LIST]: "Chapter List",
  [APP_PATHS.MANAGE_ORGANIZATION]: "Manage Organization",
  [APP_PATHS.ORG_DASHBOARD]: "Organization Dashboard",
  [APP_PATHS.ORG_DASHBOARD_LOCATIONS]: "Organization Dashboard > Locations",
  [APP_PATHS.ORG_DASHBOARD_BRANCH]: "Organization Dashboard > Branch Status",
  [APP_PATHS.ORG_EXECUTIVE]: "Executive Dashboard",
  [APP_PATHS.VIEW_ORGANIZATION]: "View Organization",
  [APP_PATHS.VIEW_USER]: "Administration > View User",
  [APP_PATHS.VIEW_VENDOR]: "Administration > View Vendor",
  [APP_PATHS.QUESTION_SCREEN]: "Assessment > Questionnaire",
  [APP_PATHS.ASSIGNED_TASKS]: "Assigned Tasks",
  [APP_PATHS.MANAGE_USERS]: "Manage Users",
  [APP_PATHS.MANAGE_VENDORS]: "Manage Vendors",
  [APP_PATHS.VENDOR_DASHBOARD]: "Vendor Dashboard",
  [APP_PATHS.ASSESSMENT_DASHBOARD]: "Assessment Dashboard",
  [APP_PATHS.USER_ASSESSMENT]: "User Assessments",
  [APP_PATHS.DOCUMENTS]: "Documents",
  [APP_PATHS.VENDOR_DOCUMENTS]: "Vendor Documents",
  [APP_PATHS.TEMPLATE_SECTION]: "Template Section",
  [APP_PATHS.TEMPLATE]: "Template",
  [APP_PATHS.SUCCESS]: "Success",
  [APP_PATHS.ASSIGNED_ASSESSMENTS]: "Assigned Assessments",
  [APP_PATHS.ORGADMIN_LIST]: "Organization Admin List",
  [APP_PATHS.ORGUSER_LIST]: "Organization User List",
  [APP_PATHS.BRANCHADMIN_LIST]: "Branch Admin List",
  [APP_PATHS.VENDORUSER_LIST]: "Vendor User List",
  [APP_PATHS.TRACK_TASKS]: "Track Tasks",
  [APP_PATHS.USER_LOGIN_DETAILS]: "User Login Details",
  [APP_PATHS.SYSTEM_TEMPLATES]: "System Templates",
  [APP_PATHS.QUESTIONAIRE_SUCCESS]: "Assessment > Questionnaire Success",
};
