import axios from "axios";
import { BASE_URL } from "../utils/api";
import { LOCALSTORAGE } from "../utils/constants";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const errHandling = (error: Error) => {
  console.log("ERR HANDLER", error);
  return Promise.reject(error);
};
const resErrHandling = (error: any) => {
  console.log("ERR HANDLER 🚀🚀🚀🚀🚀", error, error.response);
  const { data } = error.response;
  const loginCachePresent = localStorage.getItem(LOCALSTORAGE.TOKEN)
    ? localStorage.getItem(LOCALSTORAGE.TOKEN)
    : false;
  // if (
  //   (data?.isLogout || data?.message == "Not Authorized") &&
  //   loginCachePresent
  // ) {
  //   console.log("Logging Out");

  //   localStorage.clear();
  //   window.location.href = '/login';
  // }
  return Promise.reject(error);
};
const reqHandling = async (config: any) => {
  const obj = { ...config };
  const token = localStorage.getItem(LOCALSTORAGE.TOKEN) as string;
  if (token) {
    obj.headers["'Access-Control-Allow-Origin'"] = "*";
    obj.headers.Authorization = token;
  }

  return obj;
};

const resHandling = (response: any) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, resErrHandling);

export default axiosInstance;
