import React from "react";
import styles from "./style.module.scss";
import { ReactComponent as Flag } from "../../../assests/svg/flag.svg";
import { ReactComponent as Date } from "../../../assests/svg/date.svg";
import { ITask } from "../../interfaces/ITask";

const TaskList: React.FC<ITask> = ({ tasks, heading, onSelectTask }) => {
  console.log({ tasks });
  return (
    <div className={`${styles.tasksCardParent} ${styles.tasksCardGridParent}`}>
      <h3>{heading}</h3>
      {tasks && tasks.length > 0 ? (
        tasks.map((task: any) => (
          <div
            className={`${styles.tasksCard} ${styles.taskOnHover}`}
            onClick={() => {
              onSelectTask(true, task);
            }}
          >
            <div className={styles.cardTop}>
              <span>{task.assessment_name}</span>
            </div>
            <div className={styles.cardChapter}>
              <span>Chapter: {task.chapter_name}</span>
            </div>
            <div className={styles.cardMid}>Task: {task.task}</div>
            <div className={styles.cardBottom}>
              {/* <span className={styles.cardDate}>
                <p>Assigned On:</p> <Date fill="var(--textColor)" />
                {task.assigned_at}
              </span> */}
              <span className={styles.cardDate}>
                <p>Assigned By:</p> <Date fill="var(--textColor)" />
                {task.assigned_by}
              </span>
              {/* <span className={styles.cardDate}>
                <p>Assigned To:</p> <Date fill="var(--textColor)" />
                {task.assigned_to}
              </span> */}

              {task?.due_date && (
                <span className={styles.dueWeek}>
                  <p>Due Date:</p>
                  <Flag fill="var(--redColor)" />
                  {task?.due_date}
                </span>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No Task Found</p>
      )}
    </div>
  );
};

export default TaskList;
