/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from "react";
import login_img from "../../../../assests/jpg/bg.jpg";
import {
  StepOne_OrgDetails,
  StepTwo_orgLocation,
  StepThree_OrgIndustries,
  StepFour_OrgAssessments,
} from "../../../../common/Parts";
import { Form, Formik } from "formik";
import styles from "./style.module.scss";
import {
  onboardingSchema,
  stepOneOrgDetailsSchema,
  stepThreeIndustrySchema,
  stepTwoLocationSchema,
} from "./validator";
import { INextPageHandler, ISteps } from "../../../../interfaces/ISteps";
import {
  APP_PATHS,
  LOCALSTORAGE,
  REQUEST_TYPE,
  RQ_KEYS,
  STEP_TABS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { decodeToken } from "react-jwt";
import StepSideBar from "../../../../common/components/StepSideBar";
import StepTab from "../../../../common/components/StepTab";

const OrgOnboarding = () => {
  const [step, setStep] = useState(1);
  console.log("step in onboarding", step);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const token = localStorage.getItem(LOCALSTORAGE.TOKEN) as string;
  const userAuthData: any = decodeToken(token);
  // console.log({ userAuthData });

  let { data } = useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
  });
  console.log("data.orgname", data?.org_name);
  const addAssessment = _useMutation({ onSuccess:()=>{

  } });
  const onSuccess = ({ data }: any) => {
    navigate(APP_PATHS.SUCCESS);
  };

  const { mutate } = _useMutation({ onSuccess });


  const nextPageHandler = (data: INextPageHandler[], setFieldTouched: any) => {
    if (data && data.length > 0) {
      console.log("data****", data);
      const result = data.some(({ values, name }) => {
        if (values[name] === "" || values[name].length === 0) {
          setFieldTouched(name, true);
          return;
        }
      });
      console.log("result", result);
      if (!result) {
        // setFieldTouched(name, true);
        setStep(step + 1);
        return;
      }
      setStep(step + 1);
    }
  };

  const prevPageHandler = () => {
    setStep(step - 1);
  };

  const steps = useCallback(
    (stepParams: ISteps) => {
      switch (step) {
        case 1:
          return (
            <StepOne_OrgDetails
              {...stepParams}
              prevPageHandler={prevPageHandler}
            />
          );
        case 2:
          return (
            <StepTwo_orgLocation
              {...stepParams}
              prevPageHandler={prevPageHandler}
            />
          );

        case 3:
          return (
            <StepThree_OrgIndustries
              {...stepParams}
              prevPageHandler={prevPageHandler}
            />
          );
        case 4:
          return (
            <StepFour_OrgAssessments
              {...stepParams}
              nextPageHandler={nextPageHandler}
              prevPageHandler={prevPageHandler}
            />
          );

        default:
          return null;
      }
    },
    [step]
  );
  const onSubmit = async (values: any) => {
    switch (step) {
      case 1:
      case 3:
        return setStep(step + 1);

      case 2:
        const headquarterSingularity =
          values.location.length > 0 &&
          values.location.filter((loc: any) => loc.is_headquarter).length === 1;

        if (!headquarterSingularity) {
          return;
        }
        return setStep(step + 1);

      case 4:
        console.log("inside onsubmit onbaording");
        const requestData = {
          ...values,
          org_industries: values.org_industries.map((ind: any) => ind.value),
          user_id: userAuthData?.user_id,
          first_name: userAuthData?.first_name,
          last_name: userAuthData?.last_name,
          org_id: userAuthData?.user_org_id,
          is_active: userAuthData?.is_active,
          assign_assessment_id: userAuthData?.user_id,
          type: "UPDATE_LOCATION",
        };
        console.log("submiting onboarding", requestData);

        queryClient.setQueryData(RQ_KEYS.LOADING, true);
        await mutate({
          url: API_ROUTE.UPDATE_ORG_USER_DETAILS,
          requestType: REQUEST_TYPE._PATCH,
          requestData,
        });
        await addAssessment.mutate({
          url: API_ROUTE.ASSIGN_ORG_ASSESSMENT,
          requestType: REQUEST_TYPE._PATCH,
          requestData: {
            assessment_ids: values.assessments && values.assessments.length > 0
                ? values.assessments.map(({ assessment_id }: any) => assessment_id)
               : [],
            
            org_id:userAuthData?.user_org_id,
            //   organizationData.user_org_id || organizationData.org_id,
            assigned_to_user_id: userAuthData?.user_id
          },
        });

        queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
        return;

      default:
        return null;
    }
  };
  return (
    <>
      {data && (
        <Formik
          initialValues={{
            org_name: data?.org_name,
            org_type: "",
            location: [],
            org_industries: [],
            assessments: [],
          }}
          validationSchema={
            step === 1
              ? stepOneOrgDetailsSchema
              : step === 2
              ? stepTwoLocationSchema
              : step === 3
              ? stepThreeIndustrySchema
              : onboardingSchema
          }
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({
            handleBlur,
            handleChange,
            isValid,
            values,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <div className={styles.stepperBody}>
                <Form className={styles.stepperForm}>
                  <div className={styles.stepperMainDiv}>
                    <StepSideBar
                      title=""
                      content={`Congratulations on taking the first step towards simplifying and enhancing your 
                      compliance management with Oboedio! We are thrilled to have you join our 
                      community.`}
                      name={`${userAuthData?.first_name} ${userAuthData?.last_name}`}
                    />
                    <div className={styles.stepCommon}>
                      <StepTab tabs={STEP_TABS} step={step} values={values} />
                      {steps({
                        handleChange,
                        handleBlur,
                        isValid,
                        values,
                        setFieldValue,
                        setFieldTouched,
                      })}
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default OrgOnboarding;
