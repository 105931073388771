import React from "react";
import styles from "./style.module.scss";
import { Button, MultiSelect } from "../../../components";
import { ISteps } from "../../../../interfaces/ISteps";
import { ReactComponent as RightArrowIcon } from "../../../../assests/svg/next_arrow_fill.svg";
import { useApiQuery } from "../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import login_icon from "../../../../assests/svg/select.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../assests/svg/backArrow.svg";

const StepThree_OrgIndustries: React.FC<ISteps> = ({
  nextPageHandler,
  prevPageHandler,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  values,
}) => {
  console.log("values ind", values);
  const industryList = useApiQuery({
    queryKey: RQ_KEYS.INDUSTRIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_INDUSTRIES,
  });

  return (
    <div className={styles.stepCommonInner}>
      <div className={styles.stepTwoHeading}>
        <img
          style={{
            width: 30,
            alignSelf: "center",
          }}
          src={login_icon}
          alt="forgot_icon"
        ></img>
        <h3>Select Industry</h3>
        {/* <p>
          Let us know which industry your organization serves for better
          connections
        </p> */}
      </div>
      <div className={styles.stepTwoForm}>
        <div className={styles.dropdownData}>
          <MultiSelect
            name="org_industries"
            label="Organization Industry"
            placeholder="Select"
            isMulti={true}
            long={true}
            className={styles.editOrgReactSelect}
            isRequired={true}
            defaultValue={values?.org_industries}
            options={industryList?.data}
            values={values?.org_industries}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            // onChange={handleChange}
          />
        </div>

        <div className={styles.pageBtn}>
          <Button
            onClick={prevPageHandler}
            label="Previous Step"
            id="backbtn"
            background="transparent"
            border="1px solid var(--secondaryColor)"
            color="var(--secondaryColor)"
            fontSize="var(--secondaryFont)"
            fontWeight="600"
            // maxWidth="100px"
            borderRadius="5px"
            Icon={<LeftArrowIcon fill="var(--secondaryColor)" />}
          />
          <Button
            type="submit"
            label="Next"
            id="nextbtn"
            fontWeight="bold"
            fontSize="var(--secondaryFont)"
            alignItems="center"
            background="var(--secondaryColor)"
            border="1px solid var(--secondaryColor)"
            display="flex"
            flexDirection="row-reverse"
            Icon={<RightArrowIcon fill="#fff" />}
          />
        </div>
      </div>
    </div>
  );
};

export default StepThree_OrgIndustries;
