import { LIMIT } from "../../../utils/constants";
import styles from "./style.module.scss";
import React, { useState } from "react";

function PaginationComponent({
  data,
  totalCount,
  setDataLimit,
  onPaginzationChange,
  dataLimit,
}: any) {
  console.log("in pagination", totalCount, dataLimit);

  // const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = LIMIT;
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems =
  //   data && data.length > 0 && data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleClick = async (pageNumber: number) => {
    pageNumber = pageNumber - 1;
    console.log("pageNumber", pageNumber, pageNumber * LIMIT);
    await setDataLimit((prev: any) => ({
      ...prev,
      offset: pageNumber * LIMIT,
    }));
    await onPaginzationChange({
      offset: pageNumber * LIMIT,
    });
  };

  if (totalPages > 1) {
    return (
      <div className={styles.pagination}>
        <div className={styles.paginationWrapper}>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handleClick(index + 1)}
              className={
                dataLimit.offset / LIMIT === index ? styles.active : ""
              }
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    );
  } else return null;
}

export const Pagination = PaginationComponent;
