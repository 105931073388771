//export const BASE_URL = "http://localhost:8000/api/v1";
// export const BASE_URL = "http://3.131.82.188:8000/api/v1";
 export const BASE_URL = "http://54.210.19.253:8000/api/v1";
// export const BASE_URL =
//   "https://9ad1-2401-4900-1c9a-7b21-e0d1-9063-9761-a3b5.ngrok-free.app/api/v1";

export const API_ROUTE = {
  LOGIN: "/auth/login",
  MATCH_USER_AUTH: "/auth/match-user-auth",
  UPDATE_USER_DETAILS: "/auth/update-password-details",
  FORGET_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  GET_ASSESSMENT_TYPES:
    "/oboedio/manage-assessments/assessment/get-assessment-type",
  GET_ORG_ADMINS_LIST: "/oboedio/manage-users/org-admins-list",
  GET_ORG_USERS_LIST: "/oboedio/manage-users/org-users-list",
  GET_VENDOR_USERS_LIST: "/oboedio/manage-users/org-vendor-list",
  GET_ORG_ADMIN_BY_ID: "/oboedio/manage-users/org-admin/org-details",
  GET_ORG_USER_BY_ID: "/oboedio/manage-users/org-admin/user-details",
  GET_ORG_VENDOR_BY_ID: "/oboedio/manage-users/org-admin/vendor-details",
  ACTIVE_SUSPEND_USER: "/oboedio/manage-users/active-suspend-user",
  ACTIVE_SUSPEND_ORGNIZATION:
    "/oboedio/manage-users/active-suspend-organization",

  UPDATE_ORG_USER_DETAILS: "/oboedio/manage-users/org-admin/edit-organization",
  ASSIGN_UNASSIGN_ORG_ASSESSMENT:
    "/oboedio/manage-users/org-admin/assign-unassign-assessment",
  ORG_ADMIN_SEND_INVITE:
    "/oboedio/manage-users/org-admin/add-organization-admin",
  ORG_USER_SEND_INVITE: "/oboedio/manage-users/org-admin/add-organization-user",
  ORG_VENDOR_SEND_INVITE:
    "/oboedio/manage-users/org-admin/add-organization-vendor",
  ORG_USER_UPDATE: "/oboedio/manage-users/org-admin/update-organization-user",
  RESEND_ORG_ADMIN_INVITE:
    "/oboedio/manage-users/org-admin/resend-orgadmin-invitation",
  RESEND_ORG_USER_INVITE:
    "/oboedio/manage-users/org-admin/resend-orguser-invitation",
  GET_INDUSTRIES: "/common/get-industries",
  GET_ASSESSMENT_CATEGORY: "/common/get-assessment-categories",
  GET_REGIONS: "/common/get-regions",
  GET_ORGANIZATIONS: "/common/get-organizations",
  GET_ASSESSMENTS_BY_ORG_ID: "/common/get-assessments-by-orgid",
  GET_REPORT_BY_ASSESSMENT_ID: "/common/get-report-by-assessmentid",
  GET_REPORT_TYPE: "/common/get-report-type",
  SAVE_HISTORICAL_DATA: "/common/save-historical-data",
  HISTORICAL_REPORTS: "/common/historical-reports",
  CREATE_ASSESSMENTS: "/oboedio/manage-assessments/assessment/create",
  UPDATE_ASSESSMENTS: "/oboedio/manage-assessments/assessment/update",
  DELETE_ASSESSMENT: "/oboedio/manage-assessments/assessment/delete",
  GET_ASSESSMENTS: "/oboedio/manage-assessments/assessment/list",
  GET_ARCHIVE_ASSESSMENT_LIST:
    "/oboedio/manage-assessments/assessment/archive-list",
  GET_ASSESSMENT_BY_ID: "/oboedio/manage-assessments/assessment/byId",
  GET_CHAPTER_BY_ID: "/oboedio/manage-assessments/chapter/chapterid",
  GET_CHAPTERS_LIST: "/oboedio/manage-assessments/chapter/list",
  GET_CHAPTERS_LIST_OBOEDIO: "/oboedio/manage-assessments/oboedio-chapter/list",
  CREATE_CHAPTERS: "/oboedio/manage-assessments/chapter/create",
  UPDATE_CHAPTERS: "/oboedio/manage-assessments/chapter/update",
  DELETE_CHAPTER: "/oboedio/manage-assessments/chapter/delete",
  CREATE_ARTICLE: "/oboedio/manage-assessments/article/create",
  UPDATE_ARTICLE: "/oboedio/manage-assessments/article/update",
  DELETE_ARTICLE: "/oboedio/manage-assessments/article/delete",
  CREATE_QUESTION: "/oboedio/manage-assessments/question/create",
  UPDATE_QUESTION: "/oboedio/manage-assessments/question/update",
  DELETE_QUESTION: "/oboedio/manage-assessments/question/delete",
  DELETE_ENTRY: "/oboedio/manage-assessments/question/delete-entry",
  CREATE_TASKS: "/oboedio/manage-assessments/task/create",
  UPDATE_TASKS: "/oboedio/manage-assessments/task/update",
  DELETE_TASKS: "/oboedio/manage-assessments/task/delete",
  UPDATE_ARCHIVE_STATUS:
    "/oboedio/manage-assessments/assessment/archive-status",
  UPDATE_PUBLISH_STATUS:
    "/oboedio/manage-assessments/assessment/publish-status",
  GET_PROCESSES: "/org-admin/assessments/processes",
  GET_ASSESSMENT_BY_ORG_ID: "/org-admin/assessments/assigned-assessments",
  GET_ASSESSMENT_BY_ORG_ID_CATEGORIZED:
    "/org-admin/assessments/assigned-assessments-categorized",
  GET_USER_ORGANIZATION: "/org-admin/organization/user-org-details",
  EDIT_USER_ORGANIZATION: "/org-admin/organization/update-user-org-details",
  CREATE_UPDATE_LOCATION: "/org-admin/organization/edit-location",
  GET_LOCATION_BY_ORGID: "/org-admin/organization/get-location-by-org-id",
  GET_FILTERED_ASSESSMENT_LIST: "/org-admin/assessments/filtered-by-region",
  ASSIGN_ORG_ASSESSMENT: "/oboedio/manage-users/org-admin/add-assessment",
  GET_ASSESSMENT_CHAPTERS: "/org-admin/assessments/assigned/chapter",
  GET_ANSWER_TABLE_BY_CHAPTER: "/org-admin/assessments/answer-table",
  GET_ASSIGNED_QUESTIONS: "/org-admin/assessments/assigned/questions",
  GET_ALL_ASSIGNED_QUESTIONS:
    "/org-admin/assessments/assigned/complete-questions",
  GET_QUESTIONS_BY_ARTICLE:
    "/org-admin/assessments/assigned/questions-by-article",
  GET_TASKS_WITH_RELATED_QUESTIONS:
    "/org-admin/assessments/assigned/task-related-with-question",
  SUBMIT_ASSESSMENT_ANSWER: "/org-admin/assessments/submit-assessment-answer",
  SUBMIT_ASSESSMENT_ANSWER_DYNAMIC:
    "/org-admin/assessments/submit-assessment-answer-dynamic",
  ASSSIGN_ASSESSMENT_TO_USER:
    "/org-admin/assessments/assign-assessment-to-user",
  ASSSIGN_QUESTION_TASK_TO_USER:
    "/org-admin/assessments/assign-question-or-task-to-user",
  ASSIGN_TASK_TO_USER: "/org-admin/assessments/assign-task-to-user",
  ASSSIGN_CHAPTER_TO_USER: "/org-admin/assessments/assign-chapter-to-user",
  RE_ASSSIGN_CHAPTER_TO_USER:
    "/org-admin/assessments/re-assign-chapter-to-user",
  RE_ASSSIGN_QUESTION_TASK_TO_USER:
    "/org-admin/assessments/re-assign-task-to-user",
  GET_ORG_USERS_BY_ROLE: "/org-admin/organization/org-users-by-role",
  UPLOAD_ASESSEMENT_IMAGE: "/org-admin/assessments/upload-assessment-image",
  IMPORT_ASSESSMENT: "/dataImport/importAssessment/create",
  POST_TABLE_DATA: "/org-admin/assessments/table-data",

  POST_TABLE_ANSWERS: "/org-admin/assessments/table-answers",
  UPDATE_TABLE_ANSWER_STATUS:
    "/org-admin/assessments/update-table-answer-status",
  SELECT_DOCUMENT: "/org-admin/assessments/select-document",
  UPLOAD_TEMPLATE: "/org-admin/assessments/upload-template",
  UPLOAD_DOCUMENT: "/org-admin/assessments/upload-document",
  UPLOAD_VENDOR_DOCUMENT: "/org-admin/assessments/upload-vendor-document",
  GET_DOCUMENTS: "/org-admin/assessments/get-documents",
  GET_BRANCH_ADMIN_DOCUMENTS:
    "/org-admin/assessments/get-branch-admin-documents",

  GET_ORG_ADMIN_DOCUMENTS: "/org-admin/assessments/get-org-admin-documents",
  GET_ASSESSMENT_DOCUMENTS: "/org-admin/assessments/get-assessment-documents",
  GET_ORG_DOCUMENTS: "/org-admin/assessments/get-org-documents",
  GET_VENDOR_DOCUMENTS: "/org-admin/assessments/get-vendor-documents",
  DELETE_ASSESSMENT_IMAGE: "/org-admin/assessments/delete-assessment-image",
  DELETE_PROCESS: "/org-admin/assessments/delete-process",
  DELETE_TEMPLATE: "/org-admin/assessments/delete-template",
  DELETE_DOCUMENT: "/org-admin/assessments/delete-document",
  ASSIGNED_TASKS: "/tasks/get-assigned-tasks",
  TASK_ASSIGNED_BY_ADMIN: "/tasks/get-task-assigned-by-admin",
  ASSIGNED_TASKS_COUNT: "/tasks/get-assigned-tasks-count",
  TASK_ASSIGNED_BY_ADMIN_COUNT: "/tasks/get-task-assigned-by-admin-count",
  ASSIGNED_QUESTION_COUNT: "/questions/get-assigned-questions-count",
  GET_ASSIGNED_QUESTIONS_TO_USER: "/questions/get-assigned-questions",
  UPDATE_TASK_ASSIGNED_STATUS: "/tasks/update-task-assigned-status",
  SELECT_ASSESSMENT: "/questions/select-assessment",
  ADD_COMMENT: "/comments/add-comment",
  GET_COMMENTS: "/comments/get-comments",
  ASSESMENT_COMPLETION_DETAILS:
    "dashboard/get-assessments-with-completion-details",
  GET_ORG_USERS_LISTS: "supportuser/get-orguser-list",
  GET_ORG_ADMIN_LISTS: "supportuser/get-orgadmin-list",
  GET_BRANCH_ADMIN_LISTS: "supportuser/get-branchadmin-list",
  GET_VENDOR_USER_LISTS_SUPPORT: "supportuser/get-vendoruser-list",
  GET_DASBOARD_LOCATIONS: "dashboard/get-address-by-assesment-details",
  GET_DASHBOARD_BRANCHNAME_STATUS: "dashboard/get-completation-status-details",
  GET_USER_DATA: "userdata/get-userdata",
  UPDATE_USER_DATA: "userdata/update-userdata",
  GET_ASSESSMENT_QUESTION_COUNT: "/questions/assessment-question-count",
  GET_TEMPLATES: "/org-admin/assessments/get-templates",
  CLONE_CHAPTER: "/org-admin/assessments/clone-chapter",
  GET_PDF_ASSESSMENT_DATA: "/common/getassessment-pdf-data",
  TASK_QUESTION: "questions/get-task-question",
};
